import moment from 'moment';
import { extractDbTimeFromDate, formatDateToRequest } from './dateUtils';
import { isValid } from 'date-fns';

export const getDefaultErrorState = () => ({
  VisitReason: false,
  Time: false,
  Date: false,
});

export const formatPayload = (payload: any) => {
  const cleanPayload: any = { ...payload };
  cleanPayload.Date = formatDateToRequest(cleanPayload.Date);
  if (cleanPayload.Time === null) {
    delete cleanPayload.Time;
  } else {
    cleanPayload.Time = extractDbTimeFromDate(cleanPayload.Time);
  }
  return cleanPayload;
};

export const getPayloadValidationResult = (payload: {
  VisitReason?: string;
  Date?: moment.Moment | null;
  Time?: moment.Moment | null;
}) => {
  const validationErrors = { ...getDefaultErrorState() };
  if (payload.VisitReason === '') {
    validationErrors.VisitReason = true;
  }
  if (!payload.Date || !isValid(payload.Date.toDate())) {
    validationErrors.Date = true;
  }
  if (!payload.Time || !isValid(payload.Time.toDate())) {
    validationErrors.Time = true;
  }
  return validationErrors;
};
