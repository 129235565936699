import React from 'react';
import {
  Card as MaterialCard,
  CardActionArea,
  CardContent,
  CardHeader,
  Theme,
  CardProps,
  CardHeaderProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
interface StyleParams {
  withoutMarginBottom?: boolean;
  maxWidth?: boolean;
}
export const useCardStyles = makeStyles<StyleParams>()(
  (theme: Theme, { withoutMarginBottom, maxWidth }: StyleParams) => ({
    root: {
      marginBottom: withoutMarginBottom ? 0 : 40,
    },
    headerLeft: {
      textAlign: 'left',
      backgroundColor: 'rgba(210, 200, 195, 0.12)',
      fontSize: '20px',
      fontWeight: 900,
      fontFamily: 'Brandon Grotesque',
    },
    headerCentered: {
      textAlign: 'center',
      backgroundColor: 'rgba(210, 200, 195, 0.12)',
      fontSize: '20px',
      fontWeight: 900,
      fontFamily: 'Brandon Grotesque',
    },
    content: {
      backgroundColor: 'rgba(255, 255, 255, 0.9);',
      height: '90%',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '0px',
      },
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
    },
    noPadding: {},
    noDrawerView: {
      paddingBottom: withoutMarginBottom ? '0.5%' : '15.1%',
      paddingTop: '5%',
      paddingRight: maxWidth ? '2.5%' : '17.4%',
      paddingLeft: maxWidth ? '2.5%' : '17.4%',
      [theme.breakpoints.down('md')]: {
        paddingBottom: withoutMarginBottom ? '0.5%' : '15.1%',
        paddingTop: '3%',
        paddingRight: '5%',
        paddingLeft: '5%',
      },
    },
    drawerView: {
      paddingBottom: withoutMarginBottom ? '0.5%' : '15.1%',
      paddingTop: '5%',
      paddingRight: maxWidth ? '3%' : '5%',
      paddingLeft: maxWidth ? '3%' : '5%',
      [theme.breakpoints.down('md')]: {
        paddingBottom: withoutMarginBottom ? '0.5%' : '15.1%',
        paddingTop: '3%',
        paddingRight: maxWidth ? '2%' : '5%',
        paddingLeft: maxWidth ? '2%' : '5%',
      },
    },
    mobileView: {
      paddingBottom: withoutMarginBottom ? '0' : '15.1%',
      paddingTop: '5%',
      paddingRight: maxWidth ? '1%' : '3%',
      paddingLeft: maxWidth ? '1%' : '3%',
    },
    actionArea: {
      '&:hover $focusHighlight': {
        opacity: 0.1,
      },
    },
    focusHighlight: {},
  })
);

export const Card = (props: {
  title?: string;
  children: any;
  pageView: 'drawerView' | 'noDrawerView' | 'mobileView' | 'noPadding';
  style?: object;
  headerHidden?: boolean;
  headerCentered?: boolean;
  withoutMarginBottom?: boolean;
  maxWidth?: boolean;
  outlined?: boolean;
  id?: string;
  hoverEffect?: boolean;
  cardProps?: CardProps;
  headerProps?: CardHeaderProps;
}) => {
  const { classes } = useCardStyles({
    ...(props.withoutMarginBottom && { withoutMarginBottom: true }),
    ...(props.maxWidth && { maxWidth: props.maxWidth }),
  });

  const getViewClass = (pageView: string) => {
    switch (pageView) {
      case 'drawerView':
        return classes.drawerView;
      case 'noDrawerView':
        return classes.noDrawerView;
      case 'mobileView':
        return classes.mobileView;
      case 'noPadding':
        return classes.noPadding;
      default:
        break;
    }
  };

  return (
    <div
      id={props.id}
      className={getViewClass(props.pageView)}
      style={props.style ? props.style : {}}
    >
      {!props.hoverEffect && (
        <MaterialCard
          {...(props.cardProps || {})}
          className={classes.root}
          {...(props.outlined && { variant: 'outlined' })}
          {...(props.style && { style: props.style })}
        >
          {!props.headerHidden && !props.headerCentered && (
            <CardHeader
              className={classes.headerLeft}
              title={props.title}
              disableTypography={true}
              {...(props.headerProps || {})}
            ></CardHeader>
          )}
          {!props.headerHidden && props.headerCentered && (
            <CardHeader
              className={classes.headerCentered}
              title={props.title}
              disableTypography={true}
              {...(props.headerProps || {})}
            ></CardHeader>
          )}
          <CardContent className={classes.content}>
            {props.children}
          </CardContent>
        </MaterialCard>
      )}
      {props.hoverEffect && (
        <CardActionArea
          classes={{
            root: classes.actionArea,
            focusHighlight: classes.focusHighlight,
          }}
          style={
            props.cardProps?.style?.height
              ? { height: props.cardProps.style.height }
              : {}
          }
        >
          <MaterialCard
            className={classes.root}
            {...(props.cardProps || {})}
            {...(props.outlined && { variant: 'outlined' })}
          >
            {!props.headerHidden && !props.headerCentered && (
              <CardHeader
                className={classes.headerLeft}
                title={props.title}
                disableTypography={true}
                {...(props.headerProps || {})}
              ></CardHeader>
            )}
            {!props.headerHidden && props.headerCentered && (
              <CardHeader
                className={classes.headerCentered}
                title={props.title}
                disableTypography={true}
                {...(props.headerProps || {})}
              ></CardHeader>
            )}
            <CardContent className={classes.content}>
              {props.children}
            </CardContent>
          </MaterialCard>
        </CardActionArea>
      )}
    </div>
  );
};
