import { Container, Grid } from '@mui/material';
import logo from '../assets/images/gekko_logo.png';
import PmsDiagnoses from './PmsDiagnoses';
import { PatientListProvider } from '../contexts/PatientListContext';
import { AppProvider } from '../contexts/Context';

const Layout = () => {
  return (
    <Container maxWidth="lg" style={{ paddingBottom: '4em' }}>
      <AppProvider>
        <PatientListProvider>
          <PmsDiagnoses />
        </PatientListProvider>
      </AppProvider>
      <footer
        style={{
          backgroundColor: '#00cd87',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Grid container justifyItems={'left'}>
          <Grid item>
            <a href="https://www.gekkovet.com" target="_blank" rel="noreferrer">
              <img
                style={{ maxHeight: '3em', marginTop: '0.5em' }}
                src={logo}
                alt="GekkoVet"
              />
            </a>
          </Grid>
          <Grid
            item
            style={{ flexGrow: '1', marginRight: '1em' }}
            justifyContent={'right'}
          >
            <div id="pms-integration-footer-actions"></div>
          </Grid>
        </Grid>
      </footer>
    </Container>
  );
};

export default Layout;
