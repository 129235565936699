import { intervalToDuration } from 'date-fns';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';

export const hasValidationErrors = (
  validationErrors: any,
  defaultErrorState: any
) => {
  return !isEqual(defaultErrorState, validationErrors);
};

export const formatDateToRequest = (date: Moment | Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const getDateFormatFromLocale = (locale: string) =>
  locale === 'en-US' ? 'MM.DD.YYYY' : 'DD.MM.YYYY';

export const formatDbDateStringToUi = (
  date: string | null | undefined,
  locale: string
) => {
  if (!date) {
    return '';
  }
  return moment(date).format(getDateFormatFromLocale(locale));
};

export const formatDbTimeStringToUi = (time: string | null, locale: string) => {
  const visitTime = formatDbTimeStringToDate(time);
  return visitTime !== null && locale === 'en-US'
    ? moment(visitTime).format('HH:mm')
    : time;
};

export const formatDbTimeStringToDate = (time: string | null) => {
  const date = new Date();

  if (!time) {
    return null;
  }

  const splittedTime = time.split(':');

  if (splittedTime.length < 2) {
    return moment(date);
  }
  const hours = +splittedTime[0];
  const minutes = +splittedTime[1];

  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return moment(date);
  }

  date.setHours(hours);
  date.setMinutes(minutes);

  return moment(date);
};

export const extractDbTimeFromDate = (date: Date | null) =>
  date ? moment(date).format('HH:mm') : null;

export const formatGenderIdToUi = (id: number) => {
  return id === 1 ? 'Male' : 'Female';
};

export const intervalToDate = (start: Date, end: Date): string => {
  const intervalJson = intervalToDuration({ start, end });

  const years =
    intervalJson && intervalJson.years && intervalJson.years > 0
      ? `${intervalJson.years} ${intervalJson.years === 1 ? 'year ' : 'years '}`
      : '';
  const months =
    intervalJson && intervalJson.months
      ? `${intervalJson.months} ${
          intervalJson.months === 1 ? 'month' : 'months'
        }`
      : '';
  /*const days =
    intervalJson && intervalJson.days !== undefined
      ? `${intervalJson.days} ${intervalJson.days === 1 ? 'day' : 'days'}`
      : '';*/
  const age = `${years}${months}`;
  return age;
};

export const calcDifferenceToToday = (startDate: any) => {
  return moment(new Date(startDate)).diff(moment(new Date()), 'days');
};
