import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Alert,
} from '@mui/material';
import { useLLMDiagnose } from '../../contexts/LLMDiagnoseContext';
import { extractSymptomsRequest, LLMError } from '../../api/LLM';
import { AutoAwesome, Fullscreen } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const LLMSymptoms = () => {
  const {
    freeText,
    setFreeText,
    extractedSymptoms,
    setExtractedSymptoms,
    setExtractedAnimal,
    setLoading,
    setError,
    error,
    loading,
  } = useLLMDiagnose();

  const extractedSymptomsCount = useMemo(() => {
    return Object.values(extractedSymptoms).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
  }, [extractedSymptoms]);
  const [showFreeTextDialog, setShowFreeTextDialog] = useState(false);

  const throttledOnChange = useMemo(() => {
    let timer: NodeJS.Timeout;
    return (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      clearTimeout(timer);
      timer = setTimeout(() => setFreeText(ev.target.value), 500);
    };
  }, [setFreeText]);

  const handleRequest = () => {
    setLoading(true);
    setError(null);
    return extractSymptomsRequest({ anamnesis: freeText })
      .then(({ symptoms, animal }) => {
        setExtractedSymptoms(symptoms);
        setExtractedAnimal(animal);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error loading LLM symptoms', error);
        if (error instanceof LLMError) {
          setError(
            `Your text didn't pass the  AI content moderation. Please change the text and try again.`
          );
        } else {
          setError('An error occurred. Please try again later.');
        }
        setExtractedSymptoms({});
        setExtractedAnimal(null);
      });
  };

  useEffect(() => {
    if (freeText && !extractedSymptomsCount) {
      handleRequest();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={1} alignItems={'stretch'}>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={4}
            defaultValue={freeText}
            onChange={throttledOnChange}
            placeholder="Describe your findings in own words. ex. 'Vomiting, fever and tremors'"
            fullWidth
            InputProps={{
              sx: { alignItems: 'flex-start' },
              endAdornment: (
                <IconButton onClick={() => setShowFreeTextDialog(true)}>
                  <Fullscreen />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            spacing={1}
            container
            flexDirection={'column'}
            style={{ height: '100%' }}
          >
            <Grid item textAlign="left">
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleRequest}
              >
                <AutoAwesome /> &nbsp; Process text
              </LoadingButton>
              {!loading && error && (
                <Typography style={{ marginTop: '1em' }} color="error">
                  {error}
                </Typography>
              )}
              {!loading && !error && !extractedSymptomsCount && (
                <Typography style={{ marginTop: '1em' }}>
                  No symptoms extracted from the text.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showFreeTextDialog}
        onClose={() => setShowFreeTextDialog(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent>
          <TextField
            multiline
            minRows={10}
            defaultValue={freeText}
            onChange={throttledOnChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFreeTextDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!error} onClose={() => setError(null)}>
        <DialogContent>
          <Alert severity="error">{error}</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LLMSymptoms;
