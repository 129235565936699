import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { AddedSymptoms } from './AddedSymptoms';
import Typography from '@mui/material/Typography';
import RequiredTooltip from './RequiredTooltip';
import { useGeographicalAreas } from '../../contexts/GeographicalAreasContext';
import { DiagnosesFilterOptions } from '../../pages/DiagnosesPage';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const CardSearchFilters = ({
  setDiagnosesFilterOptions,
  initialState,
  layoutDirection = 'row',
}: {
  setDiagnosesFilterOptions: (value: DiagnosesFilterOptions) => void;
  initialState: DiagnosesFilterOptions;
  layoutDirection?: 'row' | 'column';
}) => {
  const { geographicalAreas } = useGeographicalAreas();
  const { selectedSymptomsObj } = useDiagnosesPage();

  const [gaState, setGAState] = React.useState<number[]>(
    initialState.relevantGeographicalAreas.map((ga) => ga.GeographicalAreaId)
  );

  const [youngAnimals, setYoungAnimals] = React.useState(
    initialState.typicallyYoungAnimals
  );
  const [geriatricAnimals, setGeriatricAnimals] = React.useState(
    initialState.typicallyGeriatricAnimals
  );

  useEffect(() => {
    const ga: Array<{ GeographicalAreaId: number }> = gaState.map((Id) => ({
      GeographicalAreaId: Id,
    }));
    setDiagnosesFilterOptions({
      typicallyYoungAnimals: youngAnimals,
      typicallyGeriatricAnimals: geriatricAnimals,
      relevantGeographicalAreas: ga,
    });
  }, [gaState, youngAnimals, geriatricAnimals, setDiagnosesFilterOptions]);

  const handleGAChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    Id: number
  ) => {
    event.stopPropagation();
    const newGAState = [...gaState];
    const index = gaState.indexOf(Id);
    if (checked && index === -1) {
      newGAState.push(Id);
    } else if (!checked && index > -1) {
      newGAState.splice(index, 1);
    }
    setGAState(newGAState);
  };

  const addedAmount = Object.keys(selectedSymptomsObj).length;

  useEffect(() => {
    if (addedAmount === 0) {
      if (gaState.length > 0) {
        setGAState([]);
      }
      if (youngAnimals) {
        setYoungAnimals(false);
      }
      if (geriatricAnimals) {
        setGeriatricAnimals(false);
      }
    }
  }, [addedAmount, gaState.length, geriatricAnimals, youngAnimals]);

  if (addedAmount === 0) {
    return <Typography align="left">No symptoms are added</Typography>;
  }

  return (
    <Grid container spacing={3} flexDirection={layoutDirection}>
      <Grid item xs={12} sm={12} container>
        <Grid item xs={12}>
          <Typography
            align="left"
            style={{ paddingTop: '0.5em', fontWeight: 'bold' }}
          >
            Essential symptoms (must be on each disease&apos;s symptom list){' '}
            <RequiredTooltip />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AddedSymptoms showMandatorySwitch showRemoveButton={false} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}
      >
        <Grid item xs={12}>
          <Typography
            align="left"
            style={{ paddingTop: '0.5em', fontWeight: 'bold' }}
          >
            Relevant to geographical areas
          </Typography>
        </Grid>
        <Grid item xs={12} container direction="row">
          {geographicalAreas.map((ga) => {
            return (
              <Grid
                item
                key={ga.Name}
                xs={12}
                sm={6}
                style={{ textAlign: 'left' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={gaState.indexOf(ga.Id) > -1}
                      onChange={(event, checked) =>
                        handleGAChange(event, checked, ga.Id)
                      }
                      name={ga.Name}
                      color="primary"
                    />
                  }
                  label={ga.Name}
                />
              </Grid>
            );
          })}
        </Grid>
        {/* 
          //Hide typically young and geriatric disesaese filters for now.
          <Grid item xs={12}>
            <Typography
              align="left"
              style={{ paddingTop: '0.5em', fontWeight: 'bold' }}
            >
              Show diseases that occur
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={youngAnimals}
                    onChange={(e) => {
                      setYoungAnimals(e.target.checked);
                    }}
                    name="young animals"
                    color="primary"
                  />
                }
                label="Typically on young animals"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={geriatricAnimals}
                    onChange={(e) => {
                      setGeriatricAnimals(e.target.checked);
                    }}
                    name="geriatric animals"
                    color="primary"
                  />
                }
                label="Typically on geriatric animals"
              />
            </Grid>
          </Grid> */}
      </Grid>
    </Grid>
  );
};
