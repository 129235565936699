import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UNDEFINED_ID } from '../config/constant.params';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useDiagnosesPage } from '../contexts/DiagnosesPageContext';
import {
  useDoseParams,
  useDoseParamsDispatch,
} from '../contexts/DoseParamsContext';
import { useSymptoms, useSymptomsDispatch } from '../contexts/SymptomsContext';
import { useWorkContext } from '../contexts/WorkContext';

import { useRequestData } from '../hooks/useRequestData';

import { Breed } from '../types/Breed.type';
import { DoseParam, DoseParamWithOrder } from '../types/Dose.params.type';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';

import { useWorkHistory } from './useWorkHistory';
import { testPatients } from '../config/testPatients';

export const useDiagnoseRouter = () => {
  const {
    isLoading: isLoadingData,
    error: errorData,
    categories,
    symptomTypes,
    symptoms,
  } = useSymptoms();
  const dispatchSymptoms = useSymptomsDispatch();
  const dispatchPatientInfo = usePatientInfoDispatch();

  const needCategories =
    !errorData && !isLoadingData && categories.length === 0;

  const needTypes =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length === 0;

  const needSymptoms =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length > 0 &&
    symptoms.length === 0;

  useRequestData<SymptomTypeCategory[]>({
    needTransport: needCategories,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypeCategories' },
  });

  useRequestData<SymptomType[]>({
    needTransport: needTypes,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypes' },
  });

  useRequestData<Symptom[]>({
    needTransport: needSymptoms,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'Symptoms' },
  });

  const {
    doseTypes,
    dosageIntervals,
    treatmentDurations,
    treatmentDurationPrefixes,
    summaryDrugUnits,
    summaryResultDrugUnits,
    error,
    isLoading,
  } = useDoseParams();
  const dispatchDoseParams = useDoseParamsDispatch();

  useRequestData<DoseParamWithOrder[]>({
    needTransport: !error && !isLoading && doseTypes.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'DoseTypes' },
    dispatchOptions: 'DoseTypes',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'DosageIntervals' },
    dispatchOptions: 'DosageIntervals',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'TreatmentDurations' },
    dispatchOptions: 'TreatmentDurations',
  });

  useRequestData<DoseParam[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'TreatmentDurationPrefixes' },
    dispatchOptions: 'TreatmentDurationPrefixes',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length > 0 &&
      summaryDrugUnits.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'SummaryDrugUnits' },
    dispatchOptions: 'SummaryDrugUnits',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length > 0 &&
      summaryDrugUnits.length > 0 &&
      summaryResultDrugUnits.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'SummaryResultDrugUnits' },
    dispatchOptions: 'SummaryResultDrugUnits',
  });

  const { animalTypesObj } = useAnimalTypesContext();
  const {
    Id,
    breedId,
    breedName,
    isLoading: isLoadingBreed,
    error: errorBreed,
  } = usePatientInfo();
  const { patient, getPatient, visit, getVisit } = useWorkContext();
  const { selectedDiagnoses } = useDiagnosesPage();

  const currentUrlParams = useParams<{ patientId: string; visitId: string }>();

  // parse test_patient_id from query params
  const { search } = useLocation();
  const testPatientId = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('test_patient_id') || '';
  }, [search]);

  useEffect(() => {
    if (currentUrlParams?.patientId !== 'test') return;
    if (
      !testPatientId ||
      !animalTypesObj ||
      !Object.keys(animalTypesObj).length
    )
      return;
    const testPatient = testPatients.find(
      (patient) => patient.Id === parseInt(testPatientId)
    );
    if (!testPatient) return;
    dispatchPatientInfo({
      type: 'setPatientInfo',
      patient: testPatient,
      animalTypeName: animalTypesObj[testPatient.AnimalTypeId].Name,
    });
  }, [testPatientId, animalTypesObj, dispatchPatientInfo, currentUrlParams]);

  const patientId = currentUrlParams?.patientId;
  const visitId = currentUrlParams?.visitId;

  const noVisit = visit === null;
  useEffect(() => {
    if (noVisit && visitId && visitId !== 'test') {
      getVisit(parseInt(visitId!));
    }
  }, [getVisit, noVisit, visitId]);

  const noPatient =
    selectedDiagnoses && Id !== parseInt(patientId!) && !patient;
  useEffect(() => {
    if (noPatient && patientId !== 'test') {
      getPatient(parseInt(patientId!));
    }
  }, [noPatient, getPatient, patientId]);

  const noPatientInfo = selectedDiagnoses && patient && Id !== patient.Id;
  useEffect(() => {
    if (noPatientInfo) {
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient: patient!,
        animalTypeName: animalTypesObj[patient!.AnimalTypeId].Name,
      });
    }
  }, [animalTypesObj, dispatchPatientInfo, noPatientInfo, patient]);

  /*If we have a test patient case (patientId === test in urlParams) 
   but no patient info (Id === -1) we send user back to home page */
  /*if (Id === -1 && patientId === 'test' && !testPatientId) {
    history.push(ROUTE_HOME);
  }*/

  const noBreedName =
    currentUrlParams?.patientId !== 'test' &&
    selectedDiagnoses &&
    !isLoadingBreed &&
    !errorBreed &&
    breedId !== UNDEFINED_ID &&
    breedName === '';

  useRequestData<Breed[]>({
    needTransport: noBreedName,
    dispatch: dispatchPatientInfo,
    method: 'get',
    params: { modelName: 'Breeds', id: breedId },
  });

  if (errorBreed) {
    dispatchPatientInfo({ type: 'reset' });
    throw errorBreed;
  }

  const { goToWorkPageFromAnywhere } = useWorkHistory();

  useEffect(() => {
    if (patient && visit && `${visit.Id}` === visitId) {
      goToWorkPageFromAnywhere(patient, visit, true);
    }
  }, [goToWorkPageFromAnywhere, patient, visit, visitId]);
};
