import { Button, Grid, Typography } from '@mui/material';
import DogIcon from '../../assets/icons/DogIcon';
import CatIcon from '../../assets/icons/CatIcon';
import { useCallback } from 'react';

export const SpeciesSelect = ({
  species,
  handleChange,
  error,
}: {
  species: number;
  error: boolean;
  handleChange: (
    event: React.ChangeEvent<{ name: string; value: number }>,
    child: React.ReactNode
  ) => void;
}) => {
  const buttonCSS: React.CSSProperties = {
    flexDirection: 'column',
    color: '#333',
    margin: '1em',
    minWidth: '8em',
  };

  const setAnimalTypeId = useCallback(
    (id: number) => {
      handleChange({ target: { name: 'AnimalTypeId', value: id } } as any, id);
    },
    [handleChange]
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign={'left'}>
        <Button
          variant={species === 2 ? 'contained' : 'outlined'}
          size="large"
          style={buttonCSS}
          onClick={() => setAnimalTypeId(2)}
        >
          <DogIcon fontSize="large" />
          <Typography variant="h6">Dog</Typography>
        </Button>
        <Button
          variant={species === 1 ? 'contained' : 'outlined'}
          size="large"
          style={buttonCSS}
          onClick={() => setAnimalTypeId(1)}
        >
          <CatIcon fontSize="large" />
          <Typography variant="h6">Cat</Typography>
        </Button>
        {error && (
          <Typography color="error">
            You have a problem with this selection.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
