import { CurrentPatientInfoState } from '../reducers/currentPatientInfo/current.patient.info.state';
import { Diagnose, Treatment } from '../types/Diagnose.type';
import { TreatmentType } from '../components/diseases-and-treatments/types';

import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';
import { CalculatedDose } from '../reducers/dosagesPage/dosages.page.state';
import { DoseParam, DoseParamWithOrder } from '../types/Dose.params.type';
import { DrugProduct } from '../types/DrugProduct.type';
import { UNDEFINED_ID } from '../config/constant.params';
import { splitSymptomNameInTwoLines } from './symptomsUtils';

export const createSummaryAsEmail = (
  patientInfo: CurrentPatientInfoState,
  visitReason: string,
  diagnoseRows: Diagnose[],
  subsequentConditionDiseaseNames: string[],
  symptomRows: Symptom[],
  categories: SymptomTypeCategory[],
  symptomTypes: SymptomType[],
  selectedTreatments: number[],
  selectedTreatmentsObj: { [x: number]: Treatment },
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  treatmentDurationPrefixes: DoseParam[],
  summaryDrugUnits: DoseParamWithOrder[],
  summaryResultDrugUnits: DoseParamWithOrder[],
  doseTypes: DoseParamWithOrder[],
  dosageIntervals: DoseParamWithOrder[],
  treatmentDurations: DoseParamWithOrder[],
  drugProductsObj: { [x: number]: DrugProduct[] }
) => {
  return `PATIENT\n${createPatientString(patientInfo)}\n\n
VISIT REASON\n${visitReason}\n\n
DIAGNOSE(S)\n${diagnoseRows
    .map((obj) => createDiagnoseRowString(obj))
    .join('\n')}${subsequentConditionDiseaseNames.join('\n')}\n\n
SYMPTOM(S)\n${symptomRows
    .map((obj) => createSymptomRowString(obj, categories, symptomTypes))
    .join('\n')}\n\n
TREATMENT(S)\n${selectedTreatments
    .map((row, index) =>
      createTreatmentRowString(
        selectedTreatmentsObj[row],
        index,
        selectedDrugDosages,
        treatmentDurationPrefixes,
        doseTypes,
        dosageIntervals,
        treatmentDurations,
        summaryDrugUnits,
        summaryResultDrugUnits,
        drugProductsObj
      )
    )
    .join('')}`;
};

export const createPatientString = (patientInfo: CurrentPatientInfoState) => {
  return `${patientInfo.name || 'Unsaved patient'}, ${
    patientInfo.animalTypeName
  }, ${patientInfo.breedName}, ${patientInfo.genderName}, ${
    patientInfo.neutered === 1 ? 'neutered' : 'intact'
  }, ${patientInfo.weightStr}\n`;
};

export const createDiagnoseRowString = (diagnose: Diagnose) => {
  return `${diagnose.Disease.Name}`;
};

export const createSymptomRowString = (
  symptom: Symptom,
  categories: SymptomTypeCategory[],
  symptomTypes: SymptomType[]
) => {
  const nameLines = splitSymptomNameInTwoLines(symptom.combinedName!);

  return `CATEGORY: ${getSymptomTypeCategoryName(
    symptom,
    categories,
    symptomTypes
  )}   SYMPTOM: ${nameLines.secondLine}`;
};

export const createTreatmentRowString = (
  treatment: Treatment,
  index: number,
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  treatmentDurationPrefixes: DoseParam[],
  doseTypes: DoseParamWithOrder[],
  dosageIntervals: DoseParamWithOrder[],
  treatmentDurations: DoseParamWithOrder[],
  summaryDrugUnits: DoseParamWithOrder[],
  summaryResultDrugUnits: DoseParamWithOrder[],
  drugProducts: { [x: number]: DrugProduct[] }
) => {
  return `Treatment ${index + 1} \nTYPE: ${getTreatmentType(
    treatment
  )}        ${
    treatment.Type === TreatmentType.Drug ? 'DRUG SUBSTANCE' : 'NAME'
  }: ${getTreatmentName(treatment)}        ${
    treatment.Type === TreatmentType.Drug &&
    drugProducts[treatment.DrugSubtance.Id]?.length > 0
      ? getDrugProducts(treatment, selectedDrugDosages, drugProducts) +
        '\n' +
        getDrugDosages(
          treatment,
          selectedDrugDosages,
          treatmentDurationPrefixes,
          doseTypes,
          dosageIntervals,
          summaryDrugUnits,
          summaryResultDrugUnits,
          treatmentDurations
        )
      : '\n' +
        getDrugDosages(
          treatment,
          selectedDrugDosages,
          treatmentDurationPrefixes,
          doseTypes,
          dosageIntervals,
          summaryDrugUnits,
          summaryResultDrugUnits,
          treatmentDurations
        )
  }\nINFO: ${treatment.Info}     \nFOLLOW UP: ${
    treatment.FollowUp ? treatment.FollowUp : '-'
  }\n\n`;
};

export const getSymptomTypeName = (
  symptom: Symptom,
  symptomTypes: SymptomType[]
) => {
  return symptomTypes.find((obj) => obj.Id === symptom.TypeId)?.Name;
};

export const getSymptomTypeCategoryName = (
  symptom: Symptom,
  categories: SymptomTypeCategory[],
  symptomTypes: SymptomType[]
) => {
  return categories.find(
    (obj) =>
      obj.Id ===
      symptomTypes.find((obj) => obj.Id === symptom.TypeId)
        ?.SymptomTypeCategoryId
  )?.Name;
};

export const getDrugDosages = (
  treatment: Treatment,
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  treatmentDurationPrefixes: DoseParam[],
  doseTypes: DoseParamWithOrder[],
  dosageIntervals: DoseParamWithOrder[],
  summaryDrugUnits: DoseParamWithOrder[],
  summaryResultDrugUnits: DoseParamWithOrder[],
  treatmentDurations: DoseParamWithOrder[]
) => {
  const dosages =
    selectedDrugDosages[treatment.Id]?.[treatment.DrugSubtance.Id];
  const doses: string[] = [];
  for (const key in dosages) {
    if (Object.prototype.hasOwnProperty.call(dosages, key)) {
      dosages[key].forEach((dose) => {
        return doses.push(
          `Alternative ${doses.length + 1}\nDose type: ${
            doseTypes.find(
              (doseType) => doseType.Id === dose.doseInstruction.doseTypeId
            )?.Name
          }     Administration route: ${
            dose.drugAdministrationRoute
          }\nStrenght: ${dose.doseInstruction.strength} ${
            dose.doseInstruction.summaryDrugUnitId === UNDEFINED_ID
              ? ''
              : summaryDrugUnits.find(
                  (sdu) => sdu.Id === dose.doseInstruction.summaryDrugUnitId
                )?.Name
          }\nNumber of units: ${dose.doseInstruction.numberOfUnits} ${
            dose.doseInstruction.summaryResultDrugUnitId === UNDEFINED_ID
              ? ''
              : summaryResultDrugUnits.find(
                  (srdu) =>
                    srdu.Id === dose.doseInstruction.summaryResultDrugUnitId
                )?.Name
          }\nDose interval: ${dose.doseInstruction.dosageInterval} ${
            dose.doseInstruction.dosageIntervalId === UNDEFINED_ID
              ? ''
              : dosageIntervals.find(
                  (di) => di.Id === dose.doseInstruction.dosageIntervalId
                )?.Name
          } \nTreatment duration: ${
            dose.doseInstruction.treatmentDurationPrefixId === UNDEFINED_ID
              ? ''
              : treatmentDurationPrefixes.find(
                  (tdp) =>
                    tdp.Id === dose.doseInstruction.treatmentDurationPrefixId
                )?.Name
          } ${dose.doseInstruction.treatmentDuration} ${
            dose.doseInstruction.treatmentDurationId === UNDEFINED_ID
              ? ''
              : treatmentDurations.find(
                  (td) => td.Id === dose.doseInstruction.treatmentDurationId
                )?.Name
          }`
        );
      });
    }
  }
  return doses.length > 0
    ? doses.join('\n')
    : 'No dosages chosen for this treatment';
};

export const getDrugProductName = (
  treatment: Treatment,
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  drugProducts: { [x: number]: DrugProduct[] }
) => {
  const dosages =
    selectedDrugDosages[treatment.Id]?.[treatment.DrugSubtance.Id];

  const drugProductNames: string[] = [];

  for (const key in dosages) {
    if (Object.prototype.hasOwnProperty.call(dosages, key)) {
      dosages[key].map((dose) => {
        const productName = drugProducts[treatment.DrugSubtance.Id].find(
          (product) => product.Id === dose.drugProductId
        )?.TradeName;
        if (productName) {
          return drugProductNames.push(productName);
        }
        return '';
      });
    }
  }

  return drugProductNames.join(', ');
};

export const getTreatmentName = (treatment: Treatment) => {
  if (treatment.Type === TreatmentType.Drug) {
    return treatment.Name;
  } else if (treatment.Type === TreatmentType.Procedure) {
    return treatment.Procedure ? treatment.Procedure.Name : '';
  } else if (treatment.Type === TreatmentType.OtherTreatment) {
    return treatment.OtherTreatment ? treatment.OtherTreatment.Name : '';
  }
  return treatment.Name;
};

export const getTreatmentType = (treatment: Treatment) => {
  if (treatment.Type === TreatmentType.Drug) {
    return 'Drug';
  } else if (treatment.Type === TreatmentType.Procedure) {
    return 'Procedure';
  } else if (treatment.Type === TreatmentType.OtherTreatment) {
    return 'Other treatment';
  }
  return treatment.Name;
};

export const getDrugProducts = (
  treatment: Treatment,
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  drugProducts: { [x: number]: DrugProduct[] }
) => {
  return (
    'PRODUCT: ' +
    getDrugProductName(treatment, selectedDrugDosages, drugProducts)
  );
};
