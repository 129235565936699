import { CalculatedDose } from '../reducers/dosagesPage/dosages.page.state';
import { Diagnose, Treatment, TreatmentInfo } from '../types/Diagnose.type';
import { DoseParamWithOrder } from '../types/Dose.params.type';
import { Symptom } from '../types/Symptom.type';
import { getTreatmentName, getTreatmentType } from './summaryUtils';
import { splitSymptomNameInTwoLines } from './symptomsUtils';

export const getRWDdosages = (
  selectedTreatments: number[],
  selectedTreatmentsObj: { [x: number]: Treatment },
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  doseTypes: DoseParamWithOrder[]
) => {
  const arrayOfDosages: any = [];
  for (const treatmentId of selectedTreatments) {
    const treatmentInfo = selectedTreatmentsObj[treatmentId];
    if (treatmentInfo.DrugSubtance) {
      const dosages =
        selectedDrugDosages[treatmentInfo.Id]?.[treatmentInfo.DrugSubtance.Id];
      if (dosages) {
        const connectedTreatment = treatmentInfo.Name;
        const keys = Object.keys(dosages);
        for (const key of keys) {
          if (Object.prototype.hasOwnProperty.call(dosages, key)) {
            dosages[+key].forEach((dose) => {
              console.log(dose);
              const doseTypeName = doseTypes.find(
                (dt) => dt.Id === dose.doseInstruction.doseTypeId
              )?.Name;
              let strength = dose.doseInstruction.strength;
              let amount = dose.doseInstruction.numberOfUnits;
              let strengthUnit;
              let amountUnit;
              switch (dose.doseInstruction.summaryDrugUnitId) {
                case 1:
                  strengthUnit = 'mg/ml';
                  amountUnit = 'ml';
                  break;
                case 2:
                  strengthUnit = 'mg/tablet';
                  amountUnit = 'tablet(s)';
                  break;
                case 3:
                  strengthUnit = 'mg/capsule';
                  amountUnit = 'capsule(s)';
              }
              if (
                dose.doseInstruction.strength === '' &&
                dose.drugAmount.strength !== ''
              ) {
                strength = dose.drugAmount.strength;
                amount = dose.drugAmount.dose;
                switch (dose.drugAmount.unit) {
                  case 1:
                    strengthUnit = 'mg/ml';
                    amountUnit = 'ml';
                    break;
                  case 2:
                    strengthUnit = 'mg/tablet';
                    amountUnit = 'tablet(s)';
                    break;
                  case 3:
                    strengthUnit = 'mg/capsule';
                    amountUnit = 'capsule(s)';
                }
              } else if (dose.drugStrength.strength !== '') {
                strength = dose.drugStrength.result;
                amount = dose.drugStrength.strength;
                switch (dose.drugStrength.unit) {
                  case 1:
                    strengthUnit = 'mg/ml';
                    amountUnit = 'ml';
                    break;
                  case 2:
                    strengthUnit = 'mg/tablet';
                    amountUnit = 'tablet(s)';
                    break;
                  case 3:
                    strengthUnit = 'mg/capsule';
                    amountUnit = 'capsule(s)';
                }
              }
              arrayOfDosages.push({
                strength: strength,
                strengthUnit: strengthUnit,
                amount: amount,
                amountUnit: amountUnit,
                doseType: doseTypeName,
                connectedTreatment: connectedTreatment,
              });
            });
          }
        }
      }
    }
  }
  return arrayOfDosages;
};

export const getRWDtreatments = (
  selectedTreatments: number[],
  selectedTreatmentsObj: { [x: number]: Treatment },
  diagnoses: Diagnose[] | undefined
) => {
  if (selectedTreatments.length === 0) {
    return undefined;
  }
  const arrayOfTreatments = [];
  for (const treatmentId of selectedTreatments) {
    const treatmentName = getTreatmentName(selectedTreatmentsObj[treatmentId]);
    const treatmentType = getTreatmentType(selectedTreatmentsObj[treatmentId]);
    const connectedDiagnose = diagnoses?.find(
      (diagnose) =>
        diagnose.Id === selectedTreatmentsObj[treatmentId].AnimalType_DiseaseId
    );
    arrayOfTreatments.push({
      name: treatmentName,
      type: treatmentType,
      connectedDiagnosis: connectedDiagnose?.Disease.Name,
    });
  }
  return arrayOfTreatments;
};

export const getRWDdiagnosesMethod1 = (
  diagnoses: Diagnose[] | undefined,
  selectedDiagnoses: number[],
  selectedSubsequentDiseases: number[],
  subsequentTreatmentInfos: TreatmentInfo[] | undefined,
  currentDiagnose: any,
  canSelect: boolean
) => {
  const selected = diagnoses
    ? diagnoses.filter(
        (diagnose: Diagnose) => selectedDiagnoses.indexOf(diagnose.Id) > -1
      )
    : [];
  const arrayOfDiagnoses = [];
  for (const selectedDiagnosis of selected) {
    arrayOfDiagnoses.push({ name: selectedDiagnosis.Disease.Name });
  }
  if (selectedSubsequentDiseases.length > 0) {
    for (const a of selectedSubsequentDiseases) {
      const b = subsequentTreatmentInfos?.find((c) => c.Id === a);
      arrayOfDiagnoses.push({ name: b?.Disease.Name });
    }
  }
  if (!canSelect) {
    arrayOfDiagnoses.push({ name: currentDiagnose });
  } else {
    const index = arrayOfDiagnoses.map((e) => e.name).indexOf(currentDiagnose);
    console.log(index);
    if (index > -1) {
      arrayOfDiagnoses.splice(index, 1);
    }
  }
  return arrayOfDiagnoses;
};

export const getRWDdiagnosesMethod2 = (selected: Diagnose[]) => {
  if (selected.length === 0) {
    return undefined;
  }
  const arrayOfDiagnoses = [];
  for (const selectedDiagnosis of selected) {
    arrayOfDiagnoses.push({ name: selectedDiagnosis.Disease.Name });
  }
  return arrayOfDiagnoses;
};

export const getRWDdiagnosesMethod3 = (
  selectedDiagnoses: number[],
  diagnoses: Diagnose[] | undefined,
  selectedSubsequentDiseases: number[],
  subsequentTreatmentInfos: TreatmentInfo[] | undefined
) => {
  if (
    selectedDiagnoses.length === 0 &&
    selectedSubsequentDiseases.length === 0
  ) {
    return undefined;
  }
  const arrayOfDiagnoses = [];
  for (const a of selectedDiagnoses) {
    const b = diagnoses?.find((c) => c.Id === a);
    arrayOfDiagnoses.push({ name: b?.Disease.Name });
  }
  for (const a of selectedSubsequentDiseases) {
    const b = subsequentTreatmentInfos?.find((c) => c.Id === a);
    arrayOfDiagnoses.push({ name: b?.Disease.Name });
  }
  return arrayOfDiagnoses;
};

export const getRWDsymptoms = (
  selectedSymptoms: number[],
  symptoms: Symptom[],
  selectedSymptomsObj: { [x: string]: boolean }
) => {
  if (selectedSymptoms.length === 0) {
    return undefined;
  }
  const arrayOfSymptoms = [];
  let type = undefined;
  for (const symptomId of selectedSymptoms) {
    const symptom = symptoms.find(({ Id }) => Id === symptomId);
    if (!symptom) return;
    const main = symptom.MainSymptomId
      ? symptoms.find(({ Id }) => Id === symptom.MainSymptomId)
      : null;
    const symptomName = main ? `${main.Name}, ${symptom.Name}` : symptom.Name;
    const nameLines = splitSymptomNameInTwoLines(symptom.combinedName!);
    type = nameLines.firstLine.slice(0, -1);
    const required = selectedSymptomsObj[symptomId];
    arrayOfSymptoms.push({
      name: symptomName,
      type: type,
      required: required ? 'required' : 'optional',
    });
  }
  return arrayOfSymptoms;
};

export const getRWDage = (dob: Date | string | null) => {
  let age = 0;
  if (dob) {
    const dobEpoch = dob instanceof Date ? dob.valueOf() : Date.parse(dob);
    const ageAsDate = new Date(Date.now() - dobEpoch);
    age = +(
      ageAsDate.getUTCFullYear() -
      1970 +
      ageAsDate.getUTCMonth() / 12 +
      ageAsDate.getDate() / 365
    ).toFixed(2);
  }
  return age;
};
