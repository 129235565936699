import React from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import DashboardIcon from '../../assets/icons/DashboardIcon';
import PatientsIcon from '../../assets/icons/PatientsIcon';
import DiseasesIcon from '../../assets/icons/DiseasesIcon';
import DrugsIcon from '../../assets/icons/DrugsIcon';
import FeedbackIcon from '../../assets/icons/FeedbackIcon';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExposureIcon from '@mui/icons-material/Exposure';

import {
  ROUTE_HOME,
  ROUTE_DRUGS,
  ROUTE_PATIENTS,
  ROUTE_TREATMENTS,
  ROUTE_VISITS,
  ROUTE_SUPPORT,
  ROUTE_TUTORIAL,
  ROUTE_CALCULATOR,
  ROUTE_DIAGNOSE_HOME,
  ROUTE_PROFILE_INFO,
} from '../../config/routes.config';
import { NavItemListProps } from './navigation.types';
import DiagnosticsIcon from '../../assets/icons/DiagnosticsIcon';

export const ITEM_SPACING = 3;
export const DRAWER_WIDTH = 240;

export const navItems: NavItemListProps = [
  {
    title: 'Home',
    to: ROUTE_HOME,
    icon: <DashboardIcon />,
  },
  {
    title: 'Diagnostics',
    to: ROUTE_DIAGNOSE_HOME,
    icon: <DiagnosticsIcon />,
  },
  {
    title: 'Diseases',
    to: ROUTE_TREATMENTS,
    icon: <DiseasesIcon />,
  },
  { separator: true },
  {
    title: 'Drug substances',
    to: ROUTE_DRUGS,
    icon: <DrugsIcon />,
  },
  {
    title: 'Calculator',
    to: ROUTE_CALCULATOR,
    icon: <ExposureIcon style={{ color: '#444444' }} />,
  },
  {
    title: 'Patients',
    icon: <PatientsIcon />,
    to: ROUTE_PATIENTS,
  },
  {
    title: 'Visits',
    icon: <AssignmentIcon style={{ color: '#444444' }} />,
    to: ROUTE_VISITS,
  },
  { separator: true },
  {
    title: 'My Profile',
    to: ROUTE_PROFILE_INFO,
    icon: <PersonOutlineIcon style={{ color: '#444444' }} />,
  },

  {
    title: 'Support',
    to: ROUTE_SUPPORT,
    icon: <FeedbackIcon />,
  },
  {
    title: 'Tutorial',
    to: ROUTE_TUTORIAL,
    icon: <HelpOutlineIcon style={{ color: '#444444' }} />,
  },
  { separator: true },
  {
    title: 'Logout',
    to: '',
    icon: <PowerSettingsNewIcon style={{ color: '#444444' }} />,
  },
];
