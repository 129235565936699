import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import DiagnoseAccordionDetails from './DiagnoseAccordionDetails';
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Diagnose } from '../../types/Diagnose.type';
import ConditionTooltip from './ConditionTooltip';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { SubsequentDiagnose } from '../../reducers/diagnosesPage/diagnoses.page.state';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { splitSymptomNameInTwoLines } from '../../utils/symptomsUtils';
import SymptomsWithAddedInfoTooltip from './SymptomsWithAddedInfoTooltip';
import { ProbabilityTooltip } from './ProbabilityTooltip';

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

const useStyles = makeStyles()((theme: Theme) => ({
  column1: {
    flexBasis: '3%',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      marginRight: '10px',
    },
  },
  column2: {
    flexBasis: '92%',
    textAlign: 'left',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    paddingTop: '11px',
  },
  column3: {
    flexBasis: '5%',
  },
}));

export default function DiagnoseAccordion({
  diagnose,
  checked,
  highlightBreedId,
  updateDifferentials,
}: {
  diagnose: Diagnose;
  checked: boolean;
  highlightBreedId?: number | undefined;
  updateDifferentials: () => void;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const { classes } = useStyles();
  const { excludedDiagnoses } = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const { symptoms, symptomsObj } = useSymptoms();

  const addedInfoDiseaseSymptoms = diagnose.DiseaseSymptoms.some(
    (diseaseSymptom) => diseaseSymptom.ReferenceText !== null
  );

  const isExcluded = excludedDiagnoses.indexOf(diagnose.Id) > -1;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    checked: boolean
  ) => {
    event.stopPropagation();

    const subsequentDiagnoses: SubsequentDiagnose[] = [];
    if (checked) {
      diagnose.categorySymptoms![4].forEach((s) => {
        const EquivalentToDiseaseId = s.Symptom.EquivalentToDiseaseId;
        const nameLines = splitSymptomNameInTwoLines(
          symptoms[symptomsObj[s.Symptom.Id]].combinedName!
        );

        if (EquivalentToDiseaseId) {
          subsequentDiagnoses.push({
            Name: nameLines.secondLine,
            EquivalentToDiseaseId,
          });
        }
      });
    }

    if (!isExcluded) {
      dispatchPageDiagnoses({
        type: 'setDiagnoseSelected',
        checked,
        diagnoseId: diagnose.Id,
        ...(checked && { subsequentDiagnoses }),
      });
    }
  };

  /*const handleExclude = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    dispatchPageDiagnoses({
      type: 'toggleExcludeDiagnose',
      diagnoseId: diagnose.Id,
    });
  };*/

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newExpanded) => setExpanded(newExpanded)}
      square
      style={{
        backgroundColor: isExcluded ? 'lightgrey' : 'inherit',
        color: isExcluded ? 'grey' : 'inherit',
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<span></span>}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Typography
          className={`${classes.wrapIcon}`}
          flexGrow={1}
          component="div"
          flexDirection={'column'}
        >
          <p style={{ margin: 0, padding: 0 }}>
            {diagnose.Disease.Name}
            &nbsp;&nbsp;&nbsp;
            {diagnose.Condition === 1 && <ConditionTooltip />}
            {addedInfoDiseaseSymptoms && <SymptomsWithAddedInfoTooltip />}
          </p>
          <p style={{ margin: 0, padding: 0 }}>
            <Button variant="outlined" size="small" sx={{ marginTop: 1 }}>
              {expanded ? 'Hide information' : 'Show full information'}
            </Button>
          </p>
        </Typography>
        <div style={{ whiteSpace: 'nowrap' }}>
          <ToggleButtonGroup
            size="small"
            value={checked}
            exclusive
            color="primary"
            onChange={handleChange}
          >
            <ToggleButton value={true}>Selected</ToggleButton>
            <ToggleButton value={false}>Not selected</ToggleButton>
          </ToggleButtonGroup>
          <br />
          <small>
            Probability: {diagnose.Propability}
            <span style={{ display: 'inline-block', float: 'right' }}>
              <ProbabilityTooltip />
            </span>
          </small>
        </div>
      </AccordionSummary>
      <DiagnoseAccordionDetails
        diagnose={diagnose}
        highlightBreedId={highlightBreedId}
        updateDifferentials={updateDifferentials}
      />
    </Accordion>
  );
}
