import { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Modal,
  Portal,
  // Tooltip,
  Typography,
} from '@mui/material';
import { CardSymptoms } from '../components/symptoms/CardSymptoms';
//import { QuestionMarkOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import GekkoSpinner from '../assets/spinners/GekkoSpinner';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';
import { WorkRouter } from '../routers/WorkRouter';
import API from '../api/ApiLayer';
import {
  ProvetConsultation,
  ProvetConsultationNote,
  ProvetPatient,
} from './provet.types';
import { NodeHtmlMarkdown } from 'node-html-markdown';
import { Patient } from '../types/Patient.type';
import { PatientVisit } from '../types/PatientVisit.type';
import { useWorkHistory } from '../hooks/useWorkHistory';
import { WorkProvider } from '../contexts/WorkContext';
import LLMDiagnoseList, {
  ExtractedSymptom,
} from '../components/llm-diagnose/LLMDiagnoseList';
import { extractSymptomsRequest } from '../api/LLM';

export async function provetRequest<BodyType>(
  url: string,
  method = 'GET',
  body?: any
) {
  return API.postRequest<BodyType>('/provet/proxy', {
    headers: {
      Accept: 'application/json',
      'Content-Type': body ? 'application/json' : undefined,
    },
    method,
    url,
    body: body ? JSON.stringify(body) : undefined,
  });
}

type CONTINUE_TO_DIAGNOSE = 'initial' | 'yes' | 'forced';

const PmsDiagnoses = () => {
  const [showFullAnamnesis, setShowFullAnamnesis] = useState(false);
  const [showAddSymptoms, setShowAddSymptoms] = useState(false);
  const [symptomsLoading, setSymptomsLoading] = useState(false);
  const [anamnesis, setAnamnesis] = useState('');
  const [continueToDiagnose, setContinueToDiagnose] =
    useState<CONTINUE_TO_DIAGNOSE>('initial');
  const dispatchDiagnosesPage = useDiagnosesPageDispatch();
  const [extractedSymptoms, setExtractedSymptoms] = useState<{
    [key: string]: ExtractedSymptom[];
  }>({});
  const [selectedSymptoms, setSelectedSymptoms] = useState<number[]>([]);

  const { search } = useLocation();
  const navigate = useNavigate();

  const { goToWorkPageFromAnywhere } = useWorkHistory();
  const { selectedSymptoms: selectedSymptomsFromHistory } = useDiagnosesPage();

  const [patient, setPatient] = useState<Patient | null | undefined>(null);
  const [visit, setVisit] = useState<PatientVisit | null | undefined>(null);
  const [isExistingVisit, setIsExistingVisit] = useState<boolean | null>(null);
  const [provetPatientUrl, setProvetPatientUrl] = useState<string | null>(null);
  const [provetConsultationBody, setProvetConsultationBody] =
    useState<ProvetConsultation | null>(null);

  useEffect(() => {
    if (!provetPatientUrl) return;
    provetRequest<ProvetPatient>(provetPatientUrl).then(async (resp) => {
      if (!resp.body) return;
      const res = await API.postRequest<Patient>(
        '/provet-data/patient',
        resp.body
      );
      setPatient(res.body);
    });
  }, [provetPatientUrl]);

  useEffect(() => {
    if (!provetConsultationBody || !patient) return;
    API.postRequest<PatientVisit>(
      '/provet-data/visit',
      provetConsultationBody
    ).then(async (resp) => {
      setVisit(resp.body);
      if (!resp.body) return;
      await goToWorkPageFromAnywhere(patient, resp.body, true);
      if (resp.status === 200) {
        setIsExistingVisit(true);
        setContinueToDiagnose('yes');
      }
    });
  }, [provetConsultationBody, patient, goToWorkPageFromAnywhere]);

  const [hasLocalSymptomModifications, setHasLocalSymptomModifications] =
    useState(false);

  const [initialHistorySelectedSymptoms, setInitialHistorySelectedSymptoms] =
    useState<number[]>([]);

  useEffect(() => {
    if (hasLocalSymptomModifications) return;
    setInitialHistorySelectedSymptoms(selectedSymptomsFromHistory.slice());
  }, [selectedSymptomsFromHistory, hasLocalSymptomModifications]);

  useEffect(() => {
    if (continueToDiagnose === 'initial') return;
    if (!patient || !visit) return;
    if (
      continueToDiagnose === 'yes' &&
      initialHistorySelectedSymptoms?.length === 0
    )
      return;
    if (hasLocalSymptomModifications) return;

    setHasLocalSymptomModifications(true);
    for (const symptomId of selectedSymptoms) {
      dispatchDiagnosesPage({
        type: 'addSymptom',
        symptomId,
      });
    }
    navigate(
      `/work/patient/${patient.Id}/visit/${visit.Id}/diagnoses?directDiagnose=1`
    );
  }, [
    hasLocalSymptomModifications,
    continueToDiagnose,
    selectedSymptoms,
    navigate,
    visit,
    patient,
    dispatchDiagnosesPage,
    initialHistorySelectedSymptoms,
  ]);

  // De-select all symptoms
  useEffect(() => {
    setSelectedSymptoms([]);
  }, [extractedSymptoms]);

  // Fetch consultation, patient and clinical notes
  useEffect(() => {
    // parse consultation_id from search
    const searchParams = new URLSearchParams(search);
    const search_consultation_id = searchParams.get('consultation_id');
    if (!search_consultation_id) return;
    setSymptomsLoading(true);
    provetRequest<ProvetConsultation>(
      '/consultation/' + search_consultation_id
    ).then(async (resp) => {
      if (!resp.body) return;
      setProvetConsultationBody(resp.body);
      (window as any).pmsIntegration.setProvetConsultationUrl(resp.body.url);
      setProvetPatientUrl(resp.body.patients[0]);
      const clinical_notes = resp.body.consultation_notes;
      const anamnesis = (
        await Promise.all(
          clinical_notes.map((url) =>
            provetRequest<ProvetConsultationNote>(url)
          )
        )
      )
        .filter((noteResp) => noteResp.body?.draft === false)
        .map((noteResp) => noteResp.body?.text)
        .join('\n\n');
      setAnamnesis(NodeHtmlMarkdown.translate(anamnesis || ''));
    });
  }, [search]);
  useEffect(() => {
    if (!anamnesis || isExistingVisit === true) return;
    extractSymptomsRequest({ anamnesis }).then((resp) => {
      setSymptomsLoading(false);
      setExtractedSymptoms(resp.symptoms);
    });
  }, [anamnesis, isExistingVisit]);

  function shouldContinueToDiagnose(continueToDiagnose: CONTINUE_TO_DIAGNOSE) {
    if (continueToDiagnose === 'forced') return true;
    if (continueToDiagnose === 'initial') return false;
    return !!initialHistorySelectedSymptoms?.length;
  }

  return (
    <>
      {!shouldContinueToDiagnose(continueToDiagnose) ? (
        <div>
          <Typography variant="h4">
            Search for differential diagnoses
          </Typography>
          <Typography variant="subtitle1">
            This tool will analyze the clinical notes and offer a list of
            possible differential diagnoses based on the symptoms.
          </Typography>
          {symptomsLoading ? (
            <div style={{ textAlign: 'center', padding: '3em' }}>
              <GekkoSpinner />
            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h5">Clinical notes</Typography>
                <Typography
                  component="pre"
                  fontStyle={'italic'}
                  fontSize={'80%'}
                  style={{ whiteSpace: 'pre-wrap' }}
                  onClick={() => setShowFullAnamnesis(!showFullAnamnesis)}
                >
                  {showFullAnamnesis ? anamnesis : anamnesis.substring(0, 100)}
                  {anamnesis.length > 100 && !showFullAnamnesis ? '...' : ''}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h5">Symptoms</Typography>
                <Typography variant="subtitle1">
                  Based on the clinical notes, following findings are suggested.
                  Select applicable findings to start diagnosing.
                </Typography>

                <LLMDiagnoseList
                  symptomsLoading={symptomsLoading}
                  extractedSymptoms={extractedSymptoms}
                  selectedSymptoms={selectedSymptoms}
                  setSelectedSymptoms={setSelectedSymptoms}
                />
                <Modal
                  onClose={() => setShowAddSymptoms(false)}
                  open={showAddSymptoms}
                >
                  <CardSymptoms />
                </Modal>
                <Portal
                  container={document.querySelector(
                    '#pms-integration-footer-actions'
                  )}
                >
                  <p style={{ textAlign: 'right', marginTop: '0' }}>
                    <Button
                      onClick={() => setContinueToDiagnose('forced')}
                      variant="contained"
                      color="primary"
                    >
                      Continue
                    </Button>
                  </p>
                </Portal>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        <WorkProvider>
          <WorkRouter />
        </WorkProvider>
      )}
    </>
  );
};

export default PmsDiagnoses;
