import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { AppProvider } from './contexts/Context';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Layout from './pms-integration/Layout';

import { PMSIntegrationUserProvider as AuthUserProvider } from './contexts/AuthUserContext';
import { provetRequest } from './pms-integration/PmsDiagnoses';

function nl2br(str: string) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = '<br>';
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
}

// TODO: remove me
//window.sessionStorage.setItem('provet_access_token', '123');

// read vendor from query string and choose the right theme
const searchParams = new URLSearchParams(window.location.search);
const vendor = searchParams.get('vendor');
const consultation = searchParams.get('consultation_id');

const themes: { [key: string]: Partial<ThemeOptions> } = {
  provet: {
    spacing: 8,
    typography: {
      h4: {
        marginTop: '1em',
      },
      h5: {
        marginTop: '1em',
      },
    },
  },
};

const theme = themes[vendor || 'provet'];

function setProvetAuthHeader() {
  // auth header format: ProvetPMS <provet_environment_id>;<provet_user_id>;<provet_timestamp>;<provet_verification_hash>;
  const provet_environment_id = searchParams.get('provet_environment_id');
  const provet_user_id = searchParams.get('user');
  const provet_timestamp = searchParams.get('timestamp');
  const provet_verification_hash = searchParams.get('verification');

  const auth = `ProvetPMS ${provet_environment_id};${provet_user_id};${provet_timestamp};${provet_verification_hash}`;
  window.sessionStorage.setItem('gekko/authheader', auth);
}

function App() {
  useEffect(() => {
    if (vendor === 'provet') {
      setProvetAuthHeader();
      const win = window as any;
      const pmsIntegration = (win.pmsIntegration = {
        provetConsultationUrl: '',
        vendor,
        waitAndClose: function (work?: () => Promise<void>) {
          return async () => {
            if (work) await work();
            Promise.all(pmsIntegration.pending).then(() => {
              try {
                console.log('will close sidebar window');
                window.parent.postMessage('reload', '*');
              } catch (e) {
                console.log('Failed to post message to parent', e);
              }
            });
          };
        },
        pending: [] as Promise<any>[],
        setProvetConsultationUrl: function (url: string) {
          pmsIntegration.provetConsultationUrl = url;
        },
        saveSummary: function (summary: string) {
          const promise = provetRequest(
            `/consultation/${consultation}/consultationnote/`,
            'POST',
            {
              consultation: pmsIntegration.provetConsultationUrl,
              text: nl2br(summary),
              draft: false,
              type: 0,
            }
          );
          pmsIntegration.pending.push(promise);
          return promise;
        },
        saveSummaryButtonText:
          vendor === 'provet'
            ? 'Save summary to Provet Cloud consultation'
            : 'Save summary to PMS',
      });
    }
  }, []);
  return (
    <AuthUserProvider>
      <BrowserRouter basename="/pms">
        <CssBaseline />
        <ThemeProvider theme={createTheme(theme)}>
          <StyledEngineProvider>
            <AppProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Routes>
                  <Route element={<Layout />} />
                </Routes>
              </LocalizationProvider>
            </AppProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthUserProvider>
  );
}

export default App;
