import React from 'react';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { TreatmentInfo } from '../../types/Diagnose.type';
import DiagnoseAccordion from './DiagnoseAccordion';
import {
  getRWDage,
  getRWDdiagnosesMethod1,
  getRWDsymptoms,
} from '../../utils/rwdUtils';
import { useRWDContext } from '../../contexts/RWDContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useParams } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function SubsequentDisease({
  treatmentInfo,
  canSelect,
}: {
  treatmentInfo: TreatmentInfo;
  canSelect: boolean;
}) {
  const {
    subsequentTreatmentsObj,
    subsequentTreatmentInfos,
    selectedTreatmentsObj,
    selectedTreatments: selectedTreatmentIds,
    selectedSubsequentDiseases,
  } = useTreatmentsPage();
  const dispatchTreatments = useTreatmentsPageDispatch();

  const { patientId } = useParams<{ patientId: string }>();

  // RWD variables
  const { track: trackRWD } = useRWDContext();
  const {
    diagnoses,
    selectedDiagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
  } = useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const patientInfo = usePatientInfo();
  // End of RWD variables

  const handleSelect = () => {
    const deselectTreatmentIds: number[] =
      subsequentTreatmentInfos && subsequentTreatmentsObj
        ? subsequentTreatmentInfos[
            subsequentTreatmentsObj[treatmentInfo.Id]
          ].Treatments.map((treatment) => treatment.Id)
        : [];

    const deselectedTreatmentIds: number[] = [];
    selectedTreatmentIds.forEach((treatmentId) => {
      if (
        selectedTreatmentsObj[treatmentId].TreatmentSet.Id === treatmentInfo.Id
      ) {
        deselectedTreatmentIds.push(treatmentId);
      }
    });

    window.dataLayer.push({
      event: 'selectedSubsequentDisease',
      diseaseId: treatmentInfo.Disease.Name,
    });
    dispatchTreatments({
      type: 'setSubsequentDiseaseSelected',
      checked: !canSelect,
      diseaseId: treatmentInfo.Id,
    });
    if (canSelect) {
      dispatchTreatments({
        type: 'deselectTreatments',
        deselectTreatmentIds,
      });
    }
    // Start of RWD functionality
    const arrayOfDiagnoses = getRWDdiagnosesMethod1(
      diagnoses,
      selectedDiagnoses,
      selectedSubsequentDiseases,
      subsequentTreatmentInfos,
      treatmentInfo.Disease.Name,
      canSelect
    );
    const arrayOfSymptoms = getRWDsymptoms(
      selectedSymptoms,
      symptoms,
      selectedSymptomsObj
    );
    const age = getRWDage(patientInfo.dateOfBirth);

    trackRWD({
      actionName: 'selected_diagnoses',
      diseases: arrayOfDiagnoses,
      symptoms: arrayOfSymptoms,
      signalmentSpecies:
        patientInfo.animalTypeName[0].toUpperCase() +
        patientInfo.animalTypeName.slice(1),
      signalmentBreed:
        patientInfo.breedName === '' ? undefined : patientInfo.breedName,
      signalmentWeight: +patientInfo.weight,
      signalmentGender: patientInfo.genderName,
      signalmentAge: age,
      signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
      signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
    });
    // end of RWD functionality
  };

  return (
    <DiagnoseAccordion
      diagnose={treatmentInfo}
      handleSelect={!canSelect ? handleSelect : undefined}
      hideTreatment={!canSelect}
    />
  );
}
