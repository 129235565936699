import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DiseaseInfo,
  Treatment,
  TreatmentInfo,
  TreatmentSet,
} from '../../types/Diagnose.type';
import { TreatmentCard } from './TreatmentCard';
import { TreatmentProtocolCard } from './TreatmentProtocolCard';
import { Card, CardContent, useMediaQuery } from '@mui/material';
import { splitAndRender } from '../treatments/DiseaseInfo';
import API from '../../api/Api';

export const Treatments = ({
  treatmentInfo,
  treatmentSets,
  fetchAndShowTreatmentDosages,
  selectedTreatmentSetId,
  setSelectedTreatmentSetId,
  diseaseInfo: originalDiseaseInfo,
}: {
  treatmentInfo: TreatmentInfo;
  treatmentSets: TreatmentSet[];
  diseaseInfo: DiseaseInfo;
  fetchAndShowTreatmentDosages: (
    treatmentId: number,
    animalTypeId: number,
    drugSubstanceId: number,
    chosenTreatment: Treatment
  ) => void;
  selectedTreatmentSetId: number | undefined;
  setSelectedTreatmentSetId: (treatmentSetId: number) => void;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [diseaseInfo, setDiseaseInfo] = useState(originalDiseaseInfo);

  useEffect(() => {
    API.getDiseaseInfoDetails(originalDiseaseInfo.Id + '').then((result) => {
      setDiseaseInfo((result as any).body[0]);
    });
  }, [originalDiseaseInfo]);

  const executeScroll = () => {
    scrollRef.current?.scrollIntoView();
  };

  const onTreatmentProtocolClick = (treatmentSetId: number) => {
    setSelectedTreatmentSetId(treatmentSetId);
    executeScroll();
  };

  const treatmentSetTreatments = treatmentInfo.Treatments.filter(
    (treatment) => {
      return treatment.TreatmentSet.Id === selectedTreatmentSetId;
    }
  );

  const showDosagesForTreatment = (treatment: Treatment) => {
    fetchAndShowTreatmentDosages(
      treatment.Id,
      treatmentInfo.AnimalTypeId,
      treatment.DrugSubtance.Id,
      treatment
    );
  };

  const mobile = useMediaQuery('(max-width:600px)');

  const generalInfo = useMemo(
    () => (
      <Card style={{ marginBottom: '1em' }}>
        <CardContent>
          <h4>DISEASE INFO</h4>
          {splitAndRender(diseaseInfo.Info, 'body2')}
          <h4>GENERAL TREATMENT INFO</h4>
          {splitAndRender(
            diseaseInfo.TreatmentInfo,
            'body2',
            'Treatment information for this disease/condition not yet available.'
          )}
          <h4>FOLLOW UP</h4>
          {splitAndRender(
            diseaseInfo.FollowUp,
            'body2',
            'Specific follow up information for this treatment not available.'
          )}
        </CardContent>
      </Card>
    ),
    [diseaseInfo]
  );

  return (
    <div ref={scrollRef}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Grid container>
            <Grid item>
              <TreatmentProtocolCard
                onClick={onTreatmentProtocolClick}
                selected={selectedTreatmentSetId === -1}
                treatmentSet={{
                  Id: -1,
                  Name: 'General information',
                  Order: -1,
                }}
                selectedTreatments={[]}
              ></TreatmentProtocolCard>
              {mobile && selectedTreatmentSetId === -1 && generalInfo}
              {treatmentSets.map((treatmentSet) => {
                return (
                  <React.Fragment key={treatmentSet.Id}>
                    <TreatmentProtocolCard
                      onClick={onTreatmentProtocolClick}
                      selected={treatmentSet.Id === selectedTreatmentSetId}
                      treatmentSet={treatmentSet}
                      selectedTreatments={[]}
                    ></TreatmentProtocolCard>
                    {mobile &&
                      treatmentSet.Id === selectedTreatmentSetId &&
                      treatmentSetTreatments.map((treatment) => {
                        return (
                          <TreatmentCard
                            key={treatment.Id}
                            treatment={treatment}
                          />
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8}>
          {!mobile && selectedTreatmentSetId === -1 && generalInfo}
          {!mobile &&
            treatmentSetTreatments.map((treatment) => {
              return (
                <TreatmentCard
                  key={treatment.Id}
                  treatment={treatment}
                  showDosages={showDosagesForTreatment}
                />
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};
