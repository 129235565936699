import { Visit } from '../components/Visit';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTE_CURRENT_VISIT_SAVED } from '../config/routes.config';
import { useWorkContext } from '../contexts/WorkContext';
import API from '../api/Api';
import { useVisitsListDispatch } from '../contexts/VisitsListContext';
import { NewPatientVisit, PatientVisit } from '../types/PatientVisit.type';
import { useIntegrationContext } from '../contexts/IntegrationContext';
import Loader from '../components/Loader';
import { Grid } from '@mui/material';
import { Card } from '../components/common/Card';
import { VisitList } from '../components/patient-summary/VisitList/VisitList';
import { formatDateToRequest } from '../utils/dateUtils';
import { sub } from 'date-fns';
import { useRWDContext } from '../contexts/RWDContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { useRequestData } from '../hooks/useRequestData';
import { useBreedsContext, useBreedsDispatch } from '../contexts/BreedsContext';
import { Breed } from '../types/Breed.type';
import { getRWDage } from '../utils/rwdUtils';

export const VisitPage = () => {
  const { visitId, patientId }: any = useParams();
  const navigate = useNavigate();
  const {
    patient,
    getPatient,
    setVisit: setWorkVisit,
    visit: workVisit,
    integrationVisit: integrationVisitData,
    getVisit: getWorkVisit,
    getIntegrationVisit,
  } = useWorkContext();
  const dispatchVisitsList = useVisitsListDispatch();

  const [loadedPatient, setLoadedPatient] = useState(false);
  const [loadedVisit, setLoadedVisit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [visitSaved, setVisitSaved] = useState(false);
  const [integrationVisit, setIntegrationVisit] = useState(false);
  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();
  const isEditPage = visitId ? true : false;
  const [previousVisits, setPreviousVisits] = useState<PatientVisit[]>([]);

  // Start of RWD help stuff
  const { track: trackRWD } = useRWDContext();
  const { animalTypes } = useAnimalTypesContext();
  const {
    isLoading: isLoadingBreeds,
    breeds,
    error: breedsError,
  } = useBreedsContext();
  const dispatchBreeds = useBreedsDispatch();
  const needBreeds = !breedsError && !isLoadingBreeds && breeds.length === 0;
  useRequestData<Breed[]>({
    needTransport: needBreeds,
    dispatch: dispatchBreeds,
    method: 'get',
    params: { modelName: 'Breeds' },
  });
  // End of RWD help stuff

  const fetchPatientVisits = useCallback(async () => {
    const todayDate = new Date();
    const queryObj: any = {
      modelName: 'PatientVisits',
      PatientId: patientId,
    };
    queryObj.DateFrom = formatDateToRequest(sub(todayDate, { days: 100 }));
    queryObj.DateTo = formatDateToRequest(todayDate);
    const patientVisitsResponse: any = await API.getModel(queryObj);
    return patientVisitsResponse;
  }, [patientId]);
  useEffect(() => {
    fetchPatientVisits().then((visitsResponse) => {
      setPreviousVisits(visitsResponse.body);
    });
  }, [fetchPatientVisits]);

  const visit: PatientVisit | null = useMemo(() => {
    if (workVisit && isEditPage) {
      return { ...workVisit };
    }
    return null;
  }, [isEditPage, workVisit]);

  const updateVisit = async (payload: object) => {
    const patientResponse: any = await API.updateVisit(payload, {}, visitId);
    setWorkVisit(patientResponse.body);
    setVisitSaved(true);
  };

  const createVisit = async (payload: NewPatientVisit) => {
    const visitResponse: any = await API.createVisit(payload);
    await API.trackEvent('create_visit');

    // Start of RWD functionality
    // Find species name for species id
    const speciesInfo = animalTypes.find((species) => {
      return species.Id === patient?.AnimalTypeId;
    });
    let age = 0;
    if (patient) {
      age = getRWDage(patient.DateOfBirth);
    }
    // set gender string
    let gender = 'female';
    if (patient?.GenderId === 1) {
      gender = 'male';
    }
    // find breed name for breed id
    const breed = breeds.find((breed) => {
      return breed.Id === patient?.BreedId;
    });
    let speciesName = undefined;
    if (speciesInfo) {
      speciesName =
        speciesInfo!.Name[0].toUpperCase() + speciesInfo?.Name.slice(1);
    }
    trackRWD({
      actionName: 'created_visit',
      signalmentSpecies: speciesName,
      signalmentBreed: breed?.Name === '' ? undefined : breed?.Name,
      signalmentWeight: patient?.Weight ? +patient.Weight : 0,
      signalmentGender: gender,
      signalmentAge: age,
      signalmentNeutered: patient?.Neutered ? 'neutered' : 'intact',
      signalmentPatientType: 'clinical',
    });
    // End of RWD functionality
    setWorkVisit(visitResponse.body);
    setVisitSaved(true);
  };

  const preloadPatient = useCallback(() => {
    if (!patient && !loadedPatient) {
      setLoadedPatient(true);
      getPatient(Number(patientId));
    }
  }, [getPatient, loadedPatient, patient, patientId]);

  const preloadVisitForEdit = useCallback(() => {
    if (!workVisit && !loadedVisit && isEditPage) {
      setLoadedVisit(true);
      getWorkVisit(Number(visitId));
    } else if (
      integrationConnectionInfo &&
      integrationInfo &&
      integrationSource &&
      !isEditPage
    ) {
      setLoadedVisit(true);
      setIntegrationVisit(true);
      getIntegrationVisit(
        integrationSource,
        integrationConnectionInfo,
        integrationInfo
      );
    }
  }, [
    workVisit,
    setLoadedVisit,
    loadedVisit,
    visitId,
    isEditPage,
    getWorkVisit,
    integrationConnectionInfo,
    integrationInfo,
    integrationSource,
    getIntegrationVisit,
    setIntegrationVisit,
  ]);

  const redirectAfterCreate = useCallback(() => {
    if (workVisit !== null && visitSaved) {
      dispatchVisitsList({
        type: 'add',
        visit: workVisit,
      });
      const path = generatePath(ROUTE_CURRENT_VISIT_SAVED, {
        patientId,
        visitId: String(workVisit.Id),
      });

      navigate(path);
    }
  }, [dispatchVisitsList, navigate, patientId, visitSaved, workVisit]);

  const redirectAfterUpdate = useCallback(() => {
    if (workVisit !== null && visitSaved && isEditPage) {
      dispatchVisitsList({
        type: 'update',
        visit: workVisit,
      });
      const path = generatePath(ROUTE_CURRENT_VISIT_SAVED, {
        patientId,
        visitId: String(workVisit.Id),
      });
      navigate(path);
    }
  }, [
    dispatchVisitsList,
    navigate,
    isEditPage,
    patientId,
    visitSaved,
    workVisit,
  ]);

  useEffect(redirectAfterCreate, [redirectAfterCreate]);
  useEffect(redirectAfterUpdate, [redirectAfterUpdate]);
  useEffect(preloadPatient, [preloadPatient]);
  useEffect(preloadVisitForEdit, [preloadVisitForEdit]);

  if (integrationVisit && !integrationVisitData) {
    return <Loader showLoader={true} />;
  }
  if (!patient) return null;

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={isEditPage ? 12 : 8} sm={12}>
        <Visit
          isEditPage={isEditPage}
          visit={visit}
          patient={patient}
          visitSaving={isSaving}
          createVisit={createVisit}
          updateVisit={updateVisit}
          setVisitSaving={setIsSaving}
          integrationVisit={integrationVisit}
          integrationVisitData={integrationVisitData}
        ></Visit>
      </Grid>
      {!isEditPage ? (
        <Grid item md={4} sm={12}>
          <Card
            style={{ paddingTop: '10%' }}
            title="PREVIOUS VISITS"
            pageView="drawerView"
            withoutMarginBottom
          >
            <VisitList
              title=""
              visits={previousVisits}
              setVisitLinkClicked={setIsSaving}
              patient={patient}
            />
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
};
