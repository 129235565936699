import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_INTEGRATION_AUTH,
  ROUTE_INTEGRATION_LOGIN,
  ROUTE_PROFILE,
  ROUTE_PAYMENT,
  ROUTE_SUPPORT,
  ROUTE_TOOLS,
  ROUTE_TUTORIAL,
  ROUTE_WORK,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_ERROR,
} from '../config/routes.config';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../contexts/SubscriptionContext';
import { WorkProvider } from '../contexts/WorkContext';
import { HomePage } from '../pages/HomePage';
import { IntegrationAuthentication } from '../pages/IntegrationAuthentication';
import { IntegrationLogin } from '../pages/IntegrationLogin';
import { PaymentPage } from '../pages/PaymentPage';
import { SupportPage } from '../pages/SupportPage';
import { TutorialPage } from '../pages/TutorialPage';
import { ProfileRouter } from '../routers/ProfileRouter';
import { ToolsRouter } from '../routers/ToolsRouter';
import { WorkRouter } from '../routers/WorkRouter';
import ErrorReportingTestPage from '../pages/ErrorReportingTestPage';
import ChatPage from '../pages/ChatPage';
import { PaymentSuccessPage } from '../pages/PaymentSuccessPage';
import { PaymentErrorPage } from '../pages/PaymentErrorPage';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const SubscriptionLayout = () => {
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: window.location.pathname,
  });

  const { error: errorSubscription } = useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  useEffect(() => {
    if (errorSubscription) {
      dispatchSubscription({ type: 'reset' });
    }
  }, [errorSubscription, dispatchSubscription]);

  return (
    <Routes>
      <Route path="/broken" element={<ErrorReportingTestPage />} />
      <Route path={ROUTE_HOME} element={<HomePage />} />
      <Route
        path={`${ROUTE_WORK}/*`}
        element={
          <WorkProvider>
            <WorkRouter />
          </WorkProvider>
        }
      />
      <Route path={`${ROUTE_TOOLS}/*`} element={<ToolsRouter />} />
      <Route path={`${ROUTE_PROFILE}/*`} element={<ProfileRouter />} />
      <Route path={ROUTE_SUPPORT} element={<SupportPage />} />
      <Route path={ROUTE_TUTORIAL} element={<TutorialPage />} />
      <Route
        path={ROUTE_INTEGRATION_AUTH}
        element={<IntegrationAuthentication />}
      />
      <Route path={ROUTE_INTEGRATION_LOGIN} element={<IntegrationLogin />} />
      <Route path={ROUTE_PAYMENT} element={<PaymentPage />} />
      <Route path={ROUTE_PAYMENT_SUCCESS} element={<PaymentSuccessPage />} />
      <Route path={ROUTE_PAYMENT_ERROR} element={<PaymentErrorPage />} />
      <Route path={'/chat'} element={<ChatPage />} />
    </Routes>
  );
};
