import React from 'react';
//import Checkbox from '@mui/material/Checkbox';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { splitSymptomNameInTwoLines } from '../../utils/symptomsUtils';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { ExtractedSymptom } from '../llm-diagnose/LLMDiagnoseList';
import {
  Check,
  CheckBoxOutlineBlank,
  Clear,
  Search,
} from '@mui/icons-material';
import { SymptomByName } from './SymptomByName';
import { SymptomByType } from './SymptomByType';

export const AddedSymptoms = ({
  showMandatorySwitch = false,
  showRemoveButton = true,
  showAddSymptoms = false,
  potentialSymptoms,
  onResetSymptoms,
}: {
  showMandatorySwitch?: boolean;
  showRemoveButton?: boolean;
  potentialSymptoms?: ExtractedSymptom[];
  showAddSymptoms?: boolean;
  onResetSymptoms?: () => void;
}) => {
  const { selectedSymptoms, selectedSymptomsObj } = useDiagnosesPage();
  const { symptoms, symptomsObj } = useSymptoms();
  const dispatch = useDiagnosesPageDispatch();
  const [symptomDialogOpen, setSymptomDialogOpen] = React.useState(false);

  const handleMandatoryChecked = (checked: boolean, Id: number) => {
    dispatch({
      type: 'setSymptomMandatory',
      symptomId: Id,
      checked,
    });
  };

  const toggleSelected = (Id: number) => {
    const isSelected = selectedSymptoms.includes(Id);
    if (isSelected) {
      dispatch({ type: 'removeSymptom', symptomId: Id });
    } else {
      dispatch({ type: 'addSymptom', symptomId: Id });
    }
  };

  const renderItem = (Id: number) => {
    const isSelected = selectedSymptoms.includes(Id);
    if (showRemoveButton)
      return (
        <Chip
          sx={{
            mb: 1,
            mr: 1,
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '80%',
            },
          }}
          onClick={() => toggleSelected(Id)}
          color={isSelected ? 'primary' : 'default'}
          icon={isSelected ? <Check /> : <CheckBoxOutlineBlank />}
          label={renderItemContent(Id)}
        />
      );
    return renderItemContent(Id);
  };

  const renderItemContent = (Id: number) => {
    const nameLines = splitSymptomNameInTwoLines(
      symptoms[symptomsObj[Id]].combinedName!
    );

    return (
      <Grid
        container
        style={{
          paddingTop: '0.5em',
          paddingBottom: '0.5em',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}
        alignItems="center"
        item
        xs={12}
      >
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Typography
            style={{
              fontFamily: 'Arial',
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: 'bold',
              lineHeight: '1.6',
              letterSpacing: '1.5px',
              opacity: '0.6',
            }}
          >
            {nameLines.firstLine}
          </Typography>
          <Typography
            style={{ whiteSpace: showRemoveButton ? 'nowrap' : 'pre-line' }}
          >
            {nameLines.secondLine}
          </Typography>
        </Grid>
        {showMandatorySwitch && (
          <Grid item xs={12} textAlign={'right'} style={{ margin: '1em' }}>
            <ToggleButtonGroup size="small">
              <ToggleButton
                value={true}
                color="primary"
                style={{
                  fontWeight: selectedSymptomsObj[Id] ? 'bold' : 'normal',
                }}
                selected={!!selectedSymptomsObj[Id] || false}
                onClick={() => handleMandatoryChecked(true, Id)}
              >
                {!!selectedSymptomsObj[Id] && (
                  <Check style={{ marginRight: '0.3em' }} />
                )}
                Required
              </ToggleButton>
              <ToggleButton
                value={false}
                color="primary"
                selected={!selectedSymptomsObj[Id]}
                onClick={() => handleMandatoryChecked(false, Id)}
              >
                Optional
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div>
      {selectedSymptoms.length === 0 && !potentialSymptoms?.length && (
        <Typography align="left">
          You can also search for symptoms manually by clicking the button
          below.
        </Typography>
      )}
      {!!potentialSymptoms?.length && (
        <Typography align="left" style={{ marginBottom: '1em' }}>
          Choose the relevant symptoms from the list below. If you can&apos;t
          find all relevant symptoms from the list below, you can also search
          for symptoms manually by clicking the button below.
        </Typography>
      )}
      {(!!selectedSymptoms.length || !!potentialSymptoms?.length) && (
        <Box flexDirection={'row'} flexWrap={'wrap'} display={'flex'}>
          {selectedSymptoms
            .filter((Id) => !!symptoms[symptomsObj[Id]])
            .sort((a, b) => {
              // sort by mandatory first
              if (selectedSymptomsObj[a] && !selectedSymptomsObj[b]) return -1;
              if (!selectedSymptomsObj[a] && selectedSymptomsObj[b]) return 1;
              return 0;
            })
            .map((Id) => (
              <Box
                style={{ width: showMandatorySwitch ? '100%' : 'auto' }}
                key={Id}
              >
                {renderItem(Id)}
              </Box>
            ))}
          {potentialSymptoms &&
            potentialSymptoms
              .filter((symptom) => !selectedSymptoms.includes(symptom.id))
              .map((symptom) => (
                <Box key={symptom.id}>{renderItem(symptom.id)}</Box>
              ))}
        </Box>
      )}
      {showAddSymptoms && (
        <div style={{ textAlign: 'left', marginTop: '1em' }}>
          <Button
            variant="contained"
            onClick={() => setSymptomDialogOpen(true)}
          >
            <Search />{' '}
            {selectedSymptoms.length || potentialSymptoms?.length
              ? 'Search for more symptoms'
              : 'Search symptoms'}
          </Button>
          &nbsp;
          {onResetSymptoms && (
            <Button onClick={onResetSymptoms}>
              <Clear /> Clear all
            </Button>
          )}
        </div>
      )}
      <Dialog
        open={symptomDialogOpen}
        onClose={() => setSymptomDialogOpen(false)}
      >
        <DialogTitle>Choose symptoms</DialogTitle>
        <DialogContent>
          <SymptomByName />
          <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider>
          <SymptomByType />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSymptomDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
