import { useCallback, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useMatchUrl = (urlList: { [x: string]: boolean }) => {
  const [selected, setSelected] = useState(false);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    setKeys(() => {
      return Object.keys(urlList);
    });
  }, [urlList]);

  const { pathname } = useLocation();

  const checkMatch = useCallback(() => {
    let foundMatch = false;
    for (const url of keys) {
      const matchUrl = matchPath({ path: url }, pathname);

      if (matchUrl) {
        foundMatch = true;
        break;
      }
    }
    return foundMatch;
  }, [keys, pathname]);

  useEffect(() => {
    setSelected(checkMatch());
  }, [checkMatch]);

  return selected;
};
