import React, { createContext, useState } from 'react';
import { ExtractedSymptom } from '../components/llm-diagnose/LLMDiagnoseList';
import { ExtractedAnimal } from '../api/LLM';

type LLMDiagnoseContextType = {
  freeText: string;
  setFreeText: (freeText: string) => void;
  setDirectDiagnose: (directDiagnose: boolean) => void;
  directDiagnose: boolean;
  isActiveLLMDiagnoseFlow: boolean;
  setIsActiveLLMDiagnoseFlow: (isActiveLLMDiagnoseFlow: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  extractedAnimal: ExtractedAnimal | null;
  setExtractedAnimal: (extractedAnimal: ExtractedAnimal | null) => void;
  extractedSymptoms: { [key: string]: ExtractedSymptom[] };
  setExtractedSymptoms: (extractedSymptoms: {
    [key: string]: ExtractedSymptom[];
  }) => void;
  error: string | null;
  setError: (error: string | null) => void;
};

const LLMDiagnoseContext = createContext<LLMDiagnoseContextType>({
  freeText: '',
  setFreeText: () => {},
  setDirectDiagnose: () => {},
  directDiagnose: false,
  isActiveLLMDiagnoseFlow: false,
  setIsActiveLLMDiagnoseFlow: () => {},
  extractedSymptoms: {},
  setExtractedSymptoms: () => {},
  extractedAnimal: null,
  setExtractedAnimal: () => {},
  loading: false,
  setLoading: () => {},
  error: '',
  setError: () => {},
});

export const LLMDiagnoseProvider = ({ children }: React.PropsWithChildren) => {
  const [freeText, setFreeText] = useState('');
  const [directDiagnose, setDirectDiagnose] = useState(false);
  const [isActiveLLMDiagnoseFlow, setIsActiveLLMDiagnoseFlow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [extractedAnimal, setExtractedAnimal] =
    useState<ExtractedAnimal | null>(null);
  const [extractedSymptoms, setExtractedSymptoms] = useState<{
    [key: string]: ExtractedSymptom[];
  }>({});
  const value = {
    freeText,
    setFreeText,
    setDirectDiagnose,
    directDiagnose,
    isActiveLLMDiagnoseFlow,
    setIsActiveLLMDiagnoseFlow,
    extractedSymptoms,
    setExtractedSymptoms,
    extractedAnimal,
    setExtractedAnimal,
    loading,
    setLoading,
    error,
    setError,
  };
  return (
    <LLMDiagnoseContext.Provider value={value}>
      {children}
    </LLMDiagnoseContext.Provider>
  );
};

export const useLLMDiagnose = () => {
  return React.useContext(LLMDiagnoseContext);
};
