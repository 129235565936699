import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { TemplatePage } from '../pages/TemplatePage';
import { DiseasesAndTreatmentsPage } from '../pages/DiseasesAndTreatmentsPage';
import { DrugSubstancesPage } from '../pages/DrugSubstancesPage';
import { CalculatorPage } from '../pages/CalculatorPage';

export const ToolsRouter = () => {
  return (
    <Routes>
      <Route
        path="diseases/breeds"
        element={<TemplatePage title="Diseases by breed" />}
      />
      <Route
        path="diseases/signalment"
        element={<TemplatePage title="Diseases by signalment" />}
      />
      <Route
        path="diseases/organs"
        element={<TemplatePage title="Diseases by organ" />}
      />
      <Route path="treatments" element={<DiseasesAndTreatmentsPage />} />
      <Route path="drugs" element={<DrugSubstancesPage />} />
      <Route path="calculator" element={<CalculatorPage />} />
    </Routes>
  );
};
