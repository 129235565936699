import React, { forwardRef, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  matchPath,
  useLocation,
} from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NavItemLinkProps } from './navigation.types';
import { useRWDContext } from '../../contexts/RWDContext';

const useStyles = makeStyles()((theme: Theme) => {
  const singleItem = {
    paddingLeft: '10%',
    marginBottom: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
    '&$selected': {
      backgroundColor: 'rgba(207, 207, 207, 0.4)',
    },
  };

  const singleItemRoot = { ...singleItem, marginTop: '20%' };

  return {
    root: {
      paddingLeft: '29%',
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:last-child': {
        marginBottom: '24px',
      },
      '&$selected': {
        backgroundColor: 'rgba(207, 207, 207, 0.4)',
      },
    },
    selected: {},
    text: {
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: '0.1px',
      color: '#444444',
      paddingTop: '2px',
      '&$selected': {
        color: 'black',
      },
    },
    icon: {
      marginTop: '7px',
      minWidth: '35px',
      paddingRight: '10%',
    },
    singleItem,
    singleItemRoot,
  };
});

export const NavItemLink = (
  props: NavItemLinkProps & {
    onLogOut?: (e: React.MouseEvent) => void;
    toggleNavOpen?: () => void;
  }
) => {
  const { icon, title, to, onLogOut = () => {}, toggleNavOpen } = props;
  const { pathname } = useLocation();
  const navPath = matchPath({ path: to }, pathname);

  const selected = Boolean(navPath) && title !== 'Logout';

  const { classes } = useStyles();
  const { track: trackRWD } = useRWDContext();

  const rwd = () => {
    if (title === 'Diagnostics') {
      trackRWD({ actionName: 'diagnostics_start_page' });
    }
  };

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, 'to'>>(function renderedLink(
        itemProps,
        ref
      ) {
        return (
          <RouterLink
            to={to}
            ref={ref}
            {...itemProps}
            {...(toggleNavOpen && { onClick: toggleNavOpen })}
          />
        );
      }),
    [to, toggleNavOpen]
  );

  return (
    <ListItem
      button
      {...(title !== 'Logout' && { component: renderLink })}
      {...(title === 'Diagnostics' && { onClick: rwd })}
      {...(title === 'Logout' && { onClick: onLogOut })}
      alignItems="flex-start"
      selected={selected}
      {...(title === 'Logout' ||
      title === 'Home' ||
      title === 'Drug substances' ||
      title === 'My Profile' ||
      title === 'Support' ||
      title === 'Diseases' ||
      title === 'Calculator' ||
      title === 'Patients' ||
      title === 'Visits' ||
      title === 'Diagnostics' ||
      title === 'Tutorial'
        ? {
            classes: {
              root:
                title === 'Home' || title === 'Logout'
                  ? classes.singleItemRoot
                  : classes.singleItem,
              selected: classes.selected,
            },
          }
        : { classes: { root: classes.root, selected: classes.selected } })}
    >
      {icon ? (
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      ) : null}
      <ListItemText
        disableTypography
        primary={
          <Typography
            className={classes.text}
            style={selected ? { fontWeight: 'bold' } : {}}
          >
            {title}
          </Typography>
        }
      />
    </ListItem>
  );
};
