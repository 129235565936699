import React from 'react';
import SubsequentDisease from './SubsequentDisease';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';

export const SubsequentDiseasesList = ({
  canSelect,
}: {
  canSelect: boolean;
}) => {
  const { subsequentTreatmentInfos, selectedSubsequentDiseases } =
    useTreatmentsPage();

  if (!subsequentTreatmentInfos) {
    if (canSelect) {
      return null;
    } else {
      return <>No associated conditions</>;
    }
  }

  const subsequentElements: JSX.Element[] = [];
  subsequentTreatmentInfos.forEach((treatmentInfo) => {
    if (
      !canSelect ||
      (canSelect && selectedSubsequentDiseases.indexOf(treatmentInfo.Id) > -1)
    ) {
      subsequentElements.push(
        <SubsequentDisease
          key={treatmentInfo.Id}
          treatmentInfo={treatmentInfo}
          canSelect={
            selectedSubsequentDiseases.indexOf(treatmentInfo.Id) > -1 ||
            canSelect
          }
        />
      );
    }
  });

  return <>{subsequentElements}</>;
};
