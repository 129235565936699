import { isFuture, isValid } from 'date-fns';
import { getTwoDecimalFormatter } from './numberUtils';
import moment from 'moment';
import { CurrentPatientInfoState } from '../reducers/currentPatientInfo/current.patient.info.state';
import { Patient } from '../types/Patient.type';

export const getDefaultErrorState = () => ({
  AnimalTypeId: false,
  GenderId: false,
  NameOrId: false,
  DateOfBirth: false,
  WeightLbs: false,
  Weight: false,
});

export type PatientPayload = {
  AnimalTypeId: number;
  GenderId: string;
  NameOrId: string | null;
  DateOfBirth: moment.Moment | null;
  WeightLbs: string;
  Weight: string;
  Identifier: string | null;
  Neutered: number;
  PetOwnerId: number | null;
  BreedId: string;
  IntegrationId?: string | null;
};

export type ApiPatientPayload = {
  AnimalTypeId: number;
  GenderId: number;
  NameOrId: string | null;
  DateOfBirth?: Date;
  Weight?: string;
  Identifier: string | null;
  Neutered: number;
  PetOwnerId: number | null;
  BreedId?: number | null;
  integrationData?: {
    IntegrationId?: string;
    IntegrationBreed?: string;
    IntegrationType?: string;
  };
};

export const getPayloadValidationResult = (payload: PatientPayload) => {
  const validationErrors = { ...getDefaultErrorState() };
  if (payload.AnimalTypeId === -1) {
    validationErrors.AnimalTypeId = true;
  }
  if (Number(payload.GenderId) === -1) {
    validationErrors.GenderId = true;
  }
  if (payload.NameOrId === '') {
    validationErrors.NameOrId = true;
  }
  if (
    !isValid(payload.DateOfBirth?.toDate()) ||
    isFuture(payload.DateOfBirth!.toDate())
  ) {
    validationErrors.DateOfBirth = true;
  }
  if (
    !payload.WeightLbs ||
    (payload.WeightLbs !== '' && isNaN(Number(payload.WeightLbs)))
  ) {
    validationErrors.WeightLbs = true;
  }
  if (
    !payload.Weight ||
    (payload.Weight !== '' && isNaN(Number(payload.Weight)))
  ) {
    validationErrors.Weight = true;
  }
  return validationErrors;
};

export const patientInfoToPatient = (
  patientInfo: CurrentPatientInfoState
): Patient => {
  const {
    Id,
    GenderId,
    AnimalTypeId,
    breedId,
    neutered,
    dateOfBirth,
    weight,
    name,
  } = patientInfo;
  return {
    Id: Id,
    AnimalTypeId,
    GenderId,
    Neutered: neutered,
    Weight: weight,
    NameOrId: name,
    BreedId: breedId,
    PetOwnerId: null,
    Identifier: '',
    DateOfBirth: dateOfBirth,
  };
};

export const formatPayload = (payload: PatientPayload) => {
  const cleanPayload: ApiPatientPayload = {
    GenderId: Number(payload.GenderId),
    AnimalTypeId: payload.AnimalTypeId,
    Neutered: payload.Neutered ? 1 : 0,
    DateOfBirth: payload.DateOfBirth?.toDate(),
    NameOrId: payload.NameOrId,
    Identifier: payload.Identifier,
    PetOwnerId: payload.PetOwnerId,
  };
  if (payload.Weight !== '') {
    const formatter = getTwoDecimalFormatter();
    const weight = formatter.format(Number(payload.Weight));
    cleanPayload.Weight = weight;
  } else {
    cleanPayload.Weight = '0.00';
  }
  if (Number(payload.BreedId) === -1) {
    cleanPayload.BreedId = null;
  } else {
    cleanPayload.BreedId = Number(payload.BreedId);
  }
  return cleanPayload;
};

const ONE_KG_IN_LBS = 2.20462;

export const convertKgToLbs = (kg: string) => {
  // +kg will produce 0 from '', which will produce NaN
  if (kg === '') {
    return '';
  }
  return Number.isNaN(+kg)
    ? '0.00'
    : (Number.parseFloat(kg) * ONE_KG_IN_LBS).toFixed(2);
};

export const convertLbsToKg = (lbs: string) => {
  // +lbs will produce 0 from '', which will produce NaN
  if (lbs === '') {
    return '';
  }
  return Number.isNaN(+lbs)
    ? '0.00'
    : (Number.parseFloat(lbs) / ONE_KG_IN_LBS).toFixed(2);
};

export const fixInputNumber = (number: number | string) => {
  let numberStr = `${number}`;
  const splittedNumberStr = numberStr.split('.');
  if (splittedNumberStr[1] && splittedNumberStr[1].length > 2) {
    numberStr = numberStr.slice(0, splittedNumberStr[0].length + 3);
  }
  return numberStr;
};
