import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { useRequestData } from '../../hooks/useRequestData';
import { TreatmentInfo } from '../../types/Diagnose.type';
import { Card } from '../common/Card';
import Loader from '../Loader';
import DiagnoseAccordionList from './DiagnoseAccordionList';
import { SubsequentDiseasesList } from './SubsequentDiseasesList';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';

export const CardSelectedDiagnoses = () => {
  const { selectedDiagnoses } = useDiagnosesPage();
  const [showSubsequentDiseases, setShowSubsequentDiseases] = useState(false);
  const {
    isLoading,
    error,
    treatmentInfos,
    subsequentDiseaseIds,
    subsequentTreatmentInfos,
  } = useTreatmentsPage();
  const { AnimalTypeId } = usePatientInfo();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();

  useEffect(() => {
    dispatchTreatmentsPage({ type: 'resetSubSequentDiseases' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const needTreatments =
    !error && !isLoading && !treatmentInfos && selectedDiagnoses.length > 0;

  useRequestData<TreatmentInfo[]>({
    needTransport: needTreatments,
    dispatch: dispatchTreatmentsPage,
    method: 'get',
    url: '/treatments',
    params: { animalTypeDiseaseIds: selectedDiagnoses.join(',') },
  });

  const needSubsequentDiagnoseInfos =
    !error &&
    !isLoading &&
    Boolean(treatmentInfos) &&
    subsequentDiseaseIds.length > 0 &&
    (!subsequentTreatmentInfos || !subsequentTreatmentInfos.length);

  useRequestData<TreatmentInfo[]>({
    needTransport: needSubsequentDiagnoseInfos,
    dispatch: dispatchTreatmentsPage,
    method: 'get',
    url: '/treatments',
    params: {
      diseaseIds: subsequentDiseaseIds.join(','),
      animalType: AnimalTypeId,
    },
    dispatchOptions: 'subsequentDiagnoses',
  });

  if (error) {
    dispatchTreatmentsPage({ type: 'reset' });
    throw error;
  }

  if (isLoading) {
    return <Loader showLoader={true} />;
  }

  if (error) {
    dispatchTreatmentsPage({ type: 'reset' });
    throw error;
  }

  if (isLoading) {
    return <Loader showLoader={true} />;
  }

  return (
    <Card
      title="SELECTED DIAGNOSES"
      pageView="drawerView"
      withoutMarginBottom
      maxWidth
    >
      <Alert
        severity="success"
        variant="filled"
        icon={<Info />}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => setShowSubsequentDiseases(true)}
          >
            Show conditions
          </Button>
        }
      >
        We found {subsequentDiseaseIds.length} potential associated conditions.
      </Alert>
      <Grid container>
        <Grid item xs={12}>
          <DiagnoseAccordionList />
          <SubsequentDiseasesList canSelect={true} />
        </Grid>
      </Grid>
      <Dialog
        open={showSubsequentDiseases}
        fullWidth
        maxWidth="md"
        onClose={() => setShowSubsequentDiseases(false)}
      >
        <DialogTitle>Associated conditions</DialogTitle>
        <DialogContent>
          <SubsequentDiseasesList canSelect={false} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowSubsequentDiseases(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
