import { useState } from 'react';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { FloatingActionBar } from '../diagnoses/FloatingActionBar';

export const SummaryFloatingActionBar = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const { exportState } = useWorkHistory();

  const handleSave = async (force: boolean) => {
    if (!force) return;
    setShowLoader(true);
    await exportState();
    setShowLoader(false);
  };

  return (
    <FloatingActionBar showLoader={showLoader} handleSave={handleSave}>
      {children}
    </FloatingActionBar>
  );
};
