import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDiagnoseRouter } from '../hooks/useDiagnoseRouter';

import { DiagnosesPage } from '../pages/DiagnosesPage';
import { DosagesPage } from '../pages/DosagesPage';
import { SummaryPage } from '../pages/SummaryPage';
import { TreatmentsPage } from '../pages/TreatmentsPage';
import { BottomPatientNavigation } from '../components/BottomPatientNavigation';

export const DiagnoseRouter = () => {
  useEffect(() => {
    return () => {
      console.log('unmounting');
    };
  }, []);
  useDiagnoseRouter();
  return (
    <>
      <Routes>
        <Route path="treatments/dosages/summary" element={<SummaryPage />} />
        <Route path="treatments/dosages" element={<DosagesPage />} />
        <Route path="treatments" element={<TreatmentsPage />} />
        <Route path="/" element={<DiagnosesPage />} />
      </Routes>

      <BottomPatientNavigation />
    </>
  );
};
