import { useCallback, useState } from 'react';
import API from '../api/Api';
import { Breed } from '../types/Breed.type';

export const useBreeds = () => {
  const [breeds, setBreeds] = useState<Breed[]>([]);

  const getBreeds = useCallback(async () => {
    const breedsResponse: any = await API.getModel({
      modelName: 'Breeds',
    });
    setBreeds(breedsResponse.body);
  }, []);

  return {
    breeds: breeds as Breed[],
    getBreeds,
  };
};
