import { ExtractedSymptom } from '../components/llm-diagnose/LLMDiagnoseList';
import API from './ApiLayer';

export type ExtractedAnimal = {
  animal_type_id: string;
  breed: string;
  date_of_birth: string | null;
  weight: string | null;
  name: string;
  gender: 'male' | 'female';
  neutered: boolean;
};

type SymptomResponse = {
  animal: ExtractedAnimal;
  symptoms: {
    keyPhrase: string;
    symptoms: {
      _distance: number;
      id: number;
      symptomName: string;
      categoryName: string;
    }[];
  }[];
};

const CERTAINTY_FACTOR = 0.35;

export class LLMError extends Error {
  httpStatus: number;
  constructor(message: string, httpStatus: number) {
    super(message);
    this.name = 'LLMError';
    this.httpStatus = httpStatus;
  }
}

export async function extractSymptomsRequest({
  anamnesis,
}: {
  anamnesis: string;
}) {
  const resp = await API.postRequest<SymptomResponse>(
    '/pms-integration/symptoms',
    {
      anamnesis,
    }
  );

  const extractedSymptoms: ExtractedSymptom[] = [];
  if (resp.status >= 400) {
    throw new LLMError('Error extracting symptoms', resp.status);
  }
  if (!resp.body) throw new Error('No response body in LLM request');
  const { animal, symptoms } = resp.body;
  for (const keyphrase of symptoms) {
    for (const symptom of keyphrase.symptoms) {
      extractedSymptoms.push({
        id: symptom.id,
        reason: '',
        name: symptom.symptomName,
        category_name: symptom.categoryName,
        uncertain: symptom._distance > CERTAINTY_FACTOR,
      });
    }
  }
  const obj: { [key: string]: ExtractedSymptom[] } = {};
  for (const symptom of extractedSymptoms) {
    if (!obj[symptom.category_name]) obj[symptom.category_name] = [];
    // filter out duplicates
    if (obj[symptom.category_name].some((s) => s.id === symptom.id)) continue;
    obj[symptom.category_name].push(symptom);
  }
  return {
    symptoms: obj,
    animal,
  };
}
