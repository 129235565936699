import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useSelectedProduct } from '../../hooks/useSelectedProduct';
import {
  CalculatedDose,
  DoseInstruction,
} from '../../reducers/dosagesPage/dosages.page.state';
import {
  CalculationType,
  CalculationUnit,
  DrugMgPerUnit,
  DrugSubstanceDose_Phase,
  DrugUnit,
} from '../../types/DrugDosages.type';
import {
  CalculatedValues,
  DefaultWithDrugMgPerUnit,
} from '../../types/DrugDosageTable.type';
import {
  determineDrugMgPerUnit,
  determineUnit,
} from '../../utils/dosagesUtils';
import { CardDrugDosageCalculator } from '../CardDrugDosageCalculator';
import { CardDoseInstruction } from './CardDoseInstruction';
import { DrugProductsContainer } from './DrugProductsContainer';
import { RecommendedDosage } from './RecommendedDosage';

export const DosageContainer = ({
  animalTypeName,
  treatmentName,
  drugSubstanceId,
  dosePhase,
  calculatedDose,
  index,
  setCalculatedDose,
  diseaseName,
  reload,
  resetReload,
  mobile,
  showDoseInstruction = true,
  handleRWDtrigger,
  indication,
}: {
  animalTypeName: string;
  treatmentName: string;
  drugSubstanceId: number;
  dosePhase: DrugSubstanceDose_Phase;
  calculatedDose: CalculatedDose;
  index: number;
  setCalculatedDose: (value: CalculatedDose, index: number) => void;
  diseaseName?: string;
  reload: boolean;
  resetReload: () => void;
  mobile: boolean;
  showDoseInstruction?: boolean;
  handleRWDtrigger?: (value: any) => void;
  indication?: string;
}) => {
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    calculatedDose.drugProductId
  );

  console.log('weight2', calculatedDose.weight);

  useEffect(() => {
    if (reload) {
      setSelectedProductId(null);
      resetReload();
    }
  }, [reload, resetReload]);

  useEffect(() => {
    if (!calculatedDose.drugAdministrationRoute) {
      calculatedDose.drugAdministrationRoute =
        dosePhase.DrugAdministrationRoute.Name;
    }
  }, [calculatedDose, dosePhase.DrugAdministrationRoute.Name]);

  const [
    mgStrength,
    productStrength,
    mgStrengthUnitStr,
    mgStrengthUnit,
    summaryDrugUnitId,
    summaryResultDrugUnitId,
  ] = useSelectedProduct(drugSubstanceId, selectedProductId);

  const forwardCalculation = (
    value: CalculatedValues<CalculationUnit>,
    calculationType: CalculationType
  ) => {
    switch (calculationType) {
      case 'numberOfUnits': {
        const calculatedMgStrength = +value.strength;
        let newDoseInstruction: DoseInstruction = {
          ...calculatedDose.doseInstruction,
          strength: value.strength,
          numberOfUnits: value.result,
          summaryDrugUnitId: value.unit,
          summaryResultDrugUnitId: value.unit,
        };

        if (
          calculatedDose.drugProductId &&
          calculatedMgStrength === mgStrength &&
          mgStrengthUnit === value.unit
        ) {
          newDoseInstruction = {
            ...calculatedDose.doseInstruction,
            strength: productStrength as string,
            numberOfUnits: value.result,
            summaryDrugUnitId: summaryDrugUnitId as number,
            summaryResultDrugUnitId: summaryResultDrugUnitId as number,
          };
        }

        const newNumberOfUnits = {
          strength: value.strength,
          dose: value.dose,
          result: value.result,
          unit: determineDrugMgPerUnit(value.unit)
            ? value.unit
            : DrugMgPerUnit['mg/ml'],
        };

        setCalculatedDose(
          {
            ...calculatedDose,
            numberOfUnits: newNumberOfUnits,
            doseInstruction: newDoseInstruction,
          },
          index
        );
        break;
      }

      case 'drugAmount': {
        const newDrugAmount = {
          strength: value.strength,
          dose: value.dose,
          result: value.result,
          unit: determineDrugMgPerUnit(value.unit)
            ? value.unit
            : DrugMgPerUnit['mg/ml'],
        };
        setCalculatedDose(
          {
            ...calculatedDose,
            drugAmount: newDrugAmount,
          },
          index
        );
        break;
      }

      case 'drugStrength': {
        const newDrugStrength = {
          strength: value.strength,
          dose: value.dose,
          result: value.result,
          unit: determineUnit(value.unit) ? value.unit : DrugUnit['ml'],
        };
        setCalculatedDose(
          {
            ...calculatedDose,
            drugStrength: newDrugStrength,
          },
          index
        );
        break;
      }

      default:
        break;
    }
  };

  const forwardDoseInstruction = (newDoseInstruction: DoseInstruction) => {
    setCalculatedDose(
      {
        ...calculatedDose,
        doseInstruction: newDoseInstruction,
      },
      index
    );
  };

  useEffect(() => {
    if (calculatedDose.drugProductId !== selectedProductId) {
      const newDoseInstruction: DoseInstruction = {
        ...calculatedDose.doseInstruction,
        strength: productStrength as string,
        summaryDrugUnitId: summaryDrugUnitId as number,
        numberOfUnits: '',
        summaryResultDrugUnitId: summaryResultDrugUnitId as number,
      };

      const newNumberOfUnits: CalculatedValues<DrugMgPerUnit> = {
        ...DefaultWithDrugMgPerUnit,
        strength: (mgStrength as number).toFixed(2),
        dose: '',
        result: '',
        unit: DrugMgPerUnit[mgStrengthUnitStr as keyof typeof DrugMgPerUnit],
      };

      setCalculatedDose(
        {
          ...calculatedDose,
          numberOfUnits: newNumberOfUnits,
          doseInstruction: newDoseInstruction,
          drugProductId: selectedProductId,
        },
        index
      );
    }
  }, [
    calculatedDose,
    index,
    mgStrength,
    mgStrengthUnitStr,
    productStrength,
    selectedProductId,
    setCalculatedDose,
    summaryDrugUnitId,
    summaryResultDrugUnitId,
  ]);

  return (
    <Container style={{ marginBottom: '2em', paddingTop: '1em' }}>
      <Container style={mobile ? { paddingLeft: '0px' } : undefined}>
        {diseaseName && (
          <Typography
            style={{
              fontWeight: 'bold',
              lineHeight: '1.6',
              letterSpacing: '0.5px',
              color: '#6E6E6E',
              fontSize: '0.8em',
            }}
          >
            {diseaseName.toUpperCase()}
          </Typography>
        )}
        <Typography
          style={{
            fontWeight: 'bold',
            lineHeight: '1.6',
            letterSpacing: '0.5px',
            marginBottom: '1em',
            color: '#6E6E6E',
            fontSize: '0.8em',
          }}
        >
          {`${treatmentName?.toUpperCase()} DOSAGES FOR ${animalTypeName?.toUpperCase()}`}
        </Typography>
      </Container>
      <Container style={mobile ? { paddingLeft: '0px' } : undefined}>
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            letterSpacing: '0.14px',
            marginBottom: '1em',
          }}
        >
          Dose type: {dosePhase.DoseType.Name}
        </Typography>
        <Grid container spacing={3}>
          <Grid container spacing={1} item xs={12} sm={6}>
            <RecommendedDosage dosePhase={dosePhase} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid container spacing={1} item xs={12} sm={6}>
            <DrugProductsContainer
              drugSubstanceId={drugSubstanceId}
              drugProductId={calculatedDose.drugProductId}
              setDrugProductId={setSelectedProductId}
            />
          </Grid>
        </Grid>
      </Container>
      <CardDrugDosageCalculator
        headerHidden={true}
        reload={reload}
        currentPatientWeight={calculatedDose.weight}
        currentNumberOfUnits={calculatedDose.numberOfUnits}
        currentDrugAmount={calculatedDose.drugAmount}
        currentDrugStrength={calculatedDose.drugStrength}
        forwardCalculation={forwardCalculation}
        handleRWDtrigger={handleRWDtrigger}
        treatmentName={treatmentName}
        doseType={dosePhase.DoseType.Name}
        indication={indication}
      />
      {showDoseInstruction && (
        <CardDoseInstruction
          treatmentName={treatmentName}
          drugAdministrationRouteName={dosePhase.DrugAdministrationRoute.Name}
          doseInstruction={calculatedDose.doseInstruction}
          setDoseInstruction={forwardDoseInstruction}
        />
      )}
    </Container>
  );
};
