import { Portal, Button, Tooltip } from '@mui/material';
import React from 'react';
import { ROUTE_HOME } from '../../config/routes.config';
import Loader from '../Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmationContext } from '../../contexts/ConfirmationContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const FloatingActionBar = ({
  continueDisabled = false,
  saveDisabled = false,
  continueDisabledMessage = '',
  showLoader,
  handleSave,
  onContinue,
  children,
}: {
  continueDisabled?: boolean;
  continueDisabledMessage?: string;
  showLoader: boolean;
  handleSave: (force: boolean) => Promise<void>;
  onContinue?: () => Promise<void>;
  children?: React.ReactNode;
  saveDisabled?: boolean;
}) => {
  const { confirmDialog } = useConfirmationContext();

  const navigate = useNavigate();
  const { dirty } = useWorkHistory();

  const { visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();

  const handleSaveAndClose = async () => {
    if (visitId === 'test') {
      return confirmDialog(
        'Do you want to save the visit for later use?',
        { yes: 'Yes', no: 'No' },
        async (result) => {
          if (result) await handleSave(result);
          navigate(ROUTE_HOME);
        }
      );
    } else if (dirty) {
      return confirmDialog(
        'Do you want to save the changes?',
        { yes: 'Yes', no: 'No' },
        async (result) => {
          if (result) await handleSave(result);
          navigate(ROUTE_HOME);
        }
      );
    }
    navigate(ROUTE_HOME);
  };

  const pmsIntegration = (window as any).pmsIntegration;

  return (
    <>
      <Portal
        container={() => {
          return pmsIntegration
            ? document.querySelector('#pms-integration-footer-actions')
            : document.querySelector('#patient-navigation-actions');
        }}
      >
        <Loader showLoader={showLoader} />
        <Button
          onClick={
            pmsIntegration
              ? pmsIntegration.waitAndClose(handleSave)
              : handleSaveAndClose
          }
          variant="outlined"
        >
          Close
        </Button>{' '}
        <Button
          sx={{ marginLeft: 1 }}
          variant="outlined"
          disabled={saveDisabled || !dirty}
          onClick={() => {
            handleSave(true);
          }}
        >
          Save
        </Button>{' '}
        {onContinue && (
          <Tooltip
            title={
              continueDisabled ? (
                <h2 style={{ padding: '0', margin: '0' }}>
                  {continueDisabledMessage}
                </h2>
              ) : (
                ''
              )
            }
          >
            <span>
              <Button
                style={{ marginLeft: '1em' }}
                variant="contained"
                color="primary"
                onClick={onContinue}
                disabled={continueDisabled}
              >
                Continue
              </Button>
            </span>
          </Tooltip>
        )}
        <br />
        {children}
      </Portal>
    </>
  );
};
