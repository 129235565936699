import React, { useEffect, useReducer, useState } from 'react';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../contexts/VeterinarianContext';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useRequestData } from '../hooks/useRequestData';
import { VetProfile } from '../types/Profile.types';
import { updateProfileReducer } from '../reducers/profile/profile.reducer';
import { DefaultUpdateProfileState } from '../reducers/profile/profile.state';
import Markdown from 'markdown-to-jsx';

const NEWS = [
  {
    timestamp: '2022-07-08T08:00:00+00:00',
    title: 'GekkoCompass is now GekkoVet',
    content:
      'Dear Veterinarian/Nurse, the name of this product has been changed. GekkoCompass is now GekkoVet. All the functions have remained the same, no changes are made that would impact the users. We wish you happy diagnosing moments with GekkoVet!',
  },
  {
    timestamp: '2024-12-01T08:00:00+00:00',
    title: 'GekkoVet is now even more intuitive, faster and easier!',
    content: ` * Now you can write free text in your own words and language - GekkoVet will search the database and suggest symptoms and findings for you to choose
 * Our AI uses only our structured data set as a source of knowledge - It doesn't hallucinate or invent suggestions
 * Unlike general chatbots, GekkoVet shows you the validated sources for every piece of information - You can choose which ones you trust the most
 * If you like, you can still use the traditional drop-down menus for symptom search

Enjoy the new GekkoVet designed to make your work more enjoyable!`,
  },
];

const WhatsNewModal = () => {
  const { veterinarian, isLoading } = useVeterinarian();

  const [dismissed, setDismissed] = useState<string | null>(null);
  const [reloadVeterinarian, setReloadVeterinarian] = useState(false);

  const [{ isUpdatingProfile }, dispatchUpdateProfile] = useReducer(
    updateProfileReducer,
    DefaultUpdateProfileState
  );

  useEffect(() => {
    if (isUpdatingProfile) return;
    setDismissed(null);
  }, [isUpdatingProfile]);

  useEffect(() => {
    if (isUpdatingProfile) return;
    setReloadVeterinarian(true);
  }, [isUpdatingProfile]);

  useEffect(() => {
    if (!isLoading) return;
    setReloadVeterinarian(false);
  }, [isLoading]);

  const dispatchVeterinarian = useVeterinarianDispatch();

  console.log('dismissed', dismissed, veterinarian?.WhatsNewSeen);

  useRequestData<VetProfile[]>({
    needTransport: reloadVeterinarian,
    dispatch: dispatchVeterinarian,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  useRequestData<VetProfile[]>({
    needTransport:
      !!dismissed &&
      !isUpdatingProfile &&
      dismissed !== veterinarian?.WhatsNewSeen,
    dispatch: dispatchUpdateProfile,
    method: 'post',
    url: '/profile',
    data: { profile: { ...veterinarian, WhatsNewSeen: dismissed } },
    dispatchOptions: {
      dispatch: dispatchVeterinarian,
      veterinarian: veterinarian,
    },
  });

  if (!veterinarian) return null;

  const lastRead = veterinarian.WhatsNewSeen
    ? moment(veterinarian.WhatsNewSeen)
    : moment().add(-6, 'months');

  const news = NEWS.filter((n) => moment(n.timestamp).diff(lastRead) > 0);
  if (!news.length) return null;
  return (
    <Dialog open={true} onClose={() => setDismissed(moment().format())}>
      <DialogTitle>{"What's new?"}</DialogTitle>
      <DialogContent>
        {news.map((n) => (
          <>
            <Typography variant="caption">
              {moment(n.timestamp).format('YYYY-MM-DD')}
            </Typography>
            <Typography variant="h5">{n.title}</Typography>
            <DialogContentText key={n.timestamp}>
              <Markdown>{n.content}</Markdown>
            </DialogContentText>
          </>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDismissed(moment().format())}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatsNewModal;
