import React from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
  button: {
    fontSize: 14,
    padding: '10px 15px',
    boxShadow: 'none',
  },
}));

type Props =
  | ButtonProps
  | (ButtonProps & LinkProps & { component: typeof Link });

export const Button = (props: Props) => {
  const { classes } = useStyles();
  const { className, title, ...rest } = props;
  return (
    <MUIButton
      title={title}
      className={props.className + ' ' + classes.button}
      {...rest}
    >
      {props.children || title}
    </MUIButton>
  );
};
