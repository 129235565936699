import { makeStyles } from 'tss-react/mui';
import { PropsWithChildren } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      justifyContent: 'center',
      minHeight: 'calc(100% - 70px)',
    },
    contentTop: {
      justifyContent: 'flex-start',
    },
  };
});

export const MainContent = ({
  children,
  id,
  contentTop = false,
}: PropsWithChildren<{ id?: string; contentTop?: boolean }>) => {
  const { classes } = useStyles();
  return (
    <main
      id={id}
      className={clsx(classes.root, { [classes.contentTop]: contentTop })}
      role="main"
    >
      {children}
    </main>
  );
};
