import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { Card } from '../common/Card';
import LLMSymptoms from './LLMSymptoms';
import { AddedSymptoms } from './AddedSymptoms';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { useLLMDiagnose } from '../../contexts/LLMDiagnoseContext';

export const CardSymptoms = ({ id }: { id?: string }) => {
  const dispatch = useDiagnosesPageDispatch();

  const { selectedSymptoms } = useDiagnosesPage();

  const { extractedSymptoms } = useLLMDiagnose();

  const flatExtractedSymptoms = useMemo(
    () => Object.values(extractedSymptoms).flat(),
    [extractedSymptoms]
  );

  const handleResetSymptoms = () => {
    dispatch({ type: 'resetSelectedSymptoms' });
  };

  return (
    <Card
      id={id}
      title="ADD SYMPTOMS"
      pageView="drawerView"
      maxWidth
      withoutMarginBottom
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <div>
            <LLMSymptoms />
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <AddedSymptoms
            potentialSymptoms={flatExtractedSymptoms}
            showAddSymptoms
            onResetSymptoms={
              (selectedSymptoms.length && handleResetSymptoms) || undefined
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};
