import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthUser } from '../contexts/AuthUserContext';
import { useIntegrationContext } from '../contexts/IntegrationContext';
import { ROUTE_NEW_PATIENT } from '../config/routes.config';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../contexts/VeterinarianContext';
import { VetProfile } from '../types/Profile.types';
import { useRequestData } from '../hooks/useRequestData';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '90%',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type AuthChallengeParams = {
  authChallenge?: string;
};

export const IntegrationLogin = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { authChallenge } = useParams<AuthChallengeParams>();
  const {
    user: { isAuthenticated, error, userEmail },
    signOut,
    loginCustomChallenge,
  } = useAuthUser();

  const {
    veterinarian,
    isLoading: isLoadingVet,
    error: vetError,
  } = useVeterinarian();
  const dispatch = useVeterinarianDispatch();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  const redirectFromIntegrationIntoPatientPage =
    !integrationConnectionInfo &&
    !integrationInfo &&
    !integrationSource &&
    userEmail === authChallenge?.split(',')[0];

  /* If we have a user already signed in in the browser and user does not match the one authenticating 
    from integration we signIn the integration user and refresh the auth state. When the userEmail 
    from auth state and the user email from integreation matches we forward user into the Patient page */
  useEffect(() => {
    const login = async () => {
      if (authChallenge) {
        if (authChallenge.split(',')[0] !== userEmail || !isAuthenticated) {
          loginCustomChallenge(authChallenge);
        }
      }
    };
    login();
  }, [
    loginCustomChallenge,
    signOut,
    authChallenge,
    isAuthenticated,
    userEmail,
  ]);

  const needVetTransport =
    !isLoadingVet && !vetError && veterinarian?.Email !== userEmail;

  useRequestData<VetProfile[]>({
    needTransport: needVetTransport,
    dispatch,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  if (isAuthenticated && !error && !redirectFromIntegrationIntoPatientPage) {
    // We need to set time out here to give auth context some time to load new user
    setTimeout(() => {
      navigate(ROUTE_NEW_PATIENT);
    }, 5000);
  }

  return (
    <Paper className={classes.root}>
      {isAuthenticated ? (
        <h3>
          Please wait while we finish preparing the patient case and forward you
          there.
        </h3>
      ) : (
        <h3>
          Welcome to GekkoVet {authChallenge ? authChallenge.split(',')[0] : ''}
          ! Please wait while we log you in.
        </h3>
      )}
    </Paper>
  );
};
