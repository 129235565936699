import React from 'react';
import { Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  usePatientListDispatch,
  usePatientListState,
} from '../../contexts/PatientListContext';
import { GenderValueString } from '../../types/Gender.type';
import { Button } from '../Button';
import { Card } from '../common/Card';
import { SpeciesSelect } from '../common/SpeciesSelect';

export const CardSearchPatient = () => {
  const { searchOptions } = usePatientListState();
  const dispatchOptions = usePatientListDispatch();

  return (
    <Card title="SEARCH PATIENT" pageView="drawerView" withoutMarginBottom>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <SpeciesSelect
            error={false}
            species={searchOptions.AnimalTypeId}
            handleChange={(
              event: React.ChangeEvent<{ name: string; value: unknown }>
            ) =>
              dispatchOptions({
                type: 'setSearchOptions',
                searchOptions: {
                  ...searchOptions,
                  AnimalTypeId: event.target.value as number,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            id="identifier"
            label="Identifier"
            value={searchOptions.Identifier}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatchOptions({
                type: 'setSearchOptions',
                searchOptions: {
                  ...searchOptions,
                  Identifier: event.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            id="name"
            label="Patient Name"
            value={searchOptions.NameOrId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatchOptions({
                type: 'setSearchOptions',
                searchOptions: {
                  ...searchOptions,
                  NameOrId: event.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={8} style={{ textAlign: 'left' }}>
          <FormControl variant="standard" component="fieldset" size="small">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="Gender"
              value={searchOptions.Gender}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatchOptions({
                  type: 'setSearchOptions',
                  searchOptions: {
                    ...searchOptions,
                    Gender: event.target.value as GenderValueString,
                  },
                })
              }
              row
            >
              <FormControlLabel value="any" control={<Radio />} label="Any" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
          <Button
            title="Search"
            color="secondary"
            variant="contained"
            onClick={() =>
              dispatchOptions({
                type: 'resetSearchOptions',
              })
            }
            style={{ marginRight: '2em', marginTop: '1em' }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
