import React, { useEffect } from 'react';
import { CardAllPatients } from '../components/all-patients/CardAllPatients';
import { CardSearchPatient } from '../components/all-patients/CardSearchPatient';
import { usePatientListDispatch } from '../contexts/PatientListContext';

export const AllPatientsPage = ({ isSelect }: { isSelect?: boolean }) => {
  const dispatch = usePatientListDispatch();
  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [dispatch]);
  return (
    <>
      <CardSearchPatient />
      <CardAllPatients isSelect={isSelect} />
    </>
  );
};
