import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PaymentErrorPage } from '../pages/PaymentErrorPage';
import { PaymentSuccessPage } from '../pages/PaymentSuccessPage';

import {
  ROUTE_PAYMENT,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_ERROR,
  ROUTE_PROFILE,
} from '../config/routes.config';
import { ProfileRouter } from '../routers/ProfileRouter';
import { PaymentPage } from '../pages/PaymentPage';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../contexts/SubscriptionContext';

export const NoSubscriptionLayout = () => {
  const { error: errorSubscription } = useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  useEffect(() => {
    if (errorSubscription) {
      dispatchSubscription({ type: 'reset' });
    }
  }, [errorSubscription, dispatchSubscription]);

  return (
    <Routes>
      <Route path={ROUTE_PAYMENT} element={<PaymentPage />} />
      <Route path={ROUTE_PAYMENT_SUCCESS} element={<PaymentSuccessPage />} />
      <Route path={ROUTE_PAYMENT_ERROR} element={<PaymentErrorPage />} />
      <Route path={`${ROUTE_PROFILE}/*`} element={<ProfileRouter />} />
      <Route path="/*" element={<Navigate to={ROUTE_PAYMENT} replace />} />
    </Routes>
  );
};
