import React from 'react';
import { Grid } from '@mui/material';
import CardDrugLibrary from './CardDrugLibrary';
import CardDiseaseLibrary from './CardDiseaseLibrary';
import LLMDiagnose from './LLMDiagnose';

export default function ThreeFlows() {
  return (
    <Grid container spacing={2} padding={3} alignItems={'stretch'}>
      <Grid item md={12} lg={8} sm={12} display={'flex'}>
        <LLMDiagnose style={{ height: '100%', width: '100%' }} />
      </Grid>
      <Grid item container md={12} lg={4} sm={12} spacing={2} display="flex">
        <Grid item xs={12} md={6} lg={12}>
          <CardDiseaseLibrary />
        </Grid>
        <Grid item xs={12} md={6} lg={12}>
          <CardDrugLibrary />
        </Grid>
      </Grid>
    </Grid>
  );
}
