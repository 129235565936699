import { Button, Portal, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SpeciesSelect } from './common/SpeciesSelect';
import { BreedsComboBox } from './add-new-patient/BreedsComboBox';
import { NeuteredCheckbox } from './add-new-patient/NeuteredCheckbox';
import { GenderRadios } from './add-new-patient/GenderRadios';
import {
  convertKgToLbs,
  convertLbsToKg,
  formatPayload,
  getDefaultErrorState,
  fixInputNumber,
  getPayloadValidationResult,
  PatientPayload,
  ApiPatientPayload,
} from '../utils/insertPatientUtils';
import Loader from './Loader';
import { hasValidationErrors } from '../utils/dateUtils';
import {
  IntegrationPatient,
  Patient as PatientType,
} from '../types/Patient.type';
import { Breed } from '../types/Breed.type';
import { ANIMAL_TYPE_ID_UNDEFINED } from '../types/AnimalType.type';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useRWDContext } from '../contexts/RWDContext';
import { getRWDage } from '../utils/rwdUtils';

declare global {
  interface Window {
    dataLayer: any;
  }
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const WEIGHT_KG = 'Weight';
const WEIGHT_LBS = 'WeightLbs';

const createInitState = {
  AnimalTypeId: ANIMAL_TYPE_ID_UNDEFINED,
  BreedId: '-1',
  GenderId: '2', // checkbox force casts this to string anyway
  Neutered: 0,
  [WEIGHT_KG]: '',
  [WEIGHT_LBS]: '',
  NameOrId: '',
  Identifier: '',
  DateOfBirth: moment(),
  PetOwnerId: null,
  IntegrationId: null,
};

export const Patient = ({
  patient,
  integrationPatientData,
  isEditPage,
  createPatient,
  updatePatient,
  breedsData,
  integrationPatient,
  patientSaving,
  fetchBreedsByAnimalTypeId,
  setPatientSaving,
  chromeless = false,
  actionContainer,
}: {
  patient: PatientType | null;
  integrationPatientData: IntegrationPatient | null;
  isEditPage: boolean;
  updatePatient: any;
  createPatient: (payload: ApiPatientPayload) => Promise<void>;
  breedsData: Breed[];
  integrationPatient: boolean;
  patientSaving: boolean;
  fetchBreedsByAnimalTypeId: any;
  setPatientSaving: any;
  chromeless?: boolean;
  actionContainer?: HTMLElement | null;
}) => {
  const actionRef = useRef<HTMLDivElement | null>(null);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [payloadState, setPayloadState] =
    useState<PatientPayload>(createInitState);
  const [errors, setErrors] = useState<any>({ errors: getDefaultErrorState() });
  const [breedBoxInputValue, setBreedBoxInputValue] = useState('');
  const { animalTypesObj } = useAnimalTypesContext();
  const { Id: patientId } = usePatientInfo();
  const dispatchPatientInfo = usePatientInfoDispatch();
  // Additional RWD variable
  const { track: trackRWD } = useRWDContext();

  const setEditInitialState = useCallback(() => {
    if (patient) {
      setPayloadState({
        ...patient,
        BreedId: patient.BreedId ? patient.BreedId.toString() : '',
        DateOfBirth: patient.DateOfBirth ? moment(patient.DateOfBirth) : null,
        GenderId: String(patient.GenderId),
        [WEIGHT_LBS]: convertKgToLbs(patient.Weight),
      });
    }
  }, [patient, setPayloadState]);

  const setInitialStateFromIntegrationData = useCallback(() => {
    if (integrationPatient && integrationPatientData) {
      fetchBreedsByAnimalTypeId(integrationPatientData.AnimalTypeId);
      setPayloadState({
        ...createInitState,
        NameOrId: integrationPatientData.NameOrId
          ? integrationPatientData.NameOrId
          : '',
        AnimalTypeId: integrationPatientData.AnimalTypeId
          ? integrationPatientData.AnimalTypeId
          : ANIMAL_TYPE_ID_UNDEFINED,
        Neutered: integrationPatientData.Neutered
          ? integrationPatientData.Neutered
          : 0,
        DateOfBirth: integrationPatientData.DateOfBirth
          ? moment(integrationPatientData.DateOfBirth)
          : null,
        GenderId: integrationPatientData.GenderId
          ? String(integrationPatientData.GenderId)
          : '',
        [WEIGHT_LBS]: integrationPatientData.Weight
          ? convertKgToLbs(integrationPatientData.Weight)
          : '',
        Weight: integrationPatientData.Weight
          ? integrationPatientData.Weight
          : '',
        IntegrationId: integrationPatientData.IntegrationId,
      });
    }
  }, [
    integrationPatient,
    integrationPatientData,
    setPayloadState,
    fetchBreedsByAnimalTypeId,
  ]);

  useEffect(setInitialStateFromIntegrationData, [
    setInitialStateFromIntegrationData,
  ]);

  useEffect(setEditInitialState, [setEditInitialState]);

  useEffect(() => {
    if (patientId < 0) return;
    if (patient && patientId !== patient.Id) {
      console.log('dispatching', patientId, patient.Id);
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
    }
  }, [animalTypesObj, dispatchPatientInfo, patient, patientId]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    switch (name) {
      case 'AnimalTypeId': {
        if (Number(payloadState.BreedId) !== -1) {
          setPayloadState({ ...payloadState, BreedId: '-1', [name]: value });
        } else {
          setPayloadState({ ...payloadState, [name]: value });
        }
        fetchBreedsByAnimalTypeId(value);
        break;
      }
      case WEIGHT_KG: {
        const numberStr = fixInputNumber(value);

        setPayloadState({
          ...payloadState,
          [WEIGHT_LBS]: convertKgToLbs(numberStr),
          [WEIGHT_KG]: numberStr,
        });
        break;
      }
      case WEIGHT_LBS: {
        const numberStr = fixInputNumber(value);

        setPayloadState({
          ...payloadState,
          [WEIGHT_KG]: convertLbsToKg(numberStr),
          [WEIGHT_LBS]: numberStr,
        });
        break;
      }
      default: {
        setPayloadState({ ...payloadState, [name]: value });
        break;
      }
    }
  };

  const handleBreedInputChange = (value: string) => {
    setPayloadState({ ...payloadState, BreedId: value });
  };

  const handleBreedBoxInputValueChange = (value: string) => {
    setBreedBoxInputValue(value);
  };

  const handleDateOfBirthInputChange = (value: moment.Moment | null) => {
    setPayloadState({ ...payloadState, DateOfBirth: value });
  };

  useEffect(() => {
    // Validation immediately after change if submit has already been pressed
    if (isSubmitPressed) {
      const validationResult = getPayloadValidationResult(payloadState);
      setErrors(validationResult);
    }
  }, [isSubmitPressed, payloadState]);

  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitPressed(true);
    const validationResult = getPayloadValidationResult(payloadState);
    if (hasValidationErrors(validationResult, getDefaultErrorState())) {
      setErrors(validationResult);
      return;
    }
    const formattedPayload = formatPayload(payloadState);
    setPatientSaving(true);
    if (isEditPage && patient) {
      updatePatient({ ...formattedPayload, Id: patient.Id });
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient: {
          ...patient,
          GenderId: formattedPayload.GenderId,
          Neutered: formattedPayload.Neutered,
          Weight: formattedPayload.Weight || '',
          DateOfBirth: formattedPayload.DateOfBirth
            ? formattedPayload.DateOfBirth.toString()
            : null,
          NameOrId: formattedPayload.NameOrId,
        },
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
    } else {
      window.dataLayer.push({
        event: 'newPatient',
      });

      // Start of RWD functionality
      const age = getRWDage(formattedPayload.DateOfBirth || null);
      let gender = 'female';
      if (formattedPayload.GenderId === 1) {
        gender = 'male';
      }
      const breed = breedsData.find((breed) => {
        return breed.Id === formattedPayload.BreedId;
      });
      // string[0].toUpperCase() + string.slice(1)
      trackRWD({
        actionName: 'created_patient',
        signalmentSpecies:
          animalTypesObj[formattedPayload.AnimalTypeId].Name[0].toUpperCase() +
          animalTypesObj[formattedPayload.AnimalTypeId].Name.slice(1),
        signalmentBreed: breed?.Name === '' ? undefined : breed?.Name,
        signalmentWeight: +(formattedPayload.Weight || '0'),
        signalmentGender: gender,
        signalmentAge: age,
        signalmentNeutered: formattedPayload.Neutered ? 'neutered' : 'intact',
        signalmentPatientType: 'clinical',
      });
      // End of RWD functionality
      if (integrationPatient) {
        formattedPayload.integrationData = {
          IntegrationBreed: integrationPatientData?.IntegrationBreed,
          IntegrationType: integrationPatientData?.IntegrationType,
          IntegrationId: integrationPatientData?.IntegrationId,
        };
      }
      createPatient(formattedPayload);
    }
  };

  if (isEditPage && (!patient || breedsData.length === 0)) {
    return <Loader showLoader={true} />;
  }

  if (
    integrationPatient &&
    (!integrationPatientData || breedsData.length === 0)
  ) {
    return <Loader showLoader={true} />;
  }

  return (
    <Grid item container>
      {!chromeless && <Grid item xs={1} sm={2} />}
      <Grid item xs={chromeless ? 12 : 10} sm={chromeless ? 12 : 8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SpeciesSelect
                error={errors.AnimalTypeId}
                species={payloadState.AnimalTypeId}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BreedsComboBox
                value={Number(payloadState.BreedId)}
                handleChange={handleBreedInputChange}
                inputValue={breedBoxInputValue}
                handleOnInputValueChange={handleBreedBoxInputValueChange}
                breedsInitData={breedsData}
                disabled={breedsData.length === 0}
                helperText={
                  integrationPatientData?.IntegrationBreed
                    ? 'Breed in ' +
                      integrationPatientData.IntegrationType +
                      ': ' +
                      integrationPatientData?.IntegrationBreed
                    : null
                }
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                value={payloadState.Weight}
                name={WEIGHT_KG}
                label="Weight (kg)"
                error={errors.Weight}
                variant="outlined"
                fullWidth
                required
                type="number"
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                value={payloadState.WeightLbs}
                name={WEIGHT_LBS}
                label="Weight (lbs)"
                error={errors.WeightLbs}
                variant="outlined"
                fullWidth
                type="number"
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: '100%' }}>
              <TextField
                name="NameOrId"
                label="Name"
                required
                variant="outlined"
                fullWidth
                error={errors.NameOrId}
                onChange={handleInputChange}
                value={payloadState.NameOrId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                value={payloadState.DateOfBirth}
                onChange={handleDateOfBirthInputChange}
                disableFuture
                slotProps={{ textField: { error: errors.DateOfBirth } }}
                label="Date of Birth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="Identifier"
                label="Identifier"
                fullWidth
                variant="outlined"
                onChange={handleInputChange}
                value={payloadState.Identifier}
              />
            </Grid>
            <Grid item xs={12}>
              <GenderRadios
                gender={Number(payloadState.GenderId)}
                handleChange={handleInputChange}
                error={errors.GenderId}
              />
            </Grid>
            <Grid item xs={12}>
              <NeuteredCheckbox
                neutered={payloadState.Neutered}
                handleChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} ref={actionRef}></Grid>
          </Grid>
          <Portal
            container={
              chromeless && actionContainer
                ? actionContainer
                : actionRef.current
            }
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={patientSaving}
              onClick={(e) => onFormSubmit(e)}
            >
              {isEditPage ? 'Save changes' : 'Save patient'}
            </Button>
          </Portal>
        </form>
      </Grid>
      {!chromeless && <Grid item xs={1} sm={2} />}
      <Loader showLoader={patientSaving} />
    </Grid>
  );
};
