import API, { Api } from '../api/Api';
import { GeographicalArea } from '../types/GeographicalArea.type';
import { ActionMap } from './reducerUtils';

export enum Types {
  CreatePatient = 'CREATE_PATIENT',
  DeletePatient = 'DELETE_PATIENT',
  EditPatient = 'EDIT_PATIENT',
  GetPatient = 'GET_PATIENT',
  GetSymptoms = 'GET_SYMPTOMS',
  GetSymptomTypes = 'GET_SYMPTOM_TYPES',
  GetSymptomTypesCategories = 'GET_SYMPTOM_TYPES_CATEGORIES',
  GetAnimalTypes = 'GET_ANIMAL_TYPES',
  UseApi = 'USE_API',
  GetProfile = 'GET_PROFILE',
  GetSpecialities = 'GET_SPECIALITIES',
  GetVetAnimalTypes = 'GET_VET_ANIMAL_TYPES',
  GetVetSpecialities = 'GET_VET_SPECIALITIES',
  ProfileUseProfileApi = 'PROFILE_USE_PROFILE_API',
  GetGeographicalAreas = 'GET_GEOGRAPHICAL_AREAS',
}

// Patients

type PatientType = {
  id: number;
  name: string;
};

export type PatientState = {
  patients: PatientType[];
};

type PatientPayload = {
  [Types.CreatePatient]: {
    id: number;
    name: string;
  };
  [Types.EditPatient]: {
    id: number;
  };
  [Types.DeletePatient]: {
    id: number;
  };
  [Types.GetPatient]: {
    modelName: string;
    id: number;
  };
};

export type PatientActions =
  ActionMap<PatientPayload>[keyof ActionMap<PatientPayload>];

export const patientReducer = (
  state: PatientState,
  action: PatientActions | SymptomsActions | ProfileActions
) => {
  switch (action.type) {
    case Types.CreatePatient:
      return state;
    case Types.DeletePatient:
      return state;
    case Types.GetPatient:
      API.getModel(
        { modelName: action.payload.modelName, id: action.payload.id },
        {}
      );
      return state;
    default:
      return state;
  }
};

// Symptoms

export type SymptomsState = {
  symptoms: any;
  symptomTypes: any;
  symptomTypeCategories: any;
  geographicalAreas: GeographicalArea[];
};

type SymptomsPayload = {
  [Types.GetSymptoms]: {
    data: Array<any>;
  };
  [Types.GetSymptomTypes]: {
    data: Array<any>;
  };
  [Types.GetSymptomTypesCategories]: {
    data: Array<any>;
  };
  [Types.GetGeographicalAreas]: {
    data: Array<any>;
  };
  [Types.UseApi]: {
    api: keyof Api;
    data: object;
    options: object;
    key: keyof typeof Types;
  };
};

export type SymptomsActions =
  ActionMap<SymptomsPayload>[keyof ActionMap<SymptomsPayload>];

export const symptomsReducer = (
  state: SymptomsState,
  action: PatientActions | SymptomsActions | ProfileActions,
  dispatch: any
) => {
  switch (action.type) {
    case Types.GetSymptoms:
      return { ...state, symptoms: action.payload.data, patientSaving: false };
    case Types.GetSymptomTypes:
      return { ...state, symptomTypes: action.payload.data };
    case Types.GetSymptomTypesCategories:
      return { ...state, symptomTypeCategories: action.payload.data };
    case Types.GetGeographicalAreas:
      return { ...state, geographicalAreas: action.payload.data };
    case Types.UseApi:
      API[action.payload.api](
        action.payload.data as any,
        action.payload.options
      ).then((result: any) => {
        action.dispatch({
          type: Types[action.payload.key],
          payload: { data: result.body },
        });
      });
      return { ...state };
    default:
      return state;
  }
};

// Veterinarians

export type ProfileState = {
  profile: any;
  subscription: any;
  specialities: any;
  vetSpecialities: any;
  vetAnimalTypes: any;
  loading: boolean;
};

type ProfilePayload = {
  [Types.GetProfile]: {
    data: Array<any>;
  };
  [Types.GetSpecialities]: {
    data: Array<any>;
  };
  [Types.GetVetAnimalTypes]: {
    data: Array<any>;
  };
  [Types.GetVetSpecialities]: {
    data: Array<any>;
  };
  [Types.ProfileUseProfileApi]: {
    api: keyof Api;
    data: object;
    options: object;
    key: keyof typeof Types;
  };
};

export type ProfileActions =
  ActionMap<ProfilePayload>[keyof ActionMap<ProfilePayload>];

export const profileReducer = (
  state: ProfileState,
  action: PatientActions | SymptomsActions | ProfileActions,
  dispatch: any
) => {
  switch (action.type) {
    case Types.GetProfile:
      return { ...state, profile: action.payload.data[0] };
    case Types.GetSpecialities:
      return { ...state, specialities: action.payload.data };
    case Types.GetVetSpecialities:
      return { ...state, vetSpecialities: action.payload.data };
    case Types.GetVetAnimalTypes:
      return { ...state, vetAnimalTypes: action.payload.data };
    case Types.ProfileUseProfileApi:
      API[action.payload.api](
        action.payload.data as any,
        action.payload.options
      ).then((result: any) => {
        action.dispatch({
          type: Types[action.payload.key],
          payload: { data: result ? result.body : null },
        });
      });
      return { ...state, loading: true };
    default:
      return state;
  }
};
