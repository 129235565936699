import React, { useMemo, useState } from 'react';
import {
  Typography,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { DiseaseInfo, TreatmentInfo } from '../../types/Diagnose.type';
import { Button } from '../Button';
import ConditionTooltip from '../diagnoses/ConditionTooltip';
import { SourcesDialog } from '../common/SourcesDialog';
import { TreatmentsContent } from './TreatmentsContent';
import { useRWDContext } from '../../contexts/RWDContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import {
  getRWDage,
  getRWDdiagnosesMethod1,
  getRWDsymptoms,
} from '../../utils/rwdUtils';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { useParams } from 'react-router-dom';

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export default function DiagnoseAccordion({
  diagnose,
  handleSelect,
  hideTreatment,
}: {
  diagnose: DiseaseInfo;
  handleSelect?: () => void;
  hideTreatment?: boolean;
  defaultExpanded?: boolean;
}) {
  const {
    treatmentsObj,
    treatmentInfos,
    selectedSubsequentDiseases,
    subsequentTreatmentInfos,
    selectedTreatments,
  } = useTreatmentsPage();
  const { patientId } = useParams<{ patientId: string }>();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const dispatchTreatments = useTreatmentsPageDispatch();

  const [sourceModalOpen, setSourceModalOpen] = useState(false);
  //const [expanded, setExpanded] = useState(defaultExpanded);
  const expanded = false;
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const toggleSourceModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
    open: boolean
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSourceModalOpen(open);
  };

  const treatmentInfo = useMemo(() => {
    if (diagnose.Id in treatmentsObj) {
      return treatmentInfos![treatmentsObj[diagnose.Id]];
    }
    if ((diagnose as any).Treatments) {
      return diagnose as TreatmentInfo;
    }
    return null;
  }, [diagnose, treatmentsObj, treatmentInfos]);

  // RWD variables
  const { track: trackRWD } = useRWDContext();
  const patientInfo = usePatientInfo();
  const { symptoms } = useSymptoms();
  const {
    diagnoses,
    selectedDiagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
  } = useDiagnosesPage();
  // End of RWD variables

  const handleDeselect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const deselectTreatmentIds: number[] = treatmentInfo
      ? treatmentInfo.Treatments.map((treatment) => treatment.Id)
      : [];

    dispatchPageDiagnoses({
      type: 'setDiagnoseSelected',
      checked: false,
      diagnoseId: diagnose.Id,
    });

    dispatchTreatments({
      type: 'setSubsequentDiseaseSelected',
      checked: false,
      diseaseId: diagnose.Id,
    });

    dispatchTreatments({
      type: 'deselectTreatments',
      deselectTreatmentIds,
    });

    // Start of RWD functionality
    const arrayOfDiagnoses = getRWDdiagnosesMethod1(
      diagnoses,
      selectedDiagnoses,
      selectedSubsequentDiseases,
      subsequentTreatmentInfos,
      treatmentInfo?.Disease.Name,
      true
    );
    const arrayOfSymptoms = getRWDsymptoms(
      selectedSymptoms,
      symptoms,
      selectedSymptomsObj
    );
    const age = getRWDage(patientInfo.dateOfBirth);
    trackRWD({
      actionName: 'selected_diagnoses',
      diseases: arrayOfDiagnoses,
      symptoms: arrayOfSymptoms,
      signalmentSpecies:
        patientInfo.animalTypeName[0].toUpperCase() +
        patientInfo.animalTypeName.slice(1),
      signalmentBreed:
        patientInfo.breedName === '' ? undefined : patientInfo.breedName,
      signalmentWeight: +patientInfo.weight,
      signalmentGender: patientInfo.genderName,
      signalmentAge: age,
      signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
      signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
    });
    // End of RWD functionality
  };

  // for historical reasons this is still an accordion
  // but it is not expandable
  // we should refactor this to something else in the future

  return (
    <Accordion
      square
      TransitionProps={{ unmountOnExit: true }}
      expanded={false}
      onChange={() => {}}
    >
      <AccordionSummary
        expandIcon={<span></span>}
        aria-label={`Expand treatment ${diagnose.Id}`}
        aria-controls={`treatment-diagnose-content-${diagnose.Id}`}
        id={`treatment-diagnose-header-${diagnose.Id}`}
        style={{ cursor: 'default' }}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={9} container>
            <Grid item xs={12}>
              <Typography align="left">
                <strong>Disease</strong>: {diagnose.Disease.Name}{' '}
                &nbsp;&nbsp;&nbsp;
                {diagnose.Condition === 1 && <ConditionTooltip />}
              </Typography>
            </Grid>

            <Grid item xs={12} container>
              {!treatmentInfo && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: 'gray',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                    }}
                    align="left"
                  >
                    No sources
                  </Typography>
                </Grid>
              )}
              {treatmentInfo && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: '#00aa79',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    align="left"
                  >
                    Sources
                  </Typography>
                  {sourceModalOpen && (
                    <SourcesDialog
                      isOpen={sourceModalOpen}
                      closeDialog={(event) => toggleSourceModal(event, false)}
                      sources={treatmentInfo.Source_AnimalTypeDiseases}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} textAlign={'left'}>
                <MuiButton
                  variant="outlined"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setContentModalOpen(true);
                  }}
                >
                  {expanded
                    ? 'Hide information'
                    : 'Show treatments and information'}
                </MuiButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={3} textAlign={'right'}>
            {handleSelect ? (
              <Button
                title="Select diagnose"
                variant="contained"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  handleSelect();
                }}
                style={{
                  color: 'white',
                  backgroundColor: '#01AA70',
                  padding: '0.2em 0.5em',
                }}
              >
                Select
              </Button>
            ) : (
              <Button
                title="Deselect diagnose"
                variant="contained"
                onClick={handleDeselect}
                style={{
                  color: '#01AA70',
                  backgroundColor: 'white',
                  border: '1px solid rgba(0, 0, 0, 0.2)',
                  padding: '0.2em 0.5em',
                }}
              >
                Deselect diagnose
              </Button>
            )}
            <br />
            {!hideTreatment && (
              <MuiButton
                variant="text"
                style={{ color: '#01AA70', fontSize: '80%' }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setContentModalOpen(true);
                }}
              >
                {(treatmentInfo &&
                  treatmentInfo.Treatments.filter(
                    (treatment) => selectedTreatments.indexOf(treatment.Id) > -1
                  ).length) ||
                  0}{' '}
                treatment(s) selected
              </MuiButton>
            )}
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="xl"
          open={contentModalOpen}
          onClose={() => setContentModalOpen(false)}
          PaperProps={{ style: { height: '90vh' } }}
        >
          <DialogTitle>Disease: {diagnose.Disease.Name}</DialogTitle>
          <DialogContent>
            <TreatmentsContent
              treatmentInfo={treatmentInfo}
              canSelect={!hideTreatment}
            />
          </DialogContent>
          <DialogActions>
            <MuiButton
              onClick={() => setContentModalOpen(false)}
              variant="text"
            >
              Close
            </MuiButton>
          </DialogActions>
        </Dialog>
      </AccordionSummary>
      {/*{defaultExpanded && (
        <TreatmentsContent
          treatmentInfo={treatmentInfo}
          canSelect={!hideTreatment}
        />
      )}*/}
    </Accordion>
  );
}
