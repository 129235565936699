import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 24 24"
      >
        <g id="icons">
          <g>
            <circle cx="7" cy="11.5" r="1.7" />
            <circle cx="16.9" cy="11.5" r="1.7" />
            <path d="M14.5,19h0c-2.4,0-3.2-2.2-3.2-3.4,0-.4.3-.8.7-.8.4,0,.8.3.8.7,0,0,0,.5.3,1,.3.6.8.9,1.4.9,1,0,1.5-.9,1.5-.9.2-.4.7-.5,1.1-.3s.5.7.3,1.1c0,0-1,1.7-2.8,1.7h0Z" />
            <path d="M9.4,19c-1.9,0-2.8-1.6-2.8-1.7-.2-.4,0-.9.3-1.1.4-.2.9,0,1.1.3,0,0,.5.9,1.5.9.6,0,1.1-.3,1.4-.9.3-.5.3-1,.3-1,0-.4.4-.8.8-.7.4,0,.8.4.7.8,0,1.1-.9,3.3-3.2,3.4h0Z" />
            <path d="M13.7,14.4c0,.6-.8,1.5-1.8,1.5s-1.8-.9-1.8-1.5.8-.8,1.8-.8,1.8.1,1.8.8Z" />
            <path d="M12,23.8c-2.8,0-5.5-.9-7.6-2.6-2.3-1.8-3.7-4.4-4.1-7.6C-.6,6.5.9,2.3,1.5.8c.1-.3.4-.5.8-.6.3,0,1.3-.2,5,3.4,1.5-.4,3.1-.6,4.7-.6s3.2.2,4.7.6C20.4,0,21.4.1,21.7.2c.3,0,.6.3.8.6.7,1.5,2.2,5.7,1.2,12.9-.4,3.1-1.8,5.7-4.1,7.6-2.1,1.7-4.8,2.6-7.6,2.6h0ZM2.8,2c-.7,1.7-1.7,5.4-.9,11.5.8,6,5.6,8.7,10.1,8.7s9.3-2.7,10.1-8.7c.8-6.1-.2-9.8-.9-11.5-.8.5-2.4,1.8-3.7,3.1-.2.2-.5.3-.8.2-1.5-.4-3.1-.6-4.7-.6s-3.2.2-4.7.6c-.3,0-.6,0-.8-.2-1.3-1.3-2.9-2.6-3.7-3.1Z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default CatIcon;
