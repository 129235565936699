import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DiagnosesDrawerContent from '../components/diagnoses/DiagnosesDrawer';
//import { DiagnosesFloatingActionBar } from '../components/diagnoses/DiagnosesFloatingActionBar';
import Loader from '../components/Loader';
import { CardSymptoms } from '../components/symptoms/CardSymptoms';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';
import { useSymptoms, useSymptomsDispatch } from '../contexts/SymptomsContext';
import { useRequestData } from '../hooks/useRequestData';
import { MainContent } from '../layouts/MainContent';
import { Diagnose } from '../types/Diagnose.type';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';
import API from '../api/Api';
import APILayer from '../api/ApiLayer';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../contexts/VeterinarianContext';
import UIState from '../types/UIState.type';
import { VetProfile } from '../types/Profile.types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DiagnosesFloatingActionBar } from '../components/diagnoses/DiagnosesFloatingActionBar';
import CardPatient from '../components/diagnoses/CardPatient';
import { Checklist } from '@mui/icons-material';
import { useRWDContext } from '../contexts/RWDContext';
import { useGeographicalAreas } from '../contexts/GeographicalAreasContext';
import { getRWDage, getRWDsymptoms } from '../utils/rwdUtils';

export type DiagnosesFilterOptions = {
  typicallyYoungAnimals: boolean;
  typicallyGeriatricAnimals: boolean;
  relevantGeographicalAreas: { GeographicalAreaId: number }[];
};

export const DefaultDiagnosesFilterOptions = {
  typicallyYoungAnimals: false,
  typicallyGeriatricAnimals: false,
};

const HasPatientWrapper = ({ children }: { children: React.ReactNode }) => {
  const { Id, GenderId, neutered } = usePatientInfo();
  const hasNecessaryInfo = useMemo(() => {
    return Id && Id !== -1 && GenderId && GenderId !== -1 && neutered !== null;
  }, [GenderId, Id, neutered]);
  const css: React.CSSProperties = useMemo(() => {
    if (hasNecessaryInfo) return {};
    return {
      position: 'relative',
      opacity: 0.3,
    };
  }, [hasNecessaryInfo]);
  return (
    <div style={css}>
      {children}
      {!hasNecessaryInfo && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
            zIndex: 9999,
          }}
        >
          &nbsp;
        </div>
      )}
    </div>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DiagnosesPage = () => {
  // Additional RWD variables
  const { track: trackRWD } = useRWDContext();
  const { geographicalAreas } = useGeographicalAreas();
  // End of additional RWD variables

  useEffect(() => {
    API.trackEvent('visit_diagnoses_page');
    /*trackRWD({
      actionName: 'diagnostics_start_page',
    });*/
  }, [trackRWD]);
  const {
    isLoading: isLoadingData,
    error: errorData,
    categories,
    symptomTypes,
    symptoms,
  } = useSymptoms();

  const veteriarianDispatch = useVeterinarianDispatch();
  const [reloadVeterinarian, setReloadVeterinarian] = useState(false);
  const { veterinarian, isLoading: isLoadingVeterinarian } = useVeterinarian();
  const [skipJoyride, setSkipJoyride] = useState(false);
  const [diagnoseCount, setDiagnoseCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [diagnoseCountProcessing, setDiagnoseCountProcessing] = useState(false);
  const [diagnoseCountePostfix, setDiagnoseCountPostfix] = useState('');
  useEffect(() => {
    if (diagnoseCount >= 100) {
      setDiagnoseCountPostfix('+');
      return;
    }
    setDiagnoseCountPostfix('');
  }, [diagnoseCount]);
  const toggleDrawer = (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (ev) ev.stopPropagation();
    setDrawerVisible(!drawerVisible);
  };
  useEffect(() => {
    if (!isLoadingVeterinarian) return;
    setReloadVeterinarian(false);
  }, [isLoadingVeterinarian]);
  const symptom_steps: Step[] = useMemo(
    () => [
      {
        content: 'Hello! Start diagnosing by adding symptoms.',
        target: '#symptom-container',
        placement: 'bottom',
        disableBeacon: true,
      },
      {
        placement: 'bottom',
        content: (
          <>
            <p style={{ textAlign: 'left' }}>
              Tick this box to see only those differentials that include this
              symptom.
            </p>
            <p style={{ textAlign: 'left' }}>
              <small>
                Tip: Tick the main symptoms as required to get more relevant
                suggestions.
              </small>
            </p>
            <p style={{ textAlign: 'left' }}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => setSkipJoyride(!skipJoyride)}
                />{' '}
                Don&apos;t show this again.
              </label>
            </p>
          </>
        ),
        target: '.symptom-required-checkbox',
        disableBeacon: true,
      },
    ],
    [skipJoyride, setSkipJoyride]
  );
  const [joyrideSteps] = useState<Step[]>(symptom_steps);
  const [currentStep, setCurrentStep] = useState(0);
  const [joyrideActive, setJoyrideActive] = useState(true); // eslint-disable-line
  const uiState = useMemo(() => {
    if (!veterinarian)
      return { diagnoses_page: { skip_joyride: true } } as UIState;
    const state = JSON.parse(veterinarian.UIStorage || '{}');
    return state as UIState;
  }, [veterinarian]);

  useEffect(() => {
    setJoyrideActive(!uiState.diagnoses_page?.skip_joyride);
  }, [uiState]);

  const dispatchSymptoms = useSymptomsDispatch();
  const {
    isLoading,
    error,
    selectedSymptomsObj,
    selectedSymptoms,
    selectedDiagnoses,
    relevantGeographicalAreas,
  } = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const { breedId } = usePatientInfo();

  const selectedDiagnoseCount = useMemo(
    () => selectedDiagnoses.length,
    [selectedDiagnoses]
  );

  // check if there's a query param to init diagnose fetch directly
  // if there is, then use useEffect to call setNeedToUpdate(true)
  const { search } = useLocation();
  const [directDiagnose, setDirectDiagnose] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(search || '');
    const directDiagnose = params.get('directDiagnose');
    if (directDiagnose) {
      setDirectDiagnose(true);
    }
  }, [search]);
  useEffect(() => {
    if (!selectedSymptomsObj || !Object.keys(selectedSymptomsObj).length)
      return;
    if (!selectedSymptoms || !selectedSymptoms.length) return;
    if (isLoading) return;
    if (isLoadingData) return;
    if (isLoadingVeterinarian) return;
    if (!categories || !categories.length) return;
    if (directDiagnose) {
      setNeedToUpdate(true);
      setDirectDiagnose(false);
      // disable joyride
      setJoyrideActive(false);
    }
  }, [
    directDiagnose,
    isLoading,
    isLoadingData,
    isLoadingVeterinarian,
    selectedSymptomsObj,
    selectedSymptoms,
    categories,
  ]);

  useEffect(() => {
    if (uiState.diagnoses_page?.skip_joyride) return;
    if (currentStep === 1 && selectedSymptoms.length > 0) {
      setJoyrideActive(true);
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'));
        document.dispatchEvent(new CustomEvent('scroll'));
      }, 500);
    }
  }, [selectedSymptoms, currentStep, uiState]);

  const [filterOptions, setFilterOptions] = useState<DiagnosesFilterOptions>({
    ...DefaultDiagnosesFilterOptions,
    relevantGeographicalAreas: (relevantGeographicalAreas || []).map((ga) => ({
      GeographicalAreaId: ga,
    })),
  });
  useEffect(() => {
    dispatchPageDiagnoses({
      type: 'setRelevantGeographicalAreas',
      geographicalAreas: filterOptions.relevantGeographicalAreas.map(
        (ga) => ga.GeographicalAreaId
      ),
    });
  }, [filterOptions, dispatchPageDiagnoses]);
  const [needToUpdate, setNeedToUpdate] = useState(false);

  const { patientId } = useParams<{ patientId: string }>();
  const patientInfo = usePatientInfo();
  const patient = useMemo(() => {
    return patientId !== 'test' && Number(patientId) > 0
      ? { Id: parseInt(patientId!) }
      : {
          AnimalTypeId: patientInfo.AnimalTypeId,
          TestPatient: true,
          Weight: patientInfo.weight,
          BreedId: patientInfo.breedId,
          Neutered: patientInfo.neutered,
          DateOfBirth: patientInfo.dateOfBirth,
          GenderId: patientInfo.GenderId,
        };
  }, [patientId, patientInfo]);

  const updateDifferentials = () => {
    console.log('updateDifferentials', selectedSymptomsObj, filterOptions);
    setNeedToUpdate(true);
    API.trackEvent('diagnoses_update_differentials');

    // Start of RWD functionality
    const arrayOfSymptoms = getRWDsymptoms(
      selectedSymptoms,
      symptoms,
      selectedSymptomsObj
    );
    const age = getRWDage(patientInfo.dateOfBirth);
    let arrayOfGA = undefined;
    if (filterOptions.relevantGeographicalAreas.length > 0) {
      arrayOfGA = [];
      for (const gaInstance of filterOptions.relevantGeographicalAreas) {
        const gaInfo = geographicalAreas.find((geoa) => {
          return geoa.Id === gaInstance.GeographicalAreaId;
        });
        arrayOfGA.push({ name: gaInfo?.Name });
      }
    }
    trackRWD({
      actionName: 'searched_differentials',
      signalmentSpecies:
        patientInfo.animalTypeName[0].toUpperCase() +
        patientInfo.animalTypeName.slice(1),
      signalmentBreed:
        patientInfo.breedName === '' ? undefined : patientInfo.breedName,
      signalmentWeight: +patientInfo.weight,
      signalmentGender: patientInfo.genderName,
      signalmentAge: age,
      signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
      signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
      symptoms: arrayOfSymptoms,
      geographicalAreas: arrayOfGA,
    });
    // End of RWD functionality
  };

  useEffect(() => {
    if (Object.entries(selectedSymptomsObj).length === 0) {
      setDiagnoseCount(0);
      return;
    }
    setDiagnoseCountProcessing(true);
    APILayer.postRequest<{ count: number }>('/diagnoses/count', {
      options: filterOptions,
      patient: {
        ...patient,
        Id: patientId === 'test' ? undefined : patient?.Id,
        TestPatient: patientId === 'test',
      },
      symptoms: Object.entries(selectedSymptomsObj).map(([Id, Mandatory]) => ({
        Id: parseInt(Id),
        ...(Mandatory && { Mandatory }),
      })),
    }).then(({ body }) => {
      setDiagnoseCount(body?.count || 0);
      setDiagnoseCountProcessing(false);
    });
  }, [filterOptions, selectedSymptomsObj, patientId, patient]);

  useEffect(() => {
    if (isLoading) {
      setNeedToUpdate(false);
    }
  }, [isLoading]);

  useRequestData<VetProfile[]>({
    needTransport: reloadVeterinarian,
    dispatch: veteriarianDispatch,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  useRequestData<Diagnose[]>({
    needTransport: needToUpdate && !isLoading,
    dispatch: dispatchPageDiagnoses,
    method: 'post',
    url: '/diagnoses',
    data: {
      options: filterOptions,
      patient,
      symptoms: Object.entries(selectedSymptomsObj).map(([Id, Mandatory]) => ({
        Id: parseInt(Id),
        ...(Mandatory && { Mandatory }),
      })),
    },
    dispatchOptions: categories,
  });

  useEffect(() => {
    if (!skipJoyride || !veterinarian) return;
    if (uiState.diagnoses_page?.skip_joyride) return;
    API.saveVeterinarian({
      ...veterinarian,
      UIStorage: JSON.stringify({
        diagnoses_page: {
          skip_joyride: true,
        },
      }),
    }).then(() => {
      setReloadVeterinarian(true);
    });
  }, [skipJoyride, veterinarian, uiState]);

  const needCategories =
    !errorData && !isLoadingData && categories.length === 0;

  const needTypes =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length === 0;

  const needSymptoms =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length > 0 &&
    symptoms.length === 0;

  useRequestData<SymptomTypeCategory[]>({
    needTransport: needCategories,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypeCategories' },
  });

  useRequestData<SymptomType[]>({
    needTransport: needTypes,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypes' },
  });

  useRequestData<Symptom[]>({
    needTransport: needSymptoms,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'Symptoms' },
  });

  if (errorData) {
    dispatchSymptoms({ type: 'reset' });
    throw errorData;
  }

  if (
    categories.length === 0 ||
    symptomTypes.length === 0 ||
    symptoms.length === 0
  ) {
    return <Loader showLoader={true} />;
  }

  if (error) {
    dispatchPageDiagnoses({ type: 'reset' });
    throw error;
  }

  const updateJoyride: (data: CallBackProps) => void = ({ action }) => {
    switch (action) {
      case 'close':
        setJoyrideActive(false);
        if (currentStep === 0) setCurrentStep(currentStep + 1);
        break;
    }
  };

  return (
    <>
      <ReactJoyride
        run={false}
        steps={joyrideSteps}
        callback={updateJoyride}
        stepIndex={currentStep}
        hideBackButton={true}
        scrollOffset={350}
      />
      <MainContent id="diagnose-container" contentTop>
        <Grid container style={{ padding: '5% 2% 0.5% 2%' }} spacing={1}>
          <Grid item sm={8}>
            <CardPatient />
          </Grid>
        </Grid>
        <HasPatientWrapper>
          <CardSymptoms id="symptom-container" />
        </HasPatientWrapper>
        <Dialog
          open={drawerVisible}
          TransitionComponent={Transition}
          onClose={() => setDrawerVisible(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            {drawerVisible && (
              <DiagnosesDrawerContent
                highlightBreedId={breedId}
                updateDifferentials={updateDifferentials}
                setDiagnosesFilterOptions={setFilterOptions}
                diagnoseFilterInitialState={filterOptions}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item md={4} textAlign={'left'}>
                <Button variant="outlined" autoFocus onClick={toggleDrawer}>
                  Back
                </Button>
              </Grid>
              <Grid item md={8} textAlign={'right'}>
                <Typography component="small">
                  {`${selectedDiagnoseCount} diagnose(s) selected`}{' '}
                </Typography>
                <Button
                  disabled={selectedDiagnoseCount < 1}
                  variant="contained"
                  onClick={() => {
                    toggleDrawer();
                    dispatchPageDiagnoses({
                      type: 'setExternalSaveAndContinue',
                      value: true,
                    });
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <div style={{ height: '68px' }}>&nbsp;</div>
        <div style={{ position: 'fixed', bottom: 80, right: 20 }}>
          <Fab
            style={{ padding: '1em 4em' }}
            variant="extended"
            size="large"
            color="primary"
            onClick={toggleDrawer}
          >
            {diagnoseCountProcessing ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <Checklist sx={{ mr: 1 }} /> Show diagnoses ({diagnoseCount}
                {diagnoseCountePostfix} possible)
              </>
            )}
          </Fab>
        </div>
        <Loader showLoader={isLoading} />
        <DiagnosesFloatingActionBar
          promptDiagnoses={() => setDrawerVisible(true)}
        />
      </MainContent>
    </>
  );
};
