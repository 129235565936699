import { useLayoutEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    backgroundColor: 'white',
    maxWidth: '650px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '90%',
  },
  highlight: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const PaymentSuccessPage = () => {
  const { classes } = useStyles();
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 2500);
    // this will clear Timeout when component unmounts
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Box className={classes.box}>
      <h1>Thanks for your subscription</h1>

      <h4>
        We are finalizing your subscription, once done you will be redirected to
        the application
      </h4>

      <h4>
        If you have any questions related to your subscription please contact
        us.
      </h4>
      <p>
        Contact{' '}
        {<span className={classes.highlight}>support@gekkovet.com</span>}
      </p>
    </Box>
  );
};
