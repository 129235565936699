import { Diagnose } from '../../types/Diagnose.type';
import { RequestAction } from '../../types/request.action.type';

export type SelectedSymptom = { Id: number; Mandatory?: boolean };

export type DiagnoseOptions = {
  selected: boolean;
};

export type SubsequentDiagnose = {
  Name: string;
  EquivalentToDiseaseId: number;
};

export type DiagnosesPageState = {
  dirty: boolean;
  isLoading: boolean;
  externalSaveAndContinue: boolean;
  error?: string;
  selectedSymptoms: number[];
  selectedSymptomsObj: { [x: string]: boolean };
  dontSelect: boolean;
  diagnoses?: Diagnose[];
  diagnosesObj: { [x: number]: number };
  excludedDiagnoses: number[];
  selectedDiagnoses: number[];
  relevantGeographicalAreas: number[];
  subsequentDiagnosesObj: {
    [x: number]: SubsequentDiagnose[];
  };
};

export const DiagnosesPageDefault: DiagnosesPageState = {
  dirty: false,
  isLoading: false,
  dontSelect: false,
  selectedSymptoms: [],
  selectedSymptomsObj: {},
  excludedDiagnoses: [],
  selectedDiagnoses: [],
  diagnosesObj: {},
  subsequentDiagnosesObj: {},
  externalSaveAndContinue: false,
  relevantGeographicalAreas: [],
};

export type DiagnosesPageAction =
  | RequestAction<Diagnose[]>
  | {
      type: 'resetAll';
    }
  | {
      type: 'addSymptom';
      symptomId: number;
    }
  | {
      type: 'removeSymptom';
      symptomId: number;
    }
  | {
      type: 'resetSelectedSymptoms';
    }
  | {
      type: 'setDiagnoseSelected';
      checked: boolean;
      diagnoseId: number;
      subsequentDiagnoses?: SubsequentDiagnose[];
    }
  | {
      type: 'toggleExcludeDiagnose';
      diagnoseId: number;
    }
  | {
      type: 'resetDiagnoses';
    }
  | {
      type: 'setDontSelectDiagnoses';
      checked: boolean;
    }
  | {
      type: 'setSymptomMandatory';
      symptomId: number;
      checked: boolean;
    }
  | {
      type: 'setRelevantGeographicalAreas';
      geographicalAreas: number[];
    }
  | {
      type: 'setExternalSaveAndContinue';
      value: boolean;
    }
  | {
      type: 'import';
      diagnosesPage: DiagnosesPageState;
    }
  | {
      type: 'setDirty';
      dirty: boolean;
    };
