import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { CardSelectedDiagnoses } from '../components/treatments/CardSelectedDiagnoses';
import { TreatmentsFloatingActionBar } from '../components/treatments/TreatmentsFloatingActionBar';
import { UNDEFINED_ID } from '../config/constant.params';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';
import { useDiagnosesPage } from '../contexts/DiagnosesPageContext';
import { useWorkContext } from '../contexts/WorkContext';
import { MainContent } from '../layouts/MainContent';
import API from '../api/Api';

export const TreatmentsPage = () => {
  useEffect(() => {
    API.trackEvent('visit_treatments_page');
  }, []);

  const {
    Id,
    breedId,
    breedName,
    isLoading: isLoadingBreed,
    error: errorBreed,
  } = usePatientInfo();
  const { patient } = useWorkContext();
  const { selectedDiagnoses } = useDiagnosesPage();

  const { patientId } = useParams<{ patientId: string }>();

  const noPatient =
    selectedDiagnoses &&
    Id !== parseInt(patientId!) &&
    !patient &&
    patientId !== 'test';

  const noPatientInfo = selectedDiagnoses && patient && Id !== patient.Id;

  const noBreedName =
    selectedDiagnoses &&
    !isLoadingBreed &&
    !errorBreed &&
    breedId !== UNDEFINED_ID &&
    breedName === '';

  if (noPatient || noPatientInfo || noBreedName || isLoadingBreed) {
    return <Loader showLoader={true} />;
  }

  return (
    <MainContent contentTop>
      <CardSelectedDiagnoses />
      <TreatmentsFloatingActionBar />
    </MainContent>
  );
};
