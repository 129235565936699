import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 24 24"
      >
        <g id="icons">
          <g>
            <path d="M13.8,14.2c0,.6-.8,1.5-1.8,1.5s-1.8-.9-1.8-1.5.8-.8,1.8-.8,1.8.1,1.8.8Z" />
            <g>
              <path d="M9.6,10.4c0,.8-.6,1.4-1.4,1.4s-1.4-.6-1.4-1.4.6-1.4,1.4-1.4,1.4.6,1.4,1.4Z" />
              <circle cx="15.9" cy="10.4" r="1.4" />
            </g>
            <path d="M9.5,19.1c-1.7,0-3.1-1.4-3.1-3.1,0-.1,0-.6,0-1.1,0-.4.5-.6.8-.5.4,0,.6.5.5.8,0,.2,0,.6,0,.7,0,0,0,0,0,0,0,1,.8,1.8,1.8,1.8s1.8-.8,1.8-1.8v-1.1c0-.4.3-.7.7-.7s.7.3.7.7v1.1c0,1.7-1.4,3.1-3.1,3.1h0Z" />
            <path d="M14.4,19.1c-1.7,0-3.1-1.4-3.1-3.1v-1.1c0-.4.3-.7.7-.7s.7.3.7.7v1.1c0,1,.8,1.8,1.8,1.8s1.8-.8,1.8-1.8,0,0,0,0c0-.1,0-.4,0-.7,0-.4.1-.7.5-.8.4,0,.7.1.8.5.1.4,0,.9,0,1.1,0,1.7-1.4,3.1-3.1,3.1h0Z" />
            <path d="M20.5,18h-.6c-.4,0-.7-.3-.7-.7s.3-.7.7-.7h.6c1.3,0,2-.8,2.1-2.3.1-2.2-.1-4.1-.7-6.1-1.2-3.7-4.1-5.9-8.1-5.9h-3.7c-4,0-6.9,2.1-8.1,5.9-.6,2-.8,3.9-.7,6.1,0,1.5.8,2.3,2.1,2.3h.6c.4,0,.7.3.7.7s-.3.7-.7.7h-.6c-2,0-3.3-1.4-3.4-3.6,0-1.5,0-3.7.8-6.5C2.1,3.6,5.6,1.1,10.2,1.1h3.7c4.6,0,8,2.5,9.3,6.9.9,2.8.9,5.1.8,6.5-.1,2.2-1.5,3.6-3.4,3.6h0Z" />
            <path d="M12.2,22.9c0,0-.1,0-.2,0-2.6,0-4.8-.8-6.5-2.4-1.7-1.7-2.7-4.2-2.7-7.1v-1.4c0-1.8.3-3.5,1-5.1.1-.3.5-.5.9-.4.3.1.5.5.4.9-.6,1.5-.9,3-.9,4.6v1.4c0,2.5.8,4.7,2.3,6.1,1.4,1.4,3.3,2.1,5.5,2h0c2.2,0,4.1-.6,5.5-2,1.5-1.5,2.3-3.6,2.3-6.1v-1.4c0-1.6-.3-3.2-.9-4.6-.1-.3,0-.7.4-.9.3-.1.7,0,.9.4.7,1.6,1,3.4,1,5.1v1.4c0,2.9-1,5.4-2.7,7.1-1.6,1.6-3.8,2.4-6.3,2.4h0Z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default DogIcon;
