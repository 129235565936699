import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PatientPage } from '../pages/PatientPage';
import { VisitRouter } from './VisitRouter';
import { PatientPageProvider } from '../contexts/PatientPageContext';

export const PatientRouter = () => {
  useEffect(() => {
    return () => {
      console.log('unmounting PatientRouter');
    };
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PatientPageProvider>
            <PatientPage />
          </PatientPageProvider>
        }
      />
      <Route
        path=":patientId"
        element={
          <PatientPageProvider>
            <PatientPage />
          </PatientPageProvider>
        }
      />
      <Route path={`:patientId/visit/*`} element={<VisitRouter />} />
    </Routes>
  );
};
