import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../../contexts/CurrentPatientInfoContext';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Patient } from '../Patient';
import { Patient as PatientType, TestPatient } from '../../types/Patient.type';
import {
  usePatientPageDispatch,
  usePatientPageState,
} from '../../contexts/PatientPageContext';
import API from '../../api/Api';
import {
  ApiPatientPayload,
  patientInfoToPatient,
} from '../../utils/insertPatientUtils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DogIcon from '../../assets/icons/DogIcon';
import CatIcon from '../../assets/icons/CatIcon';
import { ListAllPatientsContainer } from '../all-patients/ListAllPatientsContainer';
import { AutoAwesome, Edit, Search } from '@mui/icons-material';
import moment from 'moment';

export const PatientInfo = ({
  setPatient,
}: {
  setPatient: (patient: PatientType, navigate?: boolean) => void;
}) => {
  const actionRef = React.useRef<HTMLDivElement | null>(null);
  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();
  const patientInfo = usePatientInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const [patientPayload, setPatientPayload] = useState<PatientType | null>(
    null
  );
  const [patientDialogOpen, setPatientDialogOpen] = useState(false);
  const [savePatientVisible, setSavePatientVisible] = useState(false);
  const [savePatientContentVisible, setSavePatientContentVisible] =
    useState(false);
  const [confirmPatientEdit, setConfirmPatientEdit] = useState(false);
  const { isSaving, breeds } = usePatientPageState();
  const dispatch = usePatientPageDispatch();
  const dispatchPatientInfo = usePatientInfoDispatch();

  const showSavePatient = () => {
    if (patientId !== 'test') {
      setConfirmPatientEdit(true);
      return;
    }
    setSavePatientVisible(true);
  };

  useEffect(() => {
    if (savePatientVisible === savePatientContentVisible) return;
    if (!savePatientVisible) {
      setSavePatientContentVisible(false);
    } else {
      setTimeout(() => {
        setSavePatientContentVisible(true);
      }, 1);
    }
  }, [savePatientVisible, savePatientContentVisible]);

  const theme = useTheme();
  // transform patientInfo to patientPayload
  useEffect(() => {
    if (!patientInfo) return;
    console.log('transforming patientInfo to patientPayload');

    setPatientPayload(patientInfoToPatient(patientInfo));
  }, [patientInfo]);

  const fetchBreedsByAnimalTypeId = useCallback(
    async (animalTypeId: number) => {
      const breedsResponse: any = await API.getModel({
        modelName: 'Breeds',
        AnimalTypeId: animalTypeId,
      });
      dispatch({ type: 'setBreeds', payload: breedsResponse.body });
    },
    [dispatch]
  );
  useEffect(() => {
    fetchBreedsByAnimalTypeId(patientInfo.AnimalTypeId);
  }, [fetchBreedsByAnimalTypeId, patientInfo.AnimalTypeId]);
  const setPatientSaving = (saving: boolean) => {
    dispatch({ type: 'setSaving', payload: saving });
  };

  const updateInMemoryPatient = (payload: ApiPatientPayload) => {
    console.log('updating in memory patient');
    // transform payload to PatienType
    const patient: PatientType | TestPatient = {
      ...payload,
      Id: patientId === 'test' ? -3 : patientInfo.Id || -3,
      AnimalTypeId: payload.AnimalTypeId,
      Weight: payload.Weight || '',
      BreedId: payload.BreedId || -1,
      PetOwnerId: payload.PetOwnerId || -1,
      DateOfBirth: payload.DateOfBirth
        ? moment(payload.DateOfBirth).format('YYYY-MM-DD')
        : null,
    };
    setPatient(patient, false);
  };

  const createPatient = async (payload: ApiPatientPayload) => {
    setSavePatientVisible(false);
    if (patientId === 'test') {
      setPatientSaving(false);
      return updateInMemoryPatient(payload);
    }
    const patientResponse = await API.createPatient({
      ...payload,
      PetOwnerId: null,
    });
    await API.trackEvent('create_patient');
    if (!patientResponse || !patientResponse.body) return;
    // replace current url with new patient id
    const newPatientId = patientResponse.body.Id;
    const url = location.pathname.replace('test', newPatientId.toString());
    navigate(url);
  };

  const updatePatient = async (payload: ApiPatientPayload) => {
    if (patientId === 'test') return updateInMemoryPatient(payload);
    if (!patientId) return;
    const patientResponse = await API.updatePatient(payload, {}, patientId);
    await API.trackEvent('update_patient');
    if (!patientResponse || !patientResponse.body) return;
    setSavePatientVisible(false);
    setPatientSaving(false);
    // update patient info in context
    const updatedPatient = patientResponse.body;
    setPatient(updatedPatient);
  };

  const resetPatientAndEdit = () => {
    // replace current url in history with patientId=test
    if (!patientId) return;
    const url = location.pathname
      .replace(patientId, 'test')
      .replace(visitId || 'test', 'test');
    navigate(url, { replace: true });
    setPatientPayload(null);
    dispatchPatientInfo({ type: 'resetPatientInfo' });
    setConfirmPatientEdit(false);
    setSavePatientVisible(true);
  };

  if (!patientInfo || patientInfo.AnimalTypeId < 1) return null;
  const chipLabel =
    patientInfo.Id === -2
      ? 'AI extracted'
      : patientId === 'test'
      ? 'New patient'
      : '';
  const chipIcon = patientInfo.Id === -2 ? <AutoAwesome /> : undefined;
  const chipColor = patientInfo.Id === -2 ? 'success' : 'secondary';
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Box sx={{ display: 'flex' }}>
            <Box
              flexDirection={'column'}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex',
                padding: 1,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  height: 64,
                  width: 64,
                }}
              >
                {patientInfo.AnimalTypeId === 2 ? (
                  <DogIcon fontSize="large" />
                ) : null}
                {patientInfo.AnimalTypeId === 1 ? (
                  <CatIcon fontSize="large" />
                ) : null}
              </Avatar>
              {chipLabel && (
                <Chip
                  sx={{ marginTop: 1, fontSize: '0.9rem' }}
                  label={chipLabel}
                  icon={chipIcon}
                  color={chipColor}
                />
              )}
            </Box>

            <Box sx={{ marginLeft: 1, textAlign: 'left' }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  marginTop: 0,
                  paddingTop: 0,
                  fontWeight: patientInfo.name ? 'bold' : 'normal',
                  fontStyle: patientInfo.name ? 'normal' : 'italic',
                }}
              >
                {patientInfo.name || '(no name)'}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {patientInfo.breedName || '(unknown breed)'}
                {', '}
                {patientInfo.genderName}
                {patientInfo.neutered ? ', neutered' : ', intact'}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {patientInfo.weightStr ? `${patientInfo.weightStr}, ` : ''}
                {patientInfo.patientAge}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Button
            fullWidth
            variant={
              patientInfo.Id < 0 || patientId === 'test'
                ? 'contained'
                : 'outlined'
            }
            color="primary"
            onClick={showSavePatient}
            startIcon={<Edit />}
          >
            Edit patient details
          </Button>
          <Tooltip
            title={
              visitId !== 'test' ? (
                <h2 style={{ padding: '0', margin: '0' }}>
                  You cannot switch the patient for an existing visit
                </h2>
              ) : (
                ''
              )
            }
          >
            <span>
              <Button
                sx={{ marginTop: 1 }}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setPatientDialogOpen(true)}
                disabled={visitId !== 'test'}
                startIcon={<Search />}
              >
                Select another patient
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Dialog
        open={savePatientVisible}
        onClose={() => setSavePatientVisible(false)}
      >
        <DialogTitle>Save or edit patient</DialogTitle>
        <DialogContent>
          <p>&nbsp;</p>
          {savePatientContentVisible && (
            <Patient
              chromeless
              isEditPage={patientId !== 'test'}
              integrationPatient={false}
              integrationPatientData={null}
              patient={patientPayload}
              updatePatient={updatePatient}
              createPatient={createPatient}
              breedsData={breeds}
              patientSaving={isSaving}
              fetchBreedsByAnimalTypeId={fetchBreedsByAnimalTypeId}
              setPatientSaving={setPatientSaving}
              actionContainer={actionRef.current}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSavePatientVisible(false)}>Close</Button>
          <div ref={actionRef}></div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={patientDialogOpen}
        onClose={() => setPatientDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Select patient</DialogTitle>
        <DialogContent>
          <ListAllPatientsContainer isSelect={true} onSelect={setPatient} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPatientDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmPatientEdit}
        onClose={() => setConfirmPatientEdit(false)}
      >
        <DialogTitle>Edit patient details</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to edit the details of an existing patient. This will
            affect all cases with this patient.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setConfirmPatientEdit(false)}
          >
            Cancel
          </Button>
          {visitId === 'test' && (
            <Button
              color="secondary"
              variant="contained"
              onClick={resetPatientAndEdit}
            >
              Create new patient
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSavePatientVisible(true);
              setConfirmPatientEdit(false);
            }}
          >
            Edit patient
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
