import React, { useEffect, useState } from 'react';
import { ROUTE_VISIT_SUMMARY } from '../../config/routes.config';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRWDContext } from '../../contexts/RWDContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../../contexts/DosagesPageContext';
import { useDoseParams } from '../../contexts/DoseParamsContext';
import {
  getRWDage,
  getRWDdiagnosesMethod3,
  getRWDdosages,
  getRWDsymptoms,
  getRWDtreatments,
} from '../../utils/rwdUtils';
import { FloatingActionBar } from '../diagnoses/FloatingActionBar';

export const DosageFloatingActionBar = () => {
  const { exportState } = useWorkHistory();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();

  // Variables for RWD
  const { track: trackRWD } = useRWDContext();
  const patientInfo = usePatientInfo();
  const { symptoms } = useSymptoms();
  const {
    diagnoses,
    selectedDiagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
  } = useDiagnosesPage();
  const {
    selectedTreatments,
    selectedTreatmentsObj,
    selectedSubsequentDiseases,
    subsequentTreatmentInfos,
  } = useTreatmentsPage();
  const { selectedDrugDosages, externalSaveAndContinue } = useDosagesPage();
  const { doseTypes } = useDoseParams();
  const dosagesPageDispatch = useDosagesPageDispatch();
  //End of variables for RWD

  useEffect(() => {
    if (externalSaveAndContinue) {
      dosagesPageDispatch({
        type: 'setExternalSaveAndContinue',
        value: false,
      });
      handleSaveAndContinue();
    }
  }, [externalSaveAndContinue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async (force: boolean) => {
    if (force || (visitId && visitId !== 'test')) {
      setShowLoader(true);
      await exportState();
      setShowLoader(false);
    }
    // Start of RWD functionality
    if (Object.keys(selectedDrugDosages).length !== 0) {
      const arrayOfDosages = getRWDdosages(
        selectedTreatments,
        selectedTreatmentsObj,
        selectedDrugDosages,
        doseTypes
      );
      const arrayOfTreatments = getRWDtreatments(
        selectedTreatments,
        selectedTreatmentsObj,
        diagnoses
      );
      const arrayOfDiagnoses = getRWDdiagnosesMethod3(
        selectedDiagnoses,
        diagnoses,
        selectedSubsequentDiseases,
        subsequentTreatmentInfos
      );
      const arrayOfSymptoms = getRWDsymptoms(
        selectedSymptoms,
        symptoms,
        selectedSymptomsObj
      );
      const age = getRWDage(patientInfo.dateOfBirth);
      trackRWD({
        actionName: 'calculated_dosages_diagnosis',
        dosages: arrayOfDosages,
        treatments: arrayOfTreatments,
        diseases: arrayOfDiagnoses,
        symptoms: arrayOfSymptoms,
        signalmentSpecies:
          patientInfo.animalTypeName[0].toUpperCase() +
          patientInfo.animalTypeName.slice(1),
        signalmentBreed:
          patientInfo.breedName === '' ? undefined : patientInfo.breedName,
        signalmentWeight: +patientInfo.weight,
        signalmentGender: patientInfo.genderName,
        signalmentAge: age,
        signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
        signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
      });
    }
    // end of RWD functionality
  };

  const handleSaveAndContinue = async () => {
    await handleSave(false);
    if (!patientId || !visitId) return;
    const path = generatePath(ROUTE_VISIT_SUMMARY, {
      patientId,
      visitId,
    });
    navigate(path);
  };

  return (
    <FloatingActionBar
      showLoader={showLoader}
      handleSave={handleSave}
      onContinue={handleSaveAndContinue}
    ></FloatingActionBar>
  );
};
