import { Female, Male } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

export const GenderRadios = ({
  gender,
  handleChange,
  error,
}: {
  gender: number;
  handleChange: any;
  error: any;
}) => {
  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={error}
      required
      fullWidth
    >
      <FormLabel component="legend" style={{ textAlign: 'left' }}>
        Gender
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="GenderId"
        value={gender}
        onChange={handleChange}
      >
        <FormControlLabel
          value="2"
          control={<Radio />}
          label={
            <span>
              Female <Female style={{ verticalAlign: 'middle' }} />
            </span>
          }
        />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label={
            <span>
              Male <Male style={{ verticalAlign: 'middle' }} />
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};
