import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDiagnosesPageDispatch } from '../contexts/DiagnosesPageContext';
import { usePatientInfoDispatch } from '../contexts/CurrentPatientInfoContext';
import { useLLMDiagnose } from '../contexts/LLMDiagnoseContext';

const DiagnoseHomePage = () => {
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const { setExtractedSymptoms, setExtractedAnimal, setFreeText } =
    useLLMDiagnose();
  // on init, reset all diagnose data
  useEffect(() => {
    dispatchPageDiagnoses({ type: 'resetAll' });
    dispatchPatientInfo({ type: 'resetPatientInfo' });
    setExtractedSymptoms({});
    setFreeText('');
    setExtractedAnimal(null);
  }, []); // eslint-disable-line
  return <Navigate to="/work/patient/test/visit/test/diagnoses" />;
};

export default DiagnoseHomePage;
