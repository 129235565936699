import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import Hidden from '@mui/material/Hidden';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import ProgressBar from './ProgressBar';
import {
  ROUTE_NEW_PATIENT,
  ROUTE_VISIT_DIAGNOSIS,
  ROUTE_VISIT_DOSAGES,
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../config/routes.config';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';
import { useLLMDiagnose } from '../contexts/LLMDiagnoseContext';
import { AppBar, Grid } from '@mui/material';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../contexts/TreatmentsPageContext';
import { useDosagesPageDispatch } from '../contexts/DosagesPageContext';

type Step = {
  activeStep: number;
  isStepDone?: boolean;
};

type StepAction =
  | { type: 'step'; activeStep: number; isStepDone?: boolean }
  | { type: 'name'; name: string };

type StepState = Step & { name: string };

const initialState: StepState = {
  name: 'New patient',
  activeStep: 0,
  isStepDone: false,
};

const stepStateReducer: Reducer<StepState, StepAction> = (
  state,
  action
): StepState => {
  switch (action.type) {
    case 'name': {
      return {
        name: action.name,
        activeStep: state.activeStep,
        isStepDone: state.isStepDone,
      };
    }
    case 'step': {
      return {
        name: state.name,
        activeStep: action.activeStep,
        isStepDone: action.isStepDone || false,
      };
    }
    default:
      return state;
  }
};

const ALL_STEPS = [
  'Symptoms',
  'Diagnosis',
  'Treatment',
  'Dosage',
  'Summary',
] as const;
type StepName = (typeof ALL_STEPS)[number];

export const PatientNavigation = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(stepStateReducer, initialState);
  const { isActiveLLMDiagnoseFlow } = useLLMDiagnose();
  const steps = [
    isActiveLLMDiagnoseFlow ? 'Symptoms' : '',
    'Diagnosis',
    'Treatment',
    'Dosage',
    'Summary',
  ].filter((i) => !!i);

  const { selectedDiagnoses } = useDiagnosesPage();
  const diagnosePageDispatch = useDiagnosesPageDispatch();
  const treatmentPageDispatch = useTreatmentsPageDispatch();
  const dosagesPageDispatch = useDosagesPageDispatch();
  const { selectedTreatments } = useTreatmentsPage();

  const stepIndex = useMemo(
    () => (name: StepName) => {
      const index = ALL_STEPS.indexOf(name);
      if (!isActiveLLMDiagnoseFlow) return index - 1;
      return index;
    },
    [isActiveLLMDiagnoseFlow]
  );

  const { pathname } = useLocation();
  const { name } = usePatientInfo();

  const match = matchPath(
    { path: ROUTE_VISIT_DIAGNOSIS, end: false },
    pathname
  );
  const { patientId, visitId } = useMemo(() => {
    return {
      patientId: match?.params.patientId || null,
      visitId: match?.params.visitId || null,
    };
  }, [match]);

  useEffect(() => {
    if (!name) dispatch({ type: 'name', name: initialState.name });
    else dispatch({ type: 'name', name });
  }, [name]);

  const matchStep = useCallback(() => {
    const stepCreatePatient = matchPath(
      {
        path: ROUTE_NEW_PATIENT,
        end: true,
      },
      pathname
    );
    if (stepCreatePatient) {
      dispatch({ type: 'name', name: initialState.name });
    } else {
      if (name) {
        dispatch({ type: 'name', name });
      } else if (state.name === initialState.name) {
        dispatch({ type: 'name', name: 'Patient' });
      }

      const stepSummary = matchPath(
        {
          path: ROUTE_VISIT_SUMMARY,
          end: true,
        },
        pathname
      );

      if (stepSummary) {
        dispatch({ type: 'step', activeStep: stepIndex('Summary') });
      } else {
        const stepDosages = matchPath(
          {
            path: ROUTE_VISIT_DOSAGES,
            end: true,
          },
          pathname
        );

        if (stepDosages) {
          dispatch({ type: 'step', activeStep: stepIndex('Dosage') });
        } else {
          const stepTreatments = matchPath(
            {
              path: ROUTE_VISIT_TREATMENTS,
              end: true,
            },
            pathname
          );

          if (stepTreatments) {
            dispatch({ type: 'step', activeStep: stepIndex('Treatment') });
          } else {
            const stepDiagnosis = matchPath(
              {
                path: ROUTE_VISIT_DIAGNOSIS,
                end: true,
              },
              pathname
            );

            if (stepDiagnosis) {
              dispatch({ type: 'step', activeStep: stepIndex('Diagnosis') });
            } else {
              dispatch({ type: 'step', activeStep: -1 });
            }
          }
        }
      }
    }
  }, [name, pathname, state.name, stepIndex]);

  useEffect(() => {
    matchStep();
  }, [matchStep]);

  if (state.activeStep === -1) {
    return null;
  }

  const onStepClick = (index: number) => {
    const step = steps[index];
    switch (step) {
      case 'Diagnosis':
        navigate(
          generatePath(ROUTE_VISIT_DIAGNOSIS, {
            patientId: patientId || null,
            visitId: visitId || null,
          })
        );
        break;
      case 'Treatment':
        if (steps[state.activeStep] === 'Diagnosis') {
          diagnosePageDispatch({
            type: 'setExternalSaveAndContinue',
            value: true,
          });
          return;
        }
        navigate(generatePath(ROUTE_VISIT_TREATMENTS, { patientId, visitId }));
        break;
      case 'Dosage':
        if (steps[state.activeStep] === 'Treatment') {
          treatmentPageDispatch({
            type: 'setExternalSaveAndContinue',
            value: true,
          });
          return;
        }
        navigate(generatePath(ROUTE_VISIT_DOSAGES, { patientId, visitId }));
        break;
      case 'Summary':
        if (steps[state.activeStep] === 'Dosage') {
          dosagesPageDispatch({
            type: 'setExternalSaveAndContinue',
            value: true,
          });
          return;
        }
        navigate(generatePath(ROUTE_VISIT_SUMMARY, { patientId, visitId }));
        break;
      default:
        break;
    }
  };

  const tooltipLabel = (index: number) => {
    if (isStepClickable(index)) return null;
    const step = steps[index];
    switch (step) {
      case 'Treatment':
        return 'You need to select at least one diagnose to proceed';
      case 'Dosage':
        return 'You need to select treatments before proceeding';
      case 'Summary':
        return 'You need to complete previous steps before proceeding';
      default:
        return null;
    }
  };

  const isStepClickable = (index: number) => {
    if (index === 0) return true;
    if (steps[index] === 'Treatment' && selectedDiagnoses.length) return true;
    if (
      (steps[index] === 'Dosage' || steps[index] === 'Summary') &&
      selectedTreatments.length
    )
      return true;
    return state.activeStep >= index;
  };

  return (
    <AppBar position="sticky" color="default" style={{ top: '65px' }}>
      <Grid container spacing={0}>
        <Grid item sm={12} md={7} lg={8}>
          <Hidden smDown>
            <ProgressBar
              activeStep={state.activeStep}
              stepDone={state.isStepDone || false}
              steps={steps}
              onStepClick={onStepClick}
              tooltipLabel={tooltipLabel}
              isStepClickable={isStepClickable}
            />
          </Hidden>
        </Grid>
      </Grid>
    </AppBar>
  );
};
