import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'light',
      primary: {
        main: '#00cd87',
        contrastText: '#fdfdfd',
        dark: '#00aa70',
      },
      secondary: {
        main: '#5fbeff',
        contrastText: '#000000',
      },
      /*info: {
        main: '#FF5A32',
        contrastText: '#d2c8c3',
      },*/
      background: {
        default: '#f1f1f1',
        paper: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Arial',
      fontSize: 12.25,
      h4: {
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
        },
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingTop: '1em',
        paddingBottom: '0.5em',
      },
      subtitle1: {
        fontWeight: 'bold',
      },
      body1: {
        fontSize: '1rem',
      },
    },
    overrides: {
      MuiAlert: {
        root: {
          fontSize: '1.1rem',
        },
      },
      MuiRadio: {
        root: {
          color: '#00cd87',
        },
        colorSecondary: {
          '&$checked': {
            color: '#00cd87',
          },
        },
      },
    },
  })
);

export default theme;
