import React from 'react';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DrugDosageInfo } from '../dosages/DrugDosageInfo';
import { UNDEFINED_ID } from '../../config/constant.params';
import {
  DrugSubstance_Dose,
  DrugSubstanceInfo,
  DoseAlternative,
} from '../../types/DrugDosages.type';
import { getDose, getDuration, getInterval } from './DrugDosageTableUtils';
import { DosageCalculatorPopup } from '../dosages/DosageCalculatorPopup';
import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../../contexts/DosagesPageContext';
import { DrugDosageInfoAccordion } from '../dosages/DrugDosageInfoAccordion';
import { Button } from '../Button';
import { Sources } from '../common/Sources';
import { DrugSubstanceAnimalInfoAccordion } from '../dosages/DrugSubstanceAnimalInfoAccordion';

export const TableHeadCell = withStyles(TableCell, () => ({
  root: {
    fontWeight: 'bold',
    borderBottom: 'none',
    padding: '16px 0 0 0',
  },
}));

export const TableIndicationCell = withStyles(TableCell, () => ({
  root: {
    borderBottom: 'none',
    padding: '16px 0 0 0',
  },
}));

export const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    borderBottom: 'none',
  },
}));

export const AlternativeHeadingTableCell = withStyles(TableCell, () => ({
  root: {
    borderBottom: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    padding: '12px 0px 0px 0px',
  },
}));

export const AlternativeInfoTableCell = withStyles(TableCell, () => ({
  root: {
    padding: '2px 0px 16px 0px',
    fontSize: '0.9rem',
    width: 100,
  },
}));

export const ButtonTableCell = withStyles(TableCell, () => ({
  root: {
    padding: '2px 2px 16px 0px',
    width: 100,
    textAlign: 'right',
  },
}));

export const AlternativeRows = ({
  treatmentId,
  drugSubstanceDose,
  isInteractive,
  drugSubstanceId = UNDEFINED_ID,
  diseaseName,
  mobile,
  isDiseasesAndTreatments = false,
  setCalculatorDoseAlternative,
  isDrugSubstances = false,
}: {
  treatmentId: number;
  drugSubstanceDose: DrugSubstance_Dose;
  isInteractive: boolean;
  drugSubstanceId?: number;
  diseaseName: string;
  mobile?: boolean;
  isDiseasesAndTreatments: boolean;
  setCalculatorDoseAlternative?: (doseAlternative: DoseAlternative) => void;
  isDrugSubstances: boolean;
}) => {
  const { selectedDrugDosages } = useDosagesPage();
  const dispatchDosages = useDosagesPageDispatch();

  const handleDrugDosageDeselect = (doseAlternativeId: number) => {
    dispatchDosages({
      type: 'setDrugDosageSelect',
      treatmentId,
      drugSubstanceId,
      doseAlternativeId,
      checked: false,
    });
    dispatchDosages({
      type: 'setDeletedDrugAlternative',
      deletedDrugAlternative: {
        treatmentId,
        drugSubstanceId,
        doseAlternativeId,
      },
    });
  };

  const selectCalculatorDoseAlternative = (
    doseAlternative: DoseAlternative
  ) => {
    if (isDiseasesAndTreatments || isDrugSubstances) {
      setCalculatorDoseAlternative &&
        setCalculatorDoseAlternative(doseAlternative);
    }
  };

  return (
    <React.Fragment>
      {drugSubstanceDose.DoseAlternatives.map((doseAlternative, i: number) => {
        const selectedDoseAlternative = Boolean(
          isInteractive &&
            selectedDrugDosages[treatmentId] &&
            selectedDrugDosages[treatmentId][drugSubstanceId] &&
            selectedDrugDosages[treatmentId][drugSubstanceId][
              doseAlternative.Id
            ]
        );

        return (
          <React.Fragment key={doseAlternative.Id}>
            {mobile ? (
              <React.Fragment>
                <TableRow>
                  <AlternativeHeadingTableCell colSpan={isInteractive ? 9 : 8}>
                    ALTERNATIVE {i + 1}{' '}
                    {
                      <Sources
                        sources={doseAlternative.Source_DoseAlternatives}
                      ></Sources>
                    }
                  </AlternativeHeadingTableCell>{' '}
                </TableRow>
                {doseAlternative.DrugSubstanceDose_Phases.map(
                  (phase, index) => {
                    const isFirstLine =
                      index <
                      doseAlternative.DrugSubstanceDose_Phases.length - 1;
                    const style = { borderBottom: 'none' };
                    return (
                      <React.Fragment key={phase.Id}>
                        <StyledTableRow>
                          <TableHeadCell style={{ padding: '0px' }}>
                            TYPE
                          </TableHeadCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <AlternativeInfoTableCell style={style}>
                            {phase.DoseType.Name}
                          </AlternativeInfoTableCell>
                        </StyledTableRow>
                        <StyledTableRow key={phase.Id}>
                          <TableHeadCell style={{ padding: '0px' }}>
                            ROUTE
                          </TableHeadCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <AlternativeInfoTableCell style={style}>
                            {phase.DrugAdministrationRoute.Name}
                          </AlternativeInfoTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableHeadCell style={{ padding: '0px' }}>
                            DOSE
                          </TableHeadCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <AlternativeInfoTableCell style={style} colSpan={2}>
                            {getDose(phase)}
                          </AlternativeInfoTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableHeadCell style={{ padding: '0px' }}>
                            INTERVAL
                          </TableHeadCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <AlternativeInfoTableCell style={style} colSpan={2}>
                            {getInterval(phase)}
                          </AlternativeInfoTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableHeadCell style={{ padding: '0px' }}>
                            DURATION
                          </TableHeadCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <AlternativeInfoTableCell style={style} colSpan={2}>
                            {getDuration(phase)}
                          </AlternativeInfoTableCell>
                        </StyledTableRow>
                        <StyledTableRow key={phase.Id}>
                          {isInteractive && !isFirstLine && (
                            <ButtonTableCell style={style}>
                              <Typography
                                component="span"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <DosageCalculatorPopup
                                  title={
                                    selectedDoseAlternative
                                      ? 'Selected'
                                      : 'Select & Calculate'
                                  }
                                  treatmentId={treatmentId}
                                  drugSubstanceId={drugSubstanceId}
                                  doseAlternative={doseAlternative}
                                  diseaseName={diseaseName}
                                />
                                {selectedDoseAlternative && (
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    style={{
                                      padding: '0.1em 0.5em',
                                      marginRight: '0.5em',
                                    }}
                                    onClick={() =>
                                      handleDrugDosageDeselect(
                                        doseAlternative.Id
                                      )
                                    }
                                    size="large"
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                )}
                              </Typography>
                            </ButtonTableCell>
                          )}
                          {isDiseasesAndTreatments && !isFirstLine && (
                            <ButtonTableCell style={style}>
                              <Typography
                                component="span"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  onClick={() =>
                                    selectCalculatorDoseAlternative(
                                      doseAlternative
                                    )
                                  }
                                >
                                  Calculator
                                </Button>
                              </Typography>
                            </ButtonTableCell>
                          )}
                          {isDrugSubstances && !isFirstLine && (
                            <ButtonTableCell style={style}>
                              <Typography
                                component="span"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  onClick={() =>
                                    selectCalculatorDoseAlternative(
                                      doseAlternative
                                    )
                                  }
                                >
                                  Calculator
                                </Button>
                              </Typography>
                            </ButtonTableCell>
                          )}
                        </StyledTableRow>
                      </React.Fragment>
                    );
                  }
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableRow>
                  <AlternativeHeadingTableCell colSpan={isInteractive ? 9 : 8}>
                    ALTERNATIVE {i + 1}{' '}
                    {
                      <Sources
                        sources={doseAlternative.Source_DoseAlternatives}
                      ></Sources>
                    }
                  </AlternativeHeadingTableCell>
                </TableRow>
                {doseAlternative.DrugSubstanceDose_Phases.map(
                  (phase, index) => {
                    const isFirstLine =
                      index <
                      doseAlternative.DrugSubstanceDose_Phases.length - 1;
                    const isLastAlternative =
                      i === drugSubstanceDose.DoseAlternatives.length - 1;

                    const style =
                      isFirstLine || isLastAlternative
                        ? { borderBottom: 'none' }
                        : {};
                    return (
                      <StyledTableRow key={phase.Id}>
                        <AlternativeInfoTableCell style={style}>
                          {phase.DoseType.Name}
                        </AlternativeInfoTableCell>
                        <AlternativeInfoTableCell style={style}>
                          {phase.DrugAdministrationRoute.Name}
                        </AlternativeInfoTableCell>
                        <AlternativeInfoTableCell style={style} colSpan={2}>
                          {getDose(phase)}
                        </AlternativeInfoTableCell>
                        <AlternativeInfoTableCell style={style} colSpan={2}>
                          {getInterval(phase)}
                        </AlternativeInfoTableCell>
                        <AlternativeInfoTableCell style={style} colSpan={2}>
                          {getDuration(phase)}
                        </AlternativeInfoTableCell>

                        {isInteractive && !isFirstLine && (
                          <ButtonTableCell style={style}>
                            <Typography
                              component="span"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {selectedDoseAlternative && (
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  style={{
                                    padding: '0.1em 0.5em',
                                    marginRight: '0.5em',
                                  }}
                                  onClick={() =>
                                    handleDrugDosageDeselect(doseAlternative.Id)
                                  }
                                  size="large"
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              )}
                              <DosageCalculatorPopup
                                title={
                                  selectedDoseAlternative
                                    ? 'Selected'
                                    : 'Select & Calculate'
                                }
                                treatmentId={treatmentId}
                                drugSubstanceId={drugSubstanceId}
                                doseAlternative={doseAlternative}
                                diseaseName={diseaseName}
                              />
                            </Typography>
                          </ButtonTableCell>
                        )}
                        {isDiseasesAndTreatments && !isFirstLine && (
                          <ButtonTableCell style={style}>
                            <Typography
                              component="span"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={() =>
                                  selectCalculatorDoseAlternative(
                                    doseAlternative
                                  )
                                }
                              >
                                Calculator
                              </Button>
                            </Typography>
                          </ButtonTableCell>
                        )}
                        {isDrugSubstances && !isFirstLine && (
                          <ButtonTableCell style={style}>
                            <Typography
                              component="span"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={() =>
                                  selectCalculatorDoseAlternative(
                                    doseAlternative
                                  )
                                }
                              >
                                Calculator
                              </Button>
                            </Typography>
                          </ButtonTableCell>
                        )}
                      </StyledTableRow>
                    );
                  }
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
export const DrugDosageTable = ({
  treatmentId = UNDEFINED_ID,
  isInteractive = false,
  drugSubstanceInfo,
  drugSubstanceName = '',
  diseaseName = '',
  dosageInfoDefaultExpanded = false,
  isDiseasesAndTreatments = false,
  setCalculatorDoseAlternative,
  mobile,
  isDrugSubstances = false,
}: {
  treatmentId?: number;
  isInteractive?: boolean;
  drugSubstanceInfo: DrugSubstanceInfo;
  drugSubstanceName?: string;
  diseaseName?: string;
  dosageInfoDefaultExpanded?: boolean;
  isDiseasesAndTreatments?: boolean;
  setCalculatorDoseAlternative?: (doseAlternative: DoseAlternative) => void;
  mobile: boolean;
  isDrugSubstances?: boolean;
}) => {
  const {
    DrugSubstance_Doses: drugSubstance_Doses,
    DrugSubstance_Animals: drugSubstance_Animals,
  } = drugSubstanceInfo;

  const style = mobile ? { display: 'block' } : {};

  return (
    <React.Fragment>
      {drugSubstance_Doses.map((drugSubstanceDose) => (
        <React.Fragment key={drugSubstanceDose.Id}>
          <Card
            variant="outlined"
            style={{
              textAlign: 'left',
              marginTop: '1.5em',
              marginBottom: '1em',
              padding: '1em 1em 0 1em',
            }}
          >
            <TableContainer style={{ width: '100%' }}>
              <Table aria-label="Drug dosage table">
                <TableBody style={style}>
                  <TableRow style={style}>
                    <TableIndicationCell colSpan={isInteractive ? 9 : 8}>
                      <Typography
                        sx={(theme) => ({ color: theme.palette.grey[600] })}
                        style={{ fontWeight: 'bold' }}
                      >
                        {drugSubstanceName}{' '}
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {drugSubstanceDose.DrugIndication.Name}{' '}
                      </Typography>
                      <Sources
                        sources={drugSubstanceDose.Source_DrugSubstanceDoses}
                      ></Sources>
                      <Typography
                        style={{
                          display: 'inline',
                          fontWeight: 'normal',
                        }}
                      >
                        {drugSubstanceDose.DosageSpecification}
                      </Typography>
                      {isInteractive && (
                        <DrugDosageInfo
                          drugDosageInfo={drugSubstanceDose.Info}
                        />
                      )}
                      {!isInteractive && (
                        <DrugDosageInfoAccordion
                          expanded={dosageInfoDefaultExpanded}
                          drugDosageInfo={drugSubstanceDose.Info}
                        />
                      )}
                      {isDiseasesAndTreatments && (
                        <DrugSubstanceAnimalInfoAccordion
                          expanded={dosageInfoDefaultExpanded}
                          drugSubstanceAnimalInfo={drugSubstance_Animals?.Info}
                        />
                      )}
                    </TableIndicationCell>
                  </TableRow>
                  {!mobile && (
                    <TableRow>
                      <TableHeadCell>TYPE</TableHeadCell>
                      <TableHeadCell>ROUTE</TableHeadCell>
                      <TableHeadCell>DOSE</TableHeadCell>
                      <TableHeadCell></TableHeadCell>
                      <TableHeadCell>INTERVAL</TableHeadCell>
                      <TableHeadCell></TableHeadCell>
                      <TableHeadCell>DURATION</TableHeadCell>
                      <TableHeadCell></TableHeadCell>
                      {isInteractive && <TableHeadCell></TableHeadCell>}
                    </TableRow>
                  )}
                  <AlternativeRows
                    drugSubstanceDose={drugSubstanceDose}
                    treatmentId={treatmentId}
                    isInteractive={isInteractive}
                    drugSubstanceId={
                      drugSubstanceInfo.DrugSubstance_Animals.DrugSubstanceId
                    }
                    diseaseName={diseaseName}
                    mobile={mobile}
                    isDiseasesAndTreatments={isDiseasesAndTreatments}
                    setCalculatorDoseAlternative={setCalculatorDoseAlternative}
                    isDrugSubstances={isDrugSubstances}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
