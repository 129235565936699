import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, Fragment, useState } from 'react';
import { Card } from '../common/Card';
import { useParams } from 'react-router-dom';

import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useWorkContext } from '../../contexts/WorkContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import { useDosagesPage } from '../../contexts/DosagesPageContext';
import { useDoseParams } from '../../contexts/DoseParamsContext';
import { useDrugProducts } from '../../contexts/DrugProductsContext';

import {
  createPatientString,
  createDiagnoseRowString,
  createSummaryAsEmail,
} from '../../utils/summaryUtils';

import SummaryEmailDialog from './SummaryEmailDialog';
import SummarySymptomRow from './SummarySymptomRow';
import SummaryTreatmentRow from './SummaryTreatmentRow';
import Loader from '../../components/Loader';
import { SummaryFloatingActionBar } from './SummaryFloatingActionBar';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles()((theme: Theme) => ({
  summaryContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sumaryItem: {
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  summaryTopic: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const CardSummary = () => {
  const { classes } = useStyles();
  const patientInfo = usePatientInfo();
  const mobile = useMediaQuery('(max-width:600px)');

  const { getVisit, visit } = useWorkContext();
  const { diagnoses, selectedDiagnoses, selectedSymptoms } = useDiagnosesPage();

  const { visitId }: any = useParams();

  const [savingPMSSummary, setSavingPMSSummary] = useState(false);

  useEffect(() => {
    if (!visit && visitId !== 'test') {
      getVisit(visitId);
    }
  }, [visit, getVisit, visitId, patientInfo]);

  const { symptoms, categories, symptomTypes } = useSymptoms();

  const {
    selectedTreatmentsObj,
    selectedTreatments,
    selectedSubsequentDiseases,
    subsequentTreatmentsObj,
    subsequentTreatmentInfos,
    confirmedSubsequentDiseases,
  } = useTreatmentsPage();

  const { selectedDrugDosages } = useDosagesPage();
  const {
    treatmentDurationPrefixes,
    doseTypes,
    dosageIntervals,
    treatmentDurations,
    summaryDrugUnits,
    summaryResultDrugUnits,
  } = useDoseParams();

  const { drugProductsObj } = useDrugProducts();

  const showLoader =
    (!visit && visitId !== 'test') ||
    !diagnoses ||
    !selectedDiagnoses ||
    !selectedSymptoms ||
    !symptoms ||
    !categories ||
    !symptomTypes ||
    !selectedTreatments ||
    !selectedTreatments ||
    !selectedDrugDosages ||
    !treatmentDurationPrefixes ||
    !doseTypes ||
    !dosageIntervals ||
    !treatmentDurations ||
    !summaryDrugUnits ||
    !summaryResultDrugUnits ||
    !drugProductsObj;

  const diagnoseRows = diagnoses
    ? diagnoses.filter(
        (diagnose) => selectedDiagnoses.indexOf(diagnose.Id) > -1
      )
    : [];

  const subsequentConditionDiseases: string[] = subsequentTreatmentInfos
    ? selectedSubsequentDiseases.map(
        (id) =>
          `${
            subsequentTreatmentInfos[subsequentTreatmentsObj[id]].Disease.Name
          } (${
            confirmedSubsequentDiseases.includes(id) ? 'confirmed' : 'suspected'
          })`
      )
    : [];

  const symptomRows = symptoms
    ? symptoms.filter((symptom) => selectedSymptoms.indexOf(symptom.Id) > -1)
    : [];

  const getSummaryString = () => {
    return createSummaryAsEmail(
      patientInfo,
      visit?.VisitReason ? visit.VisitReason : '-',
      diagnoseRows,
      subsequentConditionDiseases,
      symptomRows,
      categories,
      symptomTypes,
      selectedTreatments,
      selectedTreatmentsObj,
      selectedDrugDosages,
      treatmentDurationPrefixes,
      summaryResultDrugUnits,
      summaryDrugUnits,
      doseTypes,
      dosageIntervals,
      treatmentDurations,
      drugProductsObj
    );
  };

  const pmsIntegration = (window as any).pmsIntegration;

  const savePMSSummary = async () => {
    if (!pmsIntegration) return;
    setSavingPMSSummary(true);
    const text = getSummaryString();
    try {
      await pmsIntegration.saveSummary(text);
    } catch (e) {
      console.error('error saving summary', e);
    } finally {
      setSavingPMSSummary(false);
    }
  };

  return (
    <Fragment>
      <Card
        title="SUMMARY"
        pageView="drawerView"
        withoutMarginBottom={!mobile}
        maxWidth
      >
        {showLoader ? (
          <Loader showLoader={true}></Loader>
        ) : (
          <Grid container className={classes.summaryContainer}>
            <Grid item xs={12} sm={12}>
              <Typography
                gutterBottom
                align="left"
                className={classes.summaryTopic}
              >
                PATIENT
              </Typography>
              <Typography
                gutterBottom
                align="left"
                className={classes.sumaryItem}
              >
                {createPatientString(patientInfo)}
              </Typography>
              <Typography
                gutterBottom
                align="left"
                className={classes.summaryTopic}
              >
                VISIT REASON
              </Typography>
              <Typography
                gutterBottom
                align="left"
                className={classes.sumaryItem}
              >
                {visitId === 'test' ? 'Test visit' : visit?.VisitReason}
              </Typography>
              <Typography
                gutterBottom
                align="left"
                className={classes.summaryTopic}
              >
                DIAGNOSES
              </Typography>
              {diagnoseRows.map((row) => {
                return (
                  <Typography
                    key={row.Id}
                    gutterBottom
                    align="left"
                    className={classes.sumaryItem}
                  >
                    {createDiagnoseRowString(row)}
                  </Typography>
                );
              })}
              {subsequentConditionDiseases.map((diseaseName) => (
                <Typography
                  key={diseaseName}
                  gutterBottom
                  align="left"
                  className={classes.sumaryItem}
                >
                  {diseaseName}
                </Typography>
              ))}
              <Typography
                gutterBottom
                align="left"
                className={classes.summaryTopic}
              >
                SYMPTOMS
              </Typography>
              {symptomRows.map((row) => {
                return (
                  <Fragment key={row.Id}>
                    <SummarySymptomRow
                      symptom={row}
                      categories={categories}
                      symptomTypes={symptomTypes}
                      mobile={mobile}
                    />
                  </Fragment>
                );
              })}
              <Typography
                gutterBottom
                align="left"
                className={classes.summaryTopic}
              >
                TREATMENTS
              </Typography>
              {selectedTreatments.map((row, index) => {
                return (
                  <Fragment key={index}>
                    <SummaryTreatmentRow
                      treatment={selectedTreatmentsObj[row]}
                      index={index}
                      selectedDrugDosages={selectedDrugDosages}
                      treatmentDurationPrefixes={treatmentDurationPrefixes}
                      doseTypes={doseTypes}
                      dosageIntervals={dosageIntervals}
                      treatmentDurations={treatmentDurations}
                      summaryDrugUnits={summaryDrugUnits}
                      summaryResultDrugUnits={summaryResultDrugUnits}
                      drugProducts={drugProductsObj}
                      mobile={mobile}
                    />
                  </Fragment>
                );
              })}
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  {pmsIntegration && (
                    <LoadingButton
                      style={{ marginTop: '16px' }}
                      variant="contained"
                      color="primary"
                      loading={savingPMSSummary}
                      onClick={savePMSSummary}
                    >
                      {pmsIntegration.saveSummaryButtonText || 'Save to PMS'}
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
      <SummaryFloatingActionBar>
        <SummaryEmailDialog getSummaryString={getSummaryString} />
      </SummaryFloatingActionBar>
    </Fragment>
  );
};
