import { arrangeSymptomsByCategory } from '../../utils/symptomsUtils';
import {
  DiagnosesPageState,
  DiagnosesPageAction,
  DiagnosesPageDefault,
} from './diagnoses.page.state';

export const diagnosesPageReducer = (
  state: DiagnosesPageState,
  action: DiagnosesPageAction
): DiagnosesPageState => {
  switch (action.type) {
    case 'setExternalSaveAndContinue': {
      return {
        ...state,
        externalSaveAndContinue: action.value,
      };
    }
    case 'addSymptom': {
      const selectedSymptomsObj = { ...state.selectedSymptomsObj };
      const selectedSymptoms = [...state.selectedSymptoms];

      if (!(action.symptomId in selectedSymptomsObj)) {
        selectedSymptomsObj[action.symptomId] = false;
        selectedSymptoms.push(action.symptomId);
      }

      return {
        ...state,
        selectedSymptomsObj,
        selectedSymptoms,
        dirty: true,
      };
    }

    case 'removeSymptom': {
      const selectedSymptomsObj = { ...state.selectedSymptomsObj };
      const selectedSymptoms = [...state.selectedSymptoms];
      const index = selectedSymptoms.indexOf(action.symptomId);
      if (index > -1) {
        delete selectedSymptomsObj[action.symptomId];
        selectedSymptoms.splice(index, 1);
      }

      if (selectedSymptoms.length === 0) {
        return {
          ...DiagnosesPageDefault,
          selectedSymptoms,
          selectedSymptomsObj,
          dirty: true,
        };
      } else {
        return {
          ...state,
          selectedSymptomsObj,
          selectedSymptoms,
          dirty: true,
        };
      }
    }

    case 'setDiagnoseSelected': {
      if (state.excludedDiagnoses.indexOf(action.diagnoseId) > -1) {
        return state;
      }

      const selectedDiagnoses = [...state.selectedDiagnoses];
      const subsequentDiagnosesObj = { ...state.subsequentDiagnosesObj };
      const index = selectedDiagnoses.indexOf(action.diagnoseId);
      let changes = false;
      if (action.checked && index === -1) {
        selectedDiagnoses.push(action.diagnoseId);
        if (
          !subsequentDiagnosesObj[action.diagnoseId] &&
          action.subsequentDiagnoses
        ) {
          subsequentDiagnosesObj[action.diagnoseId] =
            action.subsequentDiagnoses;
        }
        changes = true;
      } else if (!action.checked && index > -1) {
        selectedDiagnoses.splice(index, 1);
        if (subsequentDiagnosesObj[action.diagnoseId]) {
          delete subsequentDiagnosesObj[action.diagnoseId];
        }
        changes = true;
      }
      if (changes) {
        return {
          ...state,
          selectedDiagnoses,
          subsequentDiagnosesObj,
          dontSelect: false,
          dirty: true,
        };
      } else {
        return state;
      }
    }

    case 'toggleExcludeDiagnose': {
      if (state.dontSelect) {
        return state;
      }

      const excludedDiagnoses = [...state.excludedDiagnoses];
      const selectedDiagnoses = [...state.selectedDiagnoses];

      const index = excludedDiagnoses.indexOf(action.diagnoseId);
      if (index > -1) {
        excludedDiagnoses.splice(index, 1);
      } else {
        excludedDiagnoses.push(action.diagnoseId);

        const selectedIndex = selectedDiagnoses.indexOf(action.diagnoseId);
        if (selectedIndex > -1) {
          selectedDiagnoses.splice(selectedIndex, 1);
        }
      }

      return {
        ...state,
        excludedDiagnoses,
        selectedDiagnoses,
        dirty: true,
      };
    }

    case 'setDontSelectDiagnoses': {
      return {
        ...state,
        dontSelect: action.checked,
        excludedDiagnoses: [],
        selectedDiagnoses: [],
        dirty: true,
      };
    }

    case 'setRelevantGeographicalAreas': {
      return {
        ...state,
        relevantGeographicalAreas: action.geographicalAreas,
        dirty: true,
      };
    }

    case 'resetSelectedSymptoms': {
      return {
        ...DiagnosesPageDefault,
      };
    }

    case 'resetDiagnoses': {
      return {
        ...DiagnosesPageDefault,
        selectedSymptoms: state.selectedSymptoms,
        selectedSymptomsObj: state.selectedSymptomsObj,
        dirty: true,
      };
    }

    case 'setSymptomMandatory': {
      const selectedSymptomsObj = { ...state.selectedSymptomsObj };
      if (action.symptomId in selectedSymptomsObj) {
        selectedSymptomsObj[action.symptomId] = action.checked;
      }

      return {
        ...state,
        selectedSymptomsObj,
        dirty: true,
      };
    }

    case 'resetAll': {
      return { ...DiagnosesPageDefault };
    }

    case 'reset': {
      const newState = { ...state };
      if (newState.error) {
        delete newState.error;
      }
      return newState;
    }

    case 'request': {
      return {
        ...state,
        diagnoses: DiagnosesPageDefault.diagnoses,
        diagnosesObj: DiagnosesPageDefault.diagnosesObj,
        isLoading: true,
      };
    }

    case 'success': {
      const diagnosesObj: { [x: number]: number } = {};
      action.responseData.forEach((diagnose, index) => {
        diagnosesObj[diagnose.Id] = index;
        diagnose.categorySymptoms = arrangeSymptomsByCategory(
          action.options,
          diagnose
        );
      });

      return {
        ...DiagnosesPageDefault,
        excludedDiagnoses: state.excludedDiagnoses,
        selectedDiagnoses: state.selectedDiagnoses,
        selectedSymptoms: state.selectedSymptoms,
        selectedSymptomsObj: state.selectedSymptomsObj,
        relevantGeographicalAreas: state.relevantGeographicalAreas,
        subsequentDiagnosesObj: state.subsequentDiagnosesObj,
        diagnoses: action.responseData,
        diagnosesObj,
        dirty: true,
      };
    }

    case 'failure': {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case 'import': {
      return {
        ...action.diagnosesPage,
        isLoading: false,
        dirty: false,
      };
    }

    case 'setDirty': {
      return {
        ...state,
        dirty: action.dirty,
      };
    }

    default:
      return state;
  }
};
