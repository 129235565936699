import { UNDEFINED_ID } from '../../config/constant.params';
import {
  DrugMgPerUnit,
  DrugUnit,
  TreatmentDosages,
} from '../../types/DrugDosages.type';
import {
  CalculatedValues,
  DefaultWithDrugMgPerUnit,
  DefaultWithDrugUnit,
} from '../../types/DrugDosageTable.type';
import { RequestAction } from '../../types/request.action.type';

export type DoseInstructionValues = {
  strength: string;
  numberOfUnits: string;
  dosageInterval: string;
  treatmentDuration: string;
};

export type DoseInstructionIndexes = {
  doseTypeId: number;
  dosageIntervalId: number;
  treatmentDurationId: number;
  treatmentDurationPrefixId: number;
  summaryDrugUnitId: number;
  summaryResultDrugUnitId: number;
};

export type DoseInstruction = DoseInstructionValues & DoseInstructionIndexes;
export type DoseInstructionKeys = Extract<keyof DoseInstruction, string>;

export const DefaultDoseInstruction: DoseInstruction = {
  doseTypeId: UNDEFINED_ID,
  dosageIntervalId: UNDEFINED_ID,
  treatmentDurationId: UNDEFINED_ID,
  treatmentDurationPrefixId: UNDEFINED_ID,
  summaryDrugUnitId: 1,
  summaryResultDrugUnitId: 1,
  strength: '',
  numberOfUnits: '',
  dosageInterval: '',
  treatmentDuration: '',
};

export type CalculatedDose = {
  dosePhaseId: number;
  weight: string;
  drugAdministrationRoute: string;
  numberOfUnits: CalculatedValues<DrugMgPerUnit>;
  drugAmount: CalculatedValues<DrugMgPerUnit>;
  drugStrength: CalculatedValues<DrugUnit>;
  doseInstruction: DoseInstruction;
  drugProductId: number | null;
};

export const DefaultCalculatedDose: CalculatedDose = {
  dosePhaseId: UNDEFINED_ID,
  weight: '',
  drugAdministrationRoute: '',
  numberOfUnits: DefaultWithDrugMgPerUnit,
  drugAmount: DefaultWithDrugMgPerUnit,
  drugStrength: DefaultWithDrugUnit,
  doseInstruction: DefaultDoseInstruction,
  drugProductId: null,
};

export type DosagesPageState = {
  dirty: boolean;
  isLoading: boolean;
  externalSaveAndContinue: boolean;
  error?: string;
  treatmentDosages?: TreatmentDosages[];
  treatmentDosagesObj: { [x: number]: number };
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  };
  deletedDrugAlternative?: {
    treatmentId: number;
    drugSubstanceId: number;
    doseAlternativeId: number;
  };
};

export const DosagesPageDefault: DosagesPageState = {
  dirty: false,
  isLoading: false,
  externalSaveAndContinue: false,
  treatmentDosagesObj: {},
  selectedDrugDosages: {},
};

export type DosagesPageAction =
  | RequestAction<TreatmentDosages[]>
  | {
      type: 'import';
      dosagesPage: DosagesPageState;
    }
  | {
      type: 'setExternalSaveAndContinue';
      value: boolean;
    }
  | {
      type: 'setDrugDosageSelect';
      treatmentId: number;
      drugSubstanceId: number;
      doseAlternativeId: number;
      checked: boolean;
      calculatedDoses?: CalculatedDose[];
    }
  | {
      type: 'setDeletedDrugAlternative';
      deletedDrugAlternative: {
        treatmentId: number;
        drugSubstanceId: number;
        doseAlternativeId: number;
      };
    }
  | {
      type: 'resetDeletedDrugAlternative';
    }
  | {
      type: 'setDirty';
      dirty: boolean;
    };
