import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NoAuthUserHome } from '../components/NoAuthUserHome';
import {
  ROUTE_AUTH,
  ROUTE_INTEGRATION_AUTH,
  ROUTE_INTEGRATION_LOGIN,
  ROUTE_HOME,
  ROUTE_REGISTER,
} from '../config/routes.config';
import { IntegrationAuthentication } from '../pages/IntegrationAuthentication';
import { IntegrationLogin } from '../pages/IntegrationLogin';

import { Login } from '../pages/Login';
import { RegistrationPage } from '../pages/RegistrationPage';

export const NoAuthLayout = () => {
  return (
    <Routes>
      <Route path={ROUTE_AUTH} element={<Login />} />
      <Route
        path={ROUTE_INTEGRATION_AUTH}
        element={<IntegrationAuthentication />}
      />
      <Route path={ROUTE_INTEGRATION_LOGIN} element={<IntegrationLogin />} />
      <Route path={ROUTE_REGISTER} element={<RegistrationPage />} />
      <Route path={ROUTE_HOME} element={<NoAuthUserHome />} />
      <Route path="*" element={<Navigate to={ROUTE_HOME} replace />} />
    </Routes>
  );
};
