import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';

export type ExtractedSymptom = {
  id: number;
  reason: string;
  name: string;
  category_name: string;
  uncertain: boolean;
};

type LLMDiagnoseListPropsType = {
  symptomsLoading: boolean;
  extractedSymptoms: { [key: string]: ExtractedSymptom[] };
  selectedSymptoms: number[];
  setSelectedSymptoms: (selectedSymptoms: number[]) => void;
};

const useStyles = makeStyles<{}, 'uncertain' | 'show_uncertain'>()(
  (theme: Theme, _, classes) => {
    return {
      show_uncertain: {},
      uncertain: {
        display: 'none',
        [`&.${classes.show_uncertain}`]: {
          display: 'table-row',
        },
      },
    };
  }
);

export default function LLMDiagnoseList({
  symptomsLoading,
  extractedSymptoms,
  selectedSymptoms,
  setSelectedSymptoms,
}: LLMDiagnoseListPropsType) {
  const { classes } = useStyles({});
  const [showUncertain, setShowUncertain] = useState<{
    [key: string]: boolean;
  }>({});
  return (
    <>
      {symptomsLoading ? 'Loading...' : null}
      {!symptomsLoading && Object.keys(extractedSymptoms).length === 0 ? (
        'No symptoms found'
      ) : (
        <Table size="small">
          {Object.keys(extractedSymptoms).map((category) => (
            <TableBody key={category}>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>{category}</strong>
                </TableCell>
              </TableRow>
              {extractedSymptoms[category].map((symptom) => (
                <TableRow
                  key={symptom.id}
                  className={clsx({
                    [classes.uncertain]:
                      symptom.uncertain && !showUncertain[category],
                  })}
                >
                  <TableCell>
                    <Checkbox
                      checked={selectedSymptoms.some((s) => s === symptom.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSymptoms([
                            ...selectedSymptoms,
                            symptom.id,
                          ]);
                        } else {
                          setSelectedSymptoms(
                            selectedSymptoms.filter((s) => s !== symptom.id)
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {symptom.name}
                  </TableCell>
                  {/*<TableCell
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    <Tooltip title={symptom.reason}>
                      <Button>
                        <QuestionMarkOutlined />
                      </Button>
                    </Tooltip>
                    </TableCell>*/}
                </TableRow>
              ))}
              {!showUncertain[category] &&
                extractedSymptoms[category].some(
                  (symptom) => symptom.uncertain
                ) && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Button
                        onClick={() =>
                          setShowUncertain({
                            ...showUncertain,
                            [category]: !showUncertain[category],
                          })
                        }
                      >
                        Show all (
                        {
                          extractedSymptoms[category].filter((s) => s.uncertain)
                            .length
                        }{' '}
                        more)
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          ))}
        </Table>
      )}
    </>
  );
}
