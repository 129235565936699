import React from 'react';
import { withStyles } from 'tss-react/mui';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const HtmlTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function RequiredTooltip() {
  return (
    <HtmlTooltip
      title={
        <Typography color="inherit">
          You can e.g. mark the most important symptoms as required and more
          general/vague symptoms optional.
        </Typography>
      }
    >
      <HelpIcon
        color="primary"
        style={{ cursor: 'pointer' }}
        fontSize="inherit"
      />
    </HtmlTooltip>
  );
}
