import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ROUTE_HOME, ROUTE_REGISTER } from '../config/routes.config';
import { useAuthUser } from '../contexts/AuthUserContext';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    backgroundColor: 'white',
    maxWidth: '650px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '90%',
  },
  highlight: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const PaymentErrorPage = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const {
    user: { isAuthenticated },
  } = useAuthUser();

  return (
    <Box className={classes.box}>
      <h1>Oops!</h1>

      <h4>
        Looks like you cancelled the payment. Your payment is now halted and
        your payment data cleared.
      </h4>
      <h4>
        You can always go back to the Subscription form and restart the process.
      </h4>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(!isAuthenticated ? ROUTE_REGISTER : ROUTE_HOME);
        }}
      >
        GO BACK
      </Button>
      <p>
        Contact{' '}
        {<span className={classes.highlight}>support@gekkovet.com</span>} if
        needed.
      </p>
    </Box>
  );
};
