import React from 'react';
import { makeStyles } from 'tss-react/mui';
import DiagnoseAccordion from './DiagnoseAccordion';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
});

export default function DiagnoseAccordionList() {
  const { classes } = useStyles();
  const { diagnoses, selectedDiagnoses, diagnosesObj } = useDiagnosesPage();

  if (!diagnoses) {
    return null;
  }

  return (
    <div className={classes.root}>
      {selectedDiagnoses.map((Id) => (
        <DiagnoseAccordion
          key={Id}
          diagnose={diagnoses[diagnosesObj[Id]]}
          defaultExpanded={selectedDiagnoses.length === 1}
        />
      ))}
    </div>
  );
}
