import React, { useCallback, useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
} from '@mui/material';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { PatientInfo } from './common/PatientInfo';
import { Patient } from '../types/Patient.type';
import { useBreeds } from '../hooks/useBreeds';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientPageProvider } from '../contexts/PatientPageContext';
import { useWorkContext } from '../contexts/WorkContext';
import { usePatientListDispatch } from '../contexts/PatientListContext';

export const BottomPatientNavigation = () => {
  const patientInfo = usePatientInfo();
  const { breeds, getBreeds } = useBreeds();
  const { animalTypesObj } = useAnimalTypesContext();
  const { setPatient: setWorkPatient } = useWorkContext();
  const patientListDispatch = usePatientListDispatch();
  useEffect(() => {
    getBreeds();
  }, [getBreeds]);
  const [patientDialogVisible, setPatientDialogVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = usePatientInfoDispatch();
  const setPatient = useCallback(
    (patient: Patient, navigateToPatient = true) => {
      console.log('setting patient name to', patient.NameOrId);
      dispatch({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
      setWorkPatient(patient);
      patientListDispatch({ type: 'update', patient });
      // find breed name and dispatch
      const breed = breeds.find((breed) => breed.Id === patient.BreedId);
      if (breed) {
        dispatch({ type: 'setBreedName', breedName: breed.Name });
      }
      if (!navigateToPatient) return;
      // replace first occurence of test in url with patientId
      const replaceWith = patient.Id > 0 ? patient.Id.toString() : 'test';
      const oldUrl = location.pathname;
      // if url contains /patient/:patientId or /patient/test replace test with patientId
      const newUrl = oldUrl.replace(
        /\/patient\/(\d+|test)/,
        `/patient/${replaceWith}`
      );
      navigate(newUrl);
    },
    [
      dispatch,
      animalTypesObj,
      location,
      navigate,
      breeds,
      setWorkPatient,
      patientListDispatch,
    ]
  );
  return (
    <>
      <AppBar position="sticky" color="default" style={{ bottom: '0px' }}>
        <Grid container spacing={0}>
          <Grid item sm={12} md={6} textAlign="left">
            <Hidden smDown>
              {patientInfo.Id !== -1 && (
                <div style={{ padding: '1em' }}>
                  <Button onClick={() => setPatientDialogVisible(true)}>
                    Patient: {patientInfo.name || '(no name)'}
                  </Button>
                </div>
              )}
            </Hidden>
          </Grid>
          <Grid
            item
            container
            justifyContent={'center'}
            sm={12}
            md={6}
            style={{
              paddingTop: '1em',
              paddingBottom: '1em',
            }}
            id="patient-navigation-actions"
          ></Grid>
        </Grid>
      </AppBar>
      <Dialog
        open={patientDialogVisible}
        onClose={() => setPatientDialogVisible(false)}
      >
        <DialogTitle>Patient</DialogTitle>
        <DialogContent>
          <PatientPageProvider>
            <PatientInfo setPatient={setPatient} />
          </PatientPageProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
