import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryString } from '../hooks/useQueryString';
import { useRequestData } from '../hooks/useRequestData';
import {
  useIntegrationContext,
  useIntegrationDispatch,
} from '../contexts/IntegrationContext';
import { integrationLoginURL } from '../config/urls.noAuth';
import {
  ProvetLoginResponse,
  IntegrationData,
} from '../types/Integration.type';
import Loader from '../components/Loader';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '90%',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type IntegrationParams = {
  integrationSource?: string;
};

export const IntegrationAuthentication = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  let { integrationSource } = useParams<IntegrationParams>();

  if (integrationSource === 'provet') {
    integrationSource = 'Provet';
  }

  const queryString = useQueryString();

  const {
    integrationConnectionInfo,
    loginLink,
    isLoading: isLoadingIntegration,
    error: errorIntegration,
  } = useIntegrationContext();

  const dispatchIntegrationInfo = useIntegrationDispatch();

  const getIntegrationInfo = (integrationSource?: string) => {
    switch (integrationSource) {
      case 'Provet': {
        const integrationInfo: IntegrationData = {};

        for (const [key, value] of queryString) {
          if (value && value !== undefined) {
            integrationInfo[key] = value;
          }
        }

        return integrationInfo;
      }

      default:
        break;
    }
  };

  const integrationInfo: IntegrationData | undefined =
    getIntegrationInfo(integrationSource);

  useRequestData<ProvetLoginResponse>({
    needTransport: !integrationConnectionInfo && !isLoadingIntegration,
    dispatch: dispatchIntegrationInfo,
    url: integrationLoginURL,
    method: 'post',
    data: {
      integrationType: integrationSource,
      integrationInfo: integrationInfo,
    },
  });

  useEffect(() => {
    if (
      integrationSource === 'Provet' &&
      integrationInfo &&
      integrationConnectionInfo &&
      loginLink
    ) {
      dispatchIntegrationInfo({ type: 'setIntegrationInfo', integrationInfo });
      dispatchIntegrationInfo({
        type: 'setIntegrationSource',
        integrationSource,
      });
      navigate(loginLink);
    }
  }, [
    integrationSource,
    integrationConnectionInfo,
    loginLink,
    navigate,
    dispatchIntegrationInfo,
    integrationInfo,
  ]);

  if (isLoadingIntegration) {
    return <Loader showLoader={true}></Loader>;
  }

  return (
    <Paper className={classes.root}>
      {errorIntegration ? (
        <h3>
          Seems like you don&apos;t have a GekkoVet account linked to this
          email. Please check that you use the same email in both systems.
        </h3>
      ) : (
        <h3>Please wait while we start your login to GekkoVet.</h3>
      )}
    </Paper>
  );
};
