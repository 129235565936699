import React, { useEffect } from 'react';
import {
  CardProps,
  Container,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Card } from '../common/Card';
import { Button } from '../Button';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useLLMDiagnose } from '../../contexts/LLMDiagnoseContext';
import { AutoAwesome } from '@mui/icons-material';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import { usePatientInfoDispatch } from '../../contexts/CurrentPatientInfoContext';
import { useRWDContext } from '../../contexts/RWDContext';

const useStyles = makeStyles()((theme: Theme) => ({
  continueButton: {
    marginTop: theme.spacing(2),
  },
  infoTextAlignment: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  containerText: {},
}));

export default function LLMDiagnose({ style }: CardProps) {
  const { classes } = useStyles();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const {
    freeText,
    setFreeText,
    setExtractedSymptoms,
    setExtractedAnimal,
    setIsActiveLLMDiagnoseFlow,
  } = useLLMDiagnose();
  // clear the free text when the component is mounted
  useEffect(() => {
    setFreeText('');
  }, [setFreeText]);
  // clear selected symptoms upon mount
  useEffect(() => {
    setExtractedSymptoms({});
    setExtractedAnimal(null);
  }, []); // eslint-disable-line
  // clear active llm flow
  useEffect(() => {
    setIsActiveLLMDiagnoseFlow(false);
  }, [setIsActiveLLMDiagnoseFlow]);
  // on init, reset all diagnose data
  useEffect(() => {
    dispatchPageDiagnoses({ type: 'resetAll' });
    dispatchPatientInfo({ type: 'resetPatientInfo' });
  }, []); // eslint-disable-line
  // check if is sm screen
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { track: trackRWD } = useRWDContext();
  const rwd = () => {
    trackRWD({ actionName: 'diagnostics_start_page' });
  };
  return (
    <Card
      title="Diagnostics"
      pageView="noPadding"
      withoutMarginBottom
      headerCentered
      style={style}
    >
      <Grid container style={{ height: '100%' }} flexDirection={'column'}>
        <Grid item className={classes.infoTextAlignment}>
          <Container fixed className={classes.containerText}>
            <Typography align="center">
              Describe the patient and your findings to start diagnosing
            </Typography>
          </Container>
        </Grid>
        <Grid item flexGrow={'1'}>
          <TextField
            multiline
            rows={isSmScreen ? 10 : 15}
            value={freeText}
            style={{ height: '100%' }}
            onChange={(ev) => setFreeText(ev.target.value)}
            placeholder="For example: Bella, 6yo intact female golden retriever, has started vomiting and has diarrhea."
            fullWidth
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12} md={6} style={{ paddingTop: '1em' }}>
              <Typography align="left">
                <AutoAwesome /> AI Powered!
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} textAlign="right">
              <Button
                title="Continue"
                onClick={rwd}
                component={Link}
                to="/work/patient/test/visit/test/diagnoses"
                color="primary"
                variant="contained"
                className={classes.continueButton}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
