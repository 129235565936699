import React from 'react';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../../contexts/DosagesPageContext';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import { useRequestData } from '../../hooks/useRequestData';
import { TreatmentDosages } from '../../types/DrugDosages.type';
import { TreatmentType } from '../diseases-and-treatments/types';
import Loader from '../Loader';

import { DosageAccordion } from './DosageAccordion';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function DosageAccordionList() {
  const { AnimalTypeId } = usePatientInfo();
  const {
    selectedTreatments,
    selectedTreatmentsObj,
    selectedSubsequentDiseases,
    subsequentTreatmentsObj,
    subsequentTreatmentInfos,
  } = useTreatmentsPage();

  const { diagnoses, diagnosesObj } = useDiagnosesPage();
  const { error, isLoading, treatmentDosages } = useDosagesPage();

  const dispatchDosages = useDosagesPageDispatch();

  const drugTreatmentElements: JSX.Element[] = [];
  const drugTreatmentsIds: number[] = [];
  const treatmentsIds: number[] = [];

  const drugTreatmentCount = selectedTreatments.filter((Id) => {
    const treatment = selectedTreatmentsObj[Id];
    return treatment.Type === TreatmentType.Drug;
  }).length;

  selectedTreatments.forEach((Id) => {
    const treatment = selectedTreatmentsObj[Id];
    const isSubsequentDisease = selectedSubsequentDiseases.includes(
      treatment.AnimalType_DiseaseId
    );

    const diseaseName = isSubsequentDisease
      ? subsequentTreatmentInfos
        ? subsequentTreatmentInfos[
            subsequentTreatmentsObj[treatment.AnimalType_DiseaseId]
          ].Disease.Name
        : ''
      : diagnoses && treatment.AnimalType_DiseaseId in diagnosesObj
      ? diagnoses[diagnosesObj[treatment.AnimalType_DiseaseId]].Disease.Name
      : '';

    if (treatment.Type === TreatmentType.Drug) {
      window.dataLayer.push({
        event: 'selectedDrugs',
        selectedTreatments: treatment.Name,
      });
      if (treatmentsIds.indexOf(treatment.Id) === -1) {
        treatmentsIds.push(treatment.Id);
      }

      if (drugTreatmentsIds.indexOf(treatment.DrugSubtance.Id) === -1) {
        drugTreatmentsIds.push(treatment.DrugSubtance.Id);
      }
      drugTreatmentElements.push(
        <DosageAccordion
          key={Id}
          treatment={treatment}
          diseaseName={diseaseName}
          defaultExpanded={drugTreatmentCount === 1}
        />
      );
    }
  });

  useRequestData<TreatmentDosages[]>({
    needTransport:
      !error && !isLoading && drugTreatmentsIds.length > 0 && !treatmentDosages,
    dispatch: dispatchDosages,
    url: '/drug-substances-info',
    method: 'get',
    params: {
      drugSubstanceIds: drugTreatmentsIds.join(','),
      animalTypeId: AnimalTypeId,
      treatmentIds: treatmentsIds.join(','),
    },
  });

  if (error) {
    dispatchDosages({ type: 'reset' });
    throw error;
  }

  if (isLoading) {
    return <Loader showLoader={true} />;
  }

  if (!selectedTreatments) {
    return null;
  }

  if (drugTreatmentElements.length === 0) {
    return (
      <>
        No drug treatments have been selected. Click continue to see the visit
        summary.
      </>
    );
  }
  return <>{drugTreatmentElements}</>;
}
