import { useCallback, useEffect, useMemo } from 'react';
import { Card } from '../common/Card';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../../contexts/CurrentPatientInfoContext';
import { PatientInfo } from '../common/PatientInfo';
import { testPatients } from '../../config/testPatients';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { Alert } from '@mui/material';
import { Patient } from '../../types/Patient.type';
import { useBreeds } from '../../hooks/useBreeds';
import { PatientPageProvider } from '../../contexts/PatientPageContext';
import { useLLMDiagnose } from '../../contexts/LLMDiagnoseContext';
import Loader from '../Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { patientInfoToPatient } from '../../utils/insertPatientUtils';
import { useWorkContext } from '../../contexts/WorkContext';
import { usePatientListDispatch } from '../../contexts/PatientListContext';
import { PatientSelector } from '../PatientSelector';

export default function CardPatient() {
  const patientInfo = usePatientInfo();
  const {
    Id: testPatientId,
    AnimalTypeId,
    GenderId,
  } = useMemo(() => {
    return patientInfo;
  }, [patientInfo]);
  const { animalTypesObj } = useAnimalTypesContext();
  const { breeds, getBreeds } = useBreeds();
  const { extractedAnimal, loading, setExtractedAnimal, freeText } =
    useLLMDiagnose();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    getBreeds();
  }, [getBreeds]);
  const dispatch = usePatientInfoDispatch();
  const { setPatient: setWorkPatient } = useWorkContext();
  const dispatchPatientList = usePatientListDispatch();
  const setTestPatientId = (id: Number) => {
    console.log('setTestPatientId', id);
    // dispatch patient info with testpatient
    const testPatient = testPatients.find((patient) => patient.Id === id);
    if (!testPatient) return;
    dispatch({
      type: 'setPatientInfo',
      patient: { ...testPatient, NameOrId: '' },
      animalTypeName: animalTypesObj[testPatient.AnimalTypeId].Name,
    });
    // find breed anme and dispatch
    const breed = breeds.find((breed) => breed.Id === testPatient.BreedId);
    if (breed) {
      dispatch({ type: 'setBreedName', breedName: breed.Name });
    }
  };
  const setPatientInformation = useCallback(
    ({
      AnimalTypeId,
      GenderId,
      Neutered,
    }: {
      AnimalTypeId: number;
      GenderId: number;
      Neutered: number;
    }) => {
      dispatch({
        type: 'setPatientInfo',
        patient: {
          ...patientInfoToPatient(patientInfo),
          AnimalTypeId,
          GenderId,
          Neutered,
        },
        animalTypeName: animalTypesObj[AnimalTypeId].Name,
      });
      dispatch({
        type: 'setBreedName',
        breedName:
          breeds.find((br) => br.Id === patientInfo.breedId)?.Name || '',
      });
    },
    [dispatch, patientInfo, animalTypesObj, breeds]
  );
  const setPatient = useCallback(
    (patient: Patient, navigateToPatient = true) => {
      console.log('setting patient name to', patient.NameOrId);
      setExtractedAnimal(null);
      dispatch({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
      setWorkPatient(patient);
      dispatchPatientList({
        type: 'update',
        patient,
      });
      // find breed anme and dispatch
      const breed = breeds.find((breed) => breed.Id === patient.BreedId);
      console.log('breed', breed);
      if (breed) {
        dispatch({ type: 'setBreedName', breedName: breed.Name });
      }
      if (!navigateToPatient) return;
      // replace first occurence of test in url with patientId
      const replaceWith = patient.Id > 0 ? patient.Id.toString() : 'test';
      const oldUrl = location.pathname;
      // if url contains /patient/:patientId or /patient/test replace test with patientId
      const newUrl = oldUrl.replace(
        /\/patient\/(\d+|test)/,
        `/patient/${replaceWith}`
      );
      navigate(newUrl);
    },
    [
      dispatch,
      animalTypesObj,
      location,
      navigate,
      breeds,
      setExtractedAnimal,
      setWorkPatient,
      dispatchPatientList,
    ]
  );
  useEffect(() => {
    if (!extractedAnimal) return; // no extracted LLM animal
    console.log('using extracted animal', extractedAnimal);
    const AnimalTypeId = Number(extractedAnimal.animal_type_id);
    if (AnimalTypeId === -1) return; // LLM failed to extract animal info
    if (testPatientId > 0) return; // already using a manually set/edited patient
    const filteredBreeds = breeds.filter(
      (b) => b.AnimalTypeId === AnimalTypeId
    );

    setPatient(
      {
        Id: -2,
        AnimalTypeId,
        BreedId:
          (extractedAnimal.breed &&
            filteredBreeds.find((breed) =>
              breed.Name.toLowerCase().startsWith(
                extractedAnimal.breed.toLowerCase()
              )
            )?.Id) ||
          -1,
        GenderId:
          extractedAnimal.gender === 'male'
            ? 1
            : extractedAnimal.gender === 'female'
            ? 2
            : -1,
        Neutered: extractedAnimal.neutered ? 1 : 0,
        Weight: extractedAnimal.weight
          ? Number(extractedAnimal.weight).toString()
          : '',
        NameOrId: extractedAnimal.name || '',
        PetOwnerId: -1,
        Identifier: '',
        DateOfBirth:
          extractedAnimal.date_of_birth &&
          moment(extractedAnimal.date_of_birth, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
          ),
      },
      false
    );
  }, [extractedAnimal, testPatientId, setPatient, breeds]);

  const hasNecessaryPatientInfo = testPatientId !== -1 && GenderId !== -1;

  return (
    <PatientPageProvider>
      <Loader showLoader={testPatientId === -1 && loading} />
      <Card title="PATIENT" pageView="noPadding" maxWidth withoutMarginBottom>
        {AnimalTypeId === -1 &&
          Number(extractedAnimal?.animal_type_id) === -1 &&
          freeText && (
            <Alert severity="info">
              Could not extract the patient information from the text. Please
              select the patient type.
            </Alert>
          )}
        {patientInfo.Id === -2 && (
          <>
            <Alert severity="info">
              The signalment is extracted by AI, please review the information.
            </Alert>
            <div style={{ margin: '1em' }}>&nbsp;</div>
          </>
        )}
        {hasNecessaryPatientInfo && <PatientInfo setPatient={setPatient} />}
        {!hasNecessaryPatientInfo && (
          <PatientSelector
            setTestPatientId={setTestPatientId}
            setPatient={setPatient}
            setInformation={setPatientInformation}
            initialInformation={{
              AnimalTypeId,
              GenderId,
              TestPatientId: testPatientId,
            }}
          />
        )}
      </Card>
    </PatientPageProvider>
  );
}
