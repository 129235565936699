import { createContext, useCallback, useContext, useState } from 'react';

type ConfirmationContextType = {
  active: boolean;
  setActive: (active: boolean) => void;
  message: string;
  buttons: {
    yes: string;
    no: string;
  };
  onConfirm: (result: boolean) => void;
  confirmDialog: (
    message: string,
    buttons: { yes: string; no: string },
    onConfirm: (result: boolean) => void
  ) => void;
};
const ConfirmationContext = createContext<ConfirmationContextType | undefined>({
  active: false,
  setActive: () => {},
  message: '',
  buttons: {
    yes: '',
    no: '',
  },
  onConfirm: () => {},
  confirmDialog: () => {},
});

export const ConfirmationProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState('');
  const [buttons, setButtons] = useState({
    yes: '',
    no: '',
  });
  const [onConfirm, setOnConfirm] = useState<(result: boolean) => void | null>(
    () => {}
  );

  const confirmDialog = useCallback(
    (
      message: string,
      buttons: { yes: string; no: string },
      onConfirm: (result: boolean) => void
    ) => {
      setMessage(message);
      setButtons(buttons);
      setActive(true);
      setOnConfirm(() => onConfirm);
    },
    [setMessage, setButtons, setActive, setOnConfirm]
  );

  const value = {
    active,
    setActive,
    message,
    buttons,
    confirmDialog,
    onConfirm,
  };

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmationContext = () => {
  const context = useContext(ConfirmationContext);
  if (context === undefined) {
    throw new Error(
      'useConfirmationContext must be used within a ConfirmationProvider'
    );
  }
  return context;
};
