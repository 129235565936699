import { useCallback, useState } from 'react';
import API from '../api/Api';
import { Patient, IntegrationPatient } from '../types/Patient.type';
import { PatientVisit, IntegrationVisit } from '../types/PatientVisit.type';
import { Breed } from '../types/Breed.type';
import { AnimalType } from '../types/AnimalType.type';
import { Diagnose } from '../types/Diagnose.type';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  IntegrationData,
  ProvetIntegrationInfo,
} from '../types/Integration.type';
import {
  ROUTE_CURRENT_VISIT_SAVED,
  ROUTE_NEW_VISIT,
} from '../config/routes.config';
import { useAuthUser } from '../contexts/AuthUserContext';

export const useWork = () => {
  const [patient, setPatient] = useState<Patient | null>(null);
  const { signOut } = useAuthUser();
  const navigate = useNavigate();
  const [integrationPatient, setIntegrationPatient] =
    useState<IntegrationPatient | null>(null);
  const [integrationVisit, setIntegrationVisit] =
    useState<IntegrationVisit | null>(null);
  const [diagnoses, setDiagnoses] = useState<Diagnose[]>([]);
  const [visit, setVisit] = useState<PatientVisit | null>(null);
  const [breed, setBreed] = useState(null);
  const [animalType, setAnimalType] = useState(null);

  const getPatient = useCallback(
    async (id: number) => {
      const patientResponse: any = await API.getModel({
        modelName: 'Patients',
        id,
      });
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        patientResponse.status === 404 &&
        patientResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        setPatient(patientResponse.body[0]);
      }
    },
    [signOut]
  );

  const getIntegrationPatient = useCallback(
    async (
      integrationSource: string,
      integrationConnectionInfo: ProvetIntegrationInfo,
      integrationInfo: IntegrationData
    ) => {
      const patientResponse: any = await API.getIntegrationPatient(
        integrationSource,
        integrationConnectionInfo,
        integrationInfo
      );
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        patientResponse.status === 404 &&
        patientResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        const patientData = patientResponse.body;

        if (patientData.Id) {
          setPatient(patientData);
          const path = generatePath(ROUTE_NEW_VISIT, {
            patientId: String(patientData.Id),
          });

          navigate(path);
        } else {
          setIntegrationPatient(patientResponse.body);
        }
      }
    },
    [navigate, signOut]
  );

  const getVisit = useCallback(
    async (id: number) => {
      const visitResponse: any = await API.getModel({
        modelName: 'PatientVisits',
        id,
      });
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        visitResponse.status === 404 &&
        visitResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        setVisit(visitResponse.body[0]);
      }
    },
    [signOut]
  );

  const getIntegrationVisit = useCallback(
    async (
      integrationSource: string,
      integrationConnectionInfo: ProvetIntegrationInfo,
      integrationInfo: IntegrationData
    ) => {
      const visitResponse: any = await API.getIntegrationVisit(
        integrationSource,
        integrationConnectionInfo,
        integrationInfo
      );
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        visitResponse.status === 404 &&
        visitResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        const visitData = visitResponse.body;

        if (visitData.Id) {
          setVisit(visitData);
          const path = generatePath(ROUTE_CURRENT_VISIT_SAVED, {
            patientId: visitData.PatientId.toString(),
            visitId: visitData.Id.toString(),
          });
          navigate(path);
        } else {
          setIntegrationVisit(visitResponse.body);
        }
      }
    },
    [navigate, signOut]
  );

  const getBreed = useCallback(
    async (id: number) => {
      const breedResponse: any = await API.getModel({
        modelName: 'Breeds',
        id,
      });
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        breedResponse.status === 404 &&
        breedResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        setBreed(breedResponse.body[0]);
      }
    },
    [signOut]
  );

  const getAnimalType = useCallback(
    async (id: number) => {
      const animalTypeResponse: any = await API.getModel({
        modelName: 'AnimalTypes',
        IsVisible: 1,
        id,
      });
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        animalTypeResponse.status === 404 &&
        animalTypeResponse.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        setAnimalType(animalTypeResponse.body[0]);
      }
    },
    [signOut]
  );

  const setPatientState = useCallback((patientValue: Patient) => {
    setPatient(patientValue);
  }, []);

  return {
    patient: patient as Patient | null,
    integrationPatient: integrationPatient as IntegrationPatient | null,
    setPatient: setPatientState,
    getPatient,
    getIntegrationPatient,
    visit: visit as PatientVisit | null,
    integrationVisit: integrationVisit as IntegrationVisit | null,
    setVisit: setVisit,
    getIntegrationVisit,
    getVisit,
    breed: breed as Breed | null,
    getBreed,
    animalType: animalType as AnimalType | null,
    getAnimalType,
    setDiagnoses,
    diagnoses,
  };
};
