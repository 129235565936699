import { UNDEFINED_ID } from '../../config/constant.params';
import { Breed } from '../../types/Breed.type';
import { Patient, TestPatient } from '../../types/Patient.type';
import { RequestAction } from '../../types/request.action.type';

export type CurrentPatientInfoState = {
  Id: number;
  identifier: string;
  AnimalTypeId: number;
  breedId: number;
  name: string;
  breedName: string;
  animalTypeName: string;
  neutered: number;
  genderName: string;
  patientAge: string;
  dateOfBirth: string | null;
  weight: string;
  weightStr: string;
  isLoading: boolean;
  error?: string;
  GenderId: number;
};

export const CurrentPatientInfoDefault: CurrentPatientInfoState = {
  Id: UNDEFINED_ID,
  identifier: '',
  AnimalTypeId: UNDEFINED_ID,
  breedId: UNDEFINED_ID,
  name: '',
  animalTypeName: '',
  breedName: '',
  genderName: '',
  neutered: 0,
  patientAge: '',
  dateOfBirth: '',
  weight: '',
  weightStr: '',
  isLoading: false,
  GenderId: 1,
};

export type CurrentPatientInfoAction =
  | RequestAction<Breed[]>
  | {
      type: 'setPatientInfo';
      patient: Patient | TestPatient;
      animalTypeName: string;
    }
  | {
      type: 'setBreedName';
      breedName: string;
    }
  | {
      type: 'resetPatientInfo';
    };
