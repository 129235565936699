import React, { useEffect, useState } from 'react';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_VISIT_DOSAGES } from '../../config/routes.config';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { useRWDContext } from '../../contexts/RWDContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import {
  getRWDtreatments,
  getRWDdiagnosesMethod3,
  getRWDsymptoms,
  getRWDage,
} from '../../utils/rwdUtils';
import { FloatingActionBar } from '../diagnoses/FloatingActionBar';

export const TreatmentsFloatingActionBar = () => {
  const { exportState } = useWorkHistory();
  const [showLoader, setShowLoader] = useState(false);
  const dispatchDosages = useDosagesPageDispatch();
  const treatmentsPageDispatch = useTreatmentsPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();
  const navigate = useNavigate();
  const [selectedTreatmentsVisible, setSelectedTreatmentsVisible] =
    useState(false);

  // extract patientId and visitId from current path
  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();

  // variables for RWD
  const { track: trackRWD } = useRWDContext();
  const {
    selectedTreatmentsObj,
    selectedTreatments,
    selectedSubsequentDiseases,
    subsequentTreatmentInfos,
    externalSaveAndContinue,
  } = useTreatmentsPage();
  const {
    diagnoses,
    selectedSymptoms,
    selectedSymptomsObj,
    selectedDiagnoses,
  } = useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const patientInfo = usePatientInfo();
  // end of variables for RWD

  useEffect(() => {
    if (externalSaveAndContinue) {
      treatmentsPageDispatch({
        type: 'setExternalSaveAndContinue',
        value: false,
      });
      handleSaveAndContinue();
    }
  }, [externalSaveAndContinue]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSave = async (force: boolean) => {
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (force || (visitId && visitId !== 'test')) {
      setShowLoader(true);
      await exportState();
      setShowLoader(false);
    }
    // Start of RWD functionality
    if (selectedTreatments.length > 0) {
      const arrayOfTreatments = getRWDtreatments(
        selectedTreatments,
        selectedTreatmentsObj,
        diagnoses
      );
      const arrayOfDiagnoses = getRWDdiagnosesMethod3(
        selectedDiagnoses,
        diagnoses,
        selectedSubsequentDiseases,
        subsequentTreatmentInfos
      );
      const arrayOfSymptoms = getRWDsymptoms(
        selectedSymptoms,
        symptoms,
        selectedSymptomsObj
      );
      const age = getRWDage(patientInfo.dateOfBirth);
      trackRWD({
        actionName: 'selected_treatments_diagnosis',
        treatments: arrayOfTreatments,
        diseases: arrayOfDiagnoses,
        symptoms: arrayOfSymptoms,
        signalmentSpecies:
          patientInfo.animalTypeName[0].toUpperCase() +
          patientInfo.animalTypeName.slice(1),
        signalmentBreed:
          patientInfo.breedName === '' ? undefined : patientInfo.breedName,
        signalmentWeight: +patientInfo.weight,
        signalmentGender: patientInfo.genderName,
        signalmentAge: age,
        signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
        signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
      });
    }
    // End of RWD functionality
  };

  const handleSaveAndContinue = async () => {
    await handleSave(false);

    if (!patientId || !visitId) return;

    const path = generatePath(ROUTE_VISIT_DOSAGES, {
      patientId,
      visitId,
    });
    navigate(path);
  };

  return (
    <FloatingActionBar
      showLoader={showLoader}
      handleSave={handleSave}
      onContinue={handleSaveAndContinue}
      continueDisabled={selectedTreatments.length === 0}
      continueDisabledMessage="You need to select at least one treatment protocol to proceed."
    >
      <Button variant="text" onClick={() => setSelectedTreatmentsVisible(true)}>
        {selectedTreatments.length} treatment(s) selected
      </Button>
      <Dialog
        open={selectedTreatmentsVisible}
        onClose={() => setSelectedTreatmentsVisible(false)}
      >
        <DialogTitle>Selected treatments</DialogTitle>
        <DialogContent>
          {selectedTreatments.length === 0 && (
            <p>
              You need to select at least one treatment protocol for at least
              one diagnose to proceed.
            </p>
          )}
          <ul>
            {selectedTreatments.map((treatmentId) => {
              const treatment = selectedTreatmentsObj[treatmentId];
              return <li key={treatmentId}>{treatment?.Name}</li>;
            })}
          </ul>
        </DialogContent>
      </Dialog>
    </FloatingActionBar>
  );
};
