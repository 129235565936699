import { Button, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { Card } from './common/Card';
import { SpeciesSelect } from './common/SpeciesSelect';
import Loader from './Loader';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { AnimalType } from '../types/AnimalType.type';
import { DrugSubstance, DrugSubstanceInfo } from '../types/DrugDosages.type';
import { DrugSubstanceComboBox } from './DrugDosageSearch/DrugSubstanceComboBox';
import { DrugSubstanceSearchResults } from './DrugDosageSearch/DrugSubstanceSearchResults';
import API from '../api/Api';
import { useRWDContext } from '../contexts/RWDContext';

export const DrugSubstances = ({
  getDrugSubstanceInfo,
  getDrugSubstances,
  drugSubstanceInfo,
  drugSubstances,
  setIsSearchLoading,
  isSearchLoading,
}: {
  getDrugSubstanceInfo: (animalTypeId: string, drugSubstanceId: string) => {};
  getDrugSubstances: (animalTypeId: string) => {};
  drugSubstanceInfo: DrugSubstanceInfo | null;
  drugSubstances: DrugSubstance[];
  setIsSearchLoading: (isLoading: boolean) => void;
  isSearchLoading: boolean;
}) => {
  const { animalTypesObj } = useAnimalTypesContext();
  const { track: trackRWD } = useRWDContext();

  const mobile = useMediaQuery('(max-width:600px)');

  const [searchAnimalType, setSearchAnimalType] = useState<AnimalType | null>(
    null
  );
  const [searchDrugSubstanceValue, setSearchDrugSubstanceValue] = useState('');

  const [selectedSpecies, setSelectedSpecies] = useState(-1);

  const [drugSubstanceInputValue, setDrugSubstanceInputValue] = useState('');
  const [drugSubstanceValue, setDrugSubstanceValue] = useState(-1);

  const handleDrugSubstanceInputValueChange = (value: string) => {
    setDrugSubstanceInputValue(value);
  };

  const handleDrugSubstanceValueChange = (value: number) => {
    setDrugSubstanceValue(value);
  };

  useEffect(() => {
    getDrugSubstances(selectedSpecies > -1 ? String(selectedSpecies) : '');
  }, [selectedSpecies, getDrugSubstances]);

  const handleRWDtrigger = (value: any) => {
    const species = animalTypesObj[selectedSpecies];
    if (value.dosageCalculationMethod) {
      trackRWD({
        actionName: 'calculated_dosage_drug',
        signalmentSpecies: species.Name,
        signalmentWeight: value.signalmentWeight,
        diseases: [
          {
            name: value.diseaseName,
          },
        ],
        treatments: [
          {
            name: drugSubstanceInputValue,
            type: 'Drug',
          },
        ],
        dosages: [
          {
            strength: value.dosageStrength,
            strengthUnit: value.dosageStrengthUnit,
            amount: value.dosageAmount,
            amountUnit: value.dosageAmountUnit,
            doseType: value.dosageDoseType,
          },
        ],
      });
    } else {
      trackRWD({
        actionName: value.actionName,
        signalmentSpecies: species.Name,
        treatments: [
          {
            name: drugSubstanceInputValue,
            type: 'Drug',
          },
        ],
      });
    }
  };

  const onSearchClick = () => {
    setIsSearchLoading(true);
    getDrugSubstanceInfo(String(selectedSpecies), String(drugSubstanceValue));
    // Save these to show in title until new search is performed
    let searchAnimalType = '',
      searchDrugSubstanceValue = '';
    if (selectedSpecies !== -1) {
      const animalType = animalTypesObj[selectedSpecies];
      setSearchAnimalType(animalType);
      searchAnimalType = animalType.Name;
    }
    if (drugSubstanceInputValue !== '') {
      setSearchDrugSubstanceValue(drugSubstanceInputValue);
      searchDrugSubstanceValue = drugSubstanceInputValue;
    }
    handleRWDtrigger({
      actionName: 'searched_drug_substance',
    });
    API.trackEvent(
      'drug_substances_search_submit',
      `${searchAnimalType};${searchDrugSubstanceValue}`
    );
  };

  return (
    <div>
      <Loader showLoader={isSearchLoading} />
      <Card
        pageView={mobile ? 'mobileView' : 'drawerView'}
        title="SEARCH DRUG SUBSTANCES"
      >
        <Grid
          container
          spacing={2}
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: 'left',
                  marginBottom: '24px',
                }}
              >
                Choose animal species first to search for drug substances
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SpeciesSelect
                error={false}
                species={selectedSpecies}
                handleChange={(event: any) => {
                  setSelectedSpecies(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <DrugSubstanceComboBox
                handleChange={handleDrugSubstanceValueChange}
                drugSubstances={drugSubstances}
                disabled={false}
                value={drugSubstanceValue}
                inputValue={drugSubstanceInputValue}
                handleOnInputValueChange={handleDrugSubstanceInputValueChange}
              ></DrugSubstanceComboBox>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'left', marginBottom: '32px' }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={selectedSpecies === -1 || drugSubstanceValue === -1}
              onClick={onSearchClick}
            >
              SEARCH
            </Button>
          </Grid>
          {drugSubstanceInfo && searchAnimalType && (
            <DrugSubstanceSearchResults
              drugSubstanceInfo={drugSubstanceInfo}
              searchAnimalType={searchAnimalType}
              searchDrugSubstanceValue={searchDrugSubstanceValue}
              handleRWDtrigger={handleRWDtrigger}
            />
          )}
        </Grid>
      </Card>
    </div>
  );
};
