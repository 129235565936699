import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PatientNavigation } from '../components/PatientNavigation';
import { AllPatientsPage } from '../pages/AllPatientsPage';
import { VisitsPage } from '../pages/VisitsPage';
import { PatientRouter } from './PatientRouter';
import { PatientSummaryPage } from '../pages/PatientSummaryPage';
import DiagnoseHomePage from '../pages/DiagnoseHomePage';

export const WorkRouter = () => {
  return (
    <>
      <PatientNavigation />
      <Routes>
        <Route
          path="patient-summary/:patientId"
          element={<PatientSummaryPage />}
        />
        <Route path="patients" element={<AllPatientsPage />} />
        <Route path="visits" element={<VisitsPage />} />
        <Route path="diagnosehome" element={<DiagnoseHomePage />} />
        <Route path={`patient/*`} element={<PatientRouter />} />
      </Routes>
    </>
  );
};
