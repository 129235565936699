import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { VisitCreatedPage } from '../pages/VisitCreatedPage';
import { VisitPage } from '../pages/VisitPage';
import { DiagnoseRouter } from './DiagnoseRouter';

export const VisitRouter = () => {
  useEffect(() => {
    return () => {
      console.log('unmounting VisitRouter');
    };
  }, []);
  return (
    <Routes>
      <Route path="/" element={<VisitPage />} />
      <Route path=":visitId" element={<VisitPage />} />
      <Route path=":visitId/saved" element={<VisitCreatedPage />} />
      <Route path=":visitId/diagnoses/*" element={<DiagnoseRouter />} />
    </Routes>
  );
};
