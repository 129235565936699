import React, { useEffect, useState } from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import DiagnoseAccordionList from './DiagnoseAccordionList';
import { useSymptoms } from '../../contexts/SymptomsContext';
import API from '../../api/Api';
import { DiagnosesFilterOptions } from '../../pages/DiagnosesPage';
import { CardSearchiltersContainer } from '../symptoms/CardSearchFiltersContainer';

const useStyles = makeStyles()((theme: Theme) => ({
  subtitle: {
    marginBottom: 0,
  },
  root: { minWidth: '50%' },
  diagnosesContainer: {
    width: '100%',
  },
}));

export default function CardDiagnoses({
  highlightBreedId,
  updateDifferentials,
  setDiagnosesFilterOptions,
  diagnoseFilterInitialState,
}: {
  highlightBreedId?: number | undefined;
  updateDifferentials: () => void;
  setDiagnosesFilterOptions: (value: DiagnosesFilterOptions) => void;
  diagnoseFilterInitialState: DiagnosesFilterOptions;
}) {
  const { classes } = useStyles();
  const { diagnoses, selectedSymptoms, isLoading, selectedSymptomsObj } =
    useDiagnosesPage();
  const { symptoms } = useSymptoms();
  const theme = useTheme();
  const [skipCount, setSkipCount] = useState(true);

  const handleDifferentialSearch = () => {
    if (selectedSymptoms.length === 0) return;
    updateDifferentials();
    window.dataLayer.push({
      event: 'addedSymptoms',
      symptomsTotal: selectedSymptoms.length,
    });

    (async () => {
      for (const symptomId of selectedSymptoms) {
        const symptom = symptoms.find(({ Id }) => Id === symptomId);
        if (!symptom) return;
        const main = symptom.MainSymptomId
          ? symptoms.find(({ Id }) => Id === symptom.MainSymptomId)
          : null;
        const symptomName = main
          ? `${main.Name}, ${symptom.Name}`
          : symptom.Name;
        API.trackEvent('added_symptom', symptomName);
        await new Promise((resolve) => setTimeout(resolve, 20000));
      }
    })();
  };

  useEffect(() => {
    if (skipCount) setSkipCount(false);
    if (!skipCount) handleDifferentialSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnoseFilterInitialState, selectedSymptomsObj]);

  if (selectedSymptoms.length === 0)
    return (
      <div className={classes.root}>
        <Typography align="center">
          Select symptoms to search for possible diagnoses
        </Typography>
      </div>
    );

  const renderContent = () => {
    if (isLoading)
      return (
        <div className={classes.root}>
          <Typography align="center">
            <CircularProgress size={48} />
          </Typography>
        </div>
      );

    return (
      <>
        {diagnoses && diagnoses.length === 0 && (
          <Typography align="center">
            No potential diagnoses found. Try marking only the most relevant
            symptoms as &quot;required&quot; or selecting
            &quot;unspecified&quot; symptom instead of a more detailed symptom
            to get more potential diagnoses.
          </Typography>
        )}
        {diagnoses && diagnoses?.length > 0 && (
          <Grid container className={classes.diagnosesContainer}>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <Typography variant="h4">
                {diagnoses.length} potential diagnose(s) found.
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Use this list to find out more about the potential diagnoses.
                After expanding the diagnose, you can also see the symptoms that
                are related to the diagnose and add them to the list of selected
                symptoms. You need to select at least one diagnose to be able to
                continue to treatment options.
              </Typography>
              {diagnoses.length === 100 ? (
                <Alert severity="info" style={{ marginTop: '1em' }}>
                  Showing 100 most probable diagnoses. You can use the filters
                  to see the most relevant diagnoses.
                </Alert>
              ) : null}
              {diagnoses.length > 10 && (
                <Alert severity="info" style={{ marginTop: '1em' }}>
                  Hint! You can reduce the amount of potential diagnoses by
                  marking only the most relevant symptoms as
                  &quot;required&quot;
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <DiagnoseAccordionList
                highlightBreedId={highlightBreedId}
                updateDifferentials={updateDifferentials}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4} xs={12} padding={1}>
          <Typography variant="h4">Search filters</Typography>
          <CardSearchiltersContainer
            initialState={diagnoseFilterInitialState}
            setDiagnosesFilterOptions={setDiagnosesFilterOptions}
            layoutDirection="column"
          />
        </Grid>
        <Grid
          padding={1}
          item
          md={8}
          xs={12}
          sx={{ borderLeft: 1, borderColor: theme.palette.grey[300] }}
        >
          {renderContent()}
        </Grid>
      </Grid>
    </div>
  );
}
