import React, { PropsWithChildren } from 'react';
import { AnimalTypesProvider } from './AnimalTypesContext';
import { DoseParamsProvider } from './DoseParamsContext';
import { GAListProvider } from './GeographicalAreasContext';
import { IntegrationProvider } from './IntegrationContext';
import { SymptomsProvider } from './SymptomsContext';
import { UiStateProvider } from './uiState.context';
import { BreedsProvider } from './BreedsContext';
import { LLMDiagnoseProvider } from './LLMDiagnoseContext';

export const AppParamsProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <UiStateProvider>
      <AnimalTypesProvider>
        <SymptomsProvider>
          <GAListProvider>
            <DoseParamsProvider>
              <LLMDiagnoseProvider>
                <IntegrationProvider>
                  <BreedsProvider>{children}</BreedsProvider>
                </IntegrationProvider>
              </LLMDiagnoseProvider>
            </DoseParamsProvider>
          </GAListProvider>
        </SymptomsProvider>
      </AnimalTypesProvider>
    </UiStateProvider>
  );
};
