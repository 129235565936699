import { useEffect, useState } from 'react';
import { Patient } from '../types/Patient.type';
import { useLLMDiagnose } from '../contexts/LLMDiagnoseContext';
import { testPatients } from '../config/testPatients';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Search, Male, Female } from '@mui/icons-material';
import CatIcon from '../assets/icons/CatIcon';
import DogIcon from '../assets/icons/DogIcon';
import { ListAllPatientsContainer } from './all-patients/ListAllPatientsContainer';

export const PatientSelector = ({
  setTestPatientId,
  setPatient,
  initialInformation,
  setInformation,
}: {
  setTestPatientId: (id: number) => void;
  setPatient: (patient: Patient) => void;
  initialInformation: {
    TestPatientId: number;
    AnimalTypeId: number;
    GenderId: number;
  };
  setInformation: (info: {
    AnimalTypeId: number;
    GenderId: number;
    Neutered: number;
  }) => void;
}) => {
  const [animalTypeId, setAnimalTypeId] = useState<number | null>(null);
  const [genderId, setGenderId] = useState<number | null>(null);
  const [neutered, setNeutered] = useState<number | null>(null);
  const [patientDialogOpen, setPatientDialogOpen] = useState(false);
  const buttonCSS: (
    variant: 'contained' | 'outlined',
    disablePointerEvents?: boolean
  ) => React.CSSProperties = (
    variant: 'contained' | 'outlined',
    disablePointerEvents = false
  ) => ({
    flexDirection: 'column',
    color: variant === 'contained' ? undefined : '#333',
    pointerEvents: disablePointerEvents ? 'none' : undefined,
    margin: '1em',
    minWidth: '8em',
  });

  useEffect(() => {
    if (initialInformation.TestPatientId === -1) return;
    if (initialInformation.AnimalTypeId !== -1) {
      setAnimalTypeId(initialInformation.AnimalTypeId);
    } else {
      setAnimalTypeId(null);
    }
    if (initialInformation.GenderId !== -1) {
      setGenderId(initialInformation.GenderId);
    } else {
      setGenderId(null);
    }
  }, [initialInformation]);

  const { freeText, extractedAnimal } = useLLMDiagnose();

  useEffect(() => {
    if (animalTypeId && genderId && neutered !== null) {
      if (initialInformation.TestPatientId !== -1) {
        // Something else than test patient
        return setInformation({
          AnimalTypeId: animalTypeId,
          GenderId: genderId,
          Neutered: neutered,
        });
      }
      const testPatient = testPatients.find(
        (patient) =>
          patient.AnimalTypeId === animalTypeId &&
          patient.GenderId === genderId &&
          patient.Neutered === neutered
      );
      if (testPatient) {
        setTestPatientId(testPatient.Id);
      }
    }
  }, [
    animalTypeId,
    genderId,
    neutered,
    setTestPatientId,
    setInformation,
    initialInformation,
  ]);

  console.log('animalTypeId', animalTypeId);
  console.log('extractedAnimal', extractedAnimal);
  console.log('freeText', freeText);

  const rootStyle: React.CSSProperties = {
    height: '13em',
    overflowY: 'hidden',
  };

  const containerStyle: (which: string) => React.CSSProperties = (which) => {
    switch (which) {
      case 'animaltype':
        return {
          opacity: animalTypeId !== null ? 0.3 : 1,
          marginTop:
            animalTypeId === null
              ? '3.8em'
              : genderId === null
              ? '-3.5em'
              : neutered === null
              ? '-11em'
              : '-15em',
          // animate marginTop
          transition: 'margin-top 0.5s',
        };
      case 'gender':
        return {
          opacity: genderId !== null ? 0.5 : 1,
        };
      case 'neutered':
        return {
          opacity: neutered !== null ? 0.5 : 1,
        };
      default:
        return {};
    }
  };

  return (
    <div style={rootStyle}>
      <Grid container spacing={2} style={containerStyle('animaltype')}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Select patient type</Typography>
          <Button
            variant={animalTypeId === 2 ? 'contained' : 'outlined'}
            size="large"
            style={buttonCSS(
              animalTypeId === 2 ? 'contained' : 'outlined',
              animalTypeId !== null
            )}
            onClick={() => setAnimalTypeId(2)}
          >
            <DogIcon fontSize="large" />
            <Typography variant="h6">Dog</Typography>
          </Button>
          <Button
            variant={animalTypeId === 1 ? 'contained' : 'outlined'}
            size="large"
            style={buttonCSS(
              animalTypeId === 1 ? 'contained' : 'outlined',
              animalTypeId !== null
            )}
            onClick={() => setAnimalTypeId(1)}
          >
            <CatIcon fontSize="large" />
            <Typography variant="h6">Cat</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          {animalTypeId === null && (
            <>
              <Typography variant="h6">Use existing patient</Typography>
              <Button
                variant="outlined"
                size="large"
                style={buttonCSS('outlined')}
                onClick={() => setPatientDialogOpen(true)}
              >
                <Search fontSize="large" />
                <Typography variant="h6">Search</Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={containerStyle('gender')}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Select gender</Typography>
          <Button
            variant={genderId === 1 ? 'contained' : 'outlined'}
            size="large"
            style={buttonCSS(
              genderId === 1 ? 'contained' : 'outlined',
              genderId !== null
            )}
            onClick={() => setGenderId(1)}
          >
            <Male fontSize="large" />
            <Typography variant="h6">Male</Typography>
          </Button>
          <Button
            variant={genderId === 2 ? 'contained' : 'outlined'}
            size="large"
            style={buttonCSS(
              genderId === 2 ? 'contained' : 'outlined',
              genderId !== null
            )}
            onClick={() => setGenderId(2)}
          >
            <Female fontSize="large" />
            <Typography variant="h6">Female</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Is the animal neutered?</Typography>
          <Button
            variant="outlined"
            size="large"
            style={buttonCSS('outlined')}
            onClick={() => setNeutered(0)}
          >
            <Typography variant="h6">Intact</Typography>
          </Button>
          <Button
            variant="outlined"
            size="large"
            style={buttonCSS('outlined')}
            onClick={() => setNeutered(1)}
          >
            <Typography variant="h6">Neutered</Typography>
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={patientDialogOpen}
        onClose={() => setPatientDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Select patient</DialogTitle>
        <DialogContent>
          <ListAllPatientsContainer isSelect={true} onSelect={setPatient} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPatientDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
