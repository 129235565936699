import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/gekko_logo.png';
import eyeLogo from '../../assets/images/gekko_eye_logo.png';
import { ROUTE_HOME, ROUTE_PAYMENT } from '../../config/routes.config';
import { useAuthUser } from '../../contexts/AuthUserContext';
import UserMenu from './UserMenu';
import { useVeterinarian } from '../../contexts/VeterinarianContext';
import { useSubscriptionContext } from '../../contexts/SubscriptionContext';
import { calcDifferenceToToday } from '../../utils/dateUtils';
import { Button } from '../Button';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoButton: {
    marginRight: theme.spacing(2),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  subAlertText: {
    padding: theme.spacing(1),
    fontSize: 12,
  },
  subAlertButton: {
    color: 'white',
    borderColor: 'white',
    borderWidth: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  subAlertButtonNoPadding: {
    color: 'white',
    borderColor: 'white',
    borderWidth: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    padding: 0,
  },
  logoBox: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
  },
  eyeMenuButton: {
    width: '34px',
    margin: '0 0 0 2px',
  },
}));

export const Header = ({ toggleNavOpen }: { toggleNavOpen: () => void }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const {
    user: { isAuthenticated },
  } = useAuthUser();
  const { veterinarian } = useVeterinarian();
  const { subscription } = useSubscriptionContext();
  const mobile = useMediaQuery('(max-width:960px)');
  const extraSmall = useMediaQuery('(max-width:415px)');

  const getSubscriptionButtonText = () => {
    if (!mobile) {
      if (calcDifferenceToToday(subscription?.validUntil) === 0) {
        return 'Last day of your trial subscription - SUBSCRIBE';
      }
      if (calcDifferenceToToday(subscription?.validUntil) < 0) {
        return 'Trial subscription has ended - SUBSCRIBE';
      }
      return `${
        calcDifferenceToToday(subscription?.validUntil) + 1
      } Days left of
      your trial subscription - SUBSCRIBE`;
    } else {
      if (calcDifferenceToToday(subscription?.validUntil) === 0) {
        return 'Last trial day - SUBSCRIBE';
      }
      if (calcDifferenceToToday(subscription?.validUntil) < 0) {
        return 'Trial has ended - SUBSCRIBE';
      }
      return `${
        calcDifferenceToToday(subscription?.validUntil) + 1
      } Days left - SUBSCRIBE`;
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {isAuthenticated && (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={toggleNavOpen}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box className={classes.logoBox}>
          <Box>
            <IconButton
              edge="start"
              className={`${classes.logoButton}`}
              color="inherit"
              aria-label="menu"
              component={Link}
              to={ROUTE_HOME}
              size="large"
            >
              <img
                src={logo}
                style={{ margin: '-15px' }}
                width={extraSmall ? '110px' : '175px'}
                alt="logo"
              />
            </IconButton>
          </Box>
          {isAuthenticated &&
            subscription?.hasSubscription &&
            subscription.status === 3 && (
              <Button
                variant="outlined"
                className={
                  extraSmall
                    ? classes.subAlertButtonNoPadding
                    : classes.subAlertButton
                }
                onClick={() => navigate(ROUTE_PAYMENT)}
              >
                {getSubscriptionButtonText()}
              </Button>
            )}
          {!mobile && (
            <Box className={classes.userBox}>
              {isAuthenticated ? (
                <UserMenu
                  username={
                    veterinarian
                      ? `${veterinarian.FirstName} ${veterinarian.Name}`
                      : ''
                  }
                />
              ) : (
                <IconButton
                  edge="start"
                  className={`${classes.eyeMenuButton}`}
                  color="inherit"
                  aria-label="menu"
                  size="large"
                >
                  <img
                    src={eyeLogo}
                    className={`${classes.eyeMenuButton}`}
                    alt="logo"
                  />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
