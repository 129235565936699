import { Auth, CognitoUser } from '@aws-amplify/auth';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { awsConfig } from '../config/aws.config';
import { noAuthUrls } from '../config/urls.noAuth';

export const axiosSetup = () => {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = '*/*';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.baseURL =
    awsConfig.API_VERSION !== ''
      ? `${awsConfig.API_URL}/${awsConfig.API_VERSION}`
      : awsConfig.API_URL;

  axios.interceptors.request.use(function (config) {
    if (
      config &&
      config.url &&
      noAuthUrls.find((x) => config.url && config.url?.indexOf(x) > -1)
    ) {
      return new Promise((resolve) => {
        config.headers.Authorization = 'Bearer ';
        resolve(config);
      });
    }

    if (
      config.headers['Content-Type'] &&
      config.headers['Content-Type'] !== 'application/json'
    ) {
      return new Promise((resolve) => {
        resolve(config);
      });
    }

    return new Promise((resolve, reject) => {
      if (window.sessionStorage.getItem('gekko/authheader')) {
        config.headers.Authorization =
          window.sessionStorage.getItem('gekko/authheader');
        return resolve(config);
      }
      Auth.currentSession()
        .then((session) => {
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res: CognitoUser) => {
              res.refreshSession(refreshToken, (error, data) => {
                if (error) {
                  Auth.signOut();
                  window.location.reload();
                } else {
                  config.headers.Authorization =
                    'Bearer ' + data.getIdToken().getJwtToken();
                  config.headers.gekkoInformationToken =
                    session.getIdToken().payload.gekkoInformationToken;
                  resolve(config);
                  window.sessionStorage.setItem(
                    'gekko/token',
                    data.getAccessToken().getJwtToken()
                  );
                }
              });
            });
          } else {
            window.sessionStorage.setItem(
              'gekko/token',
              session.getAccessToken().getJwtToken()
            );
            config.headers.Authorization =
              'Bearer ' + session.getAccessToken().getJwtToken();
            config.headers.gekkoInformationToken =
              session.getIdToken().payload.gekkoInformationToken;
            resolve(config);
          }
        })
        .catch((error: AxiosError) => {
          return reject(error);
        });
    });
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async function (error: AxiosError) {
      if (error.response?.status === 401) {
        await Auth.signOut();
        window.location.reload();
        return Promise.reject(error);
      }
      if (!error.response && error.message === 'Network Error') {
        if (process.env.NODE_ENV === 'production') {
          return Promise.reject({
            message:
              'There seems to be some problems.\n Please try again or refresh the page.\n Also try Logout and then Login again.\n If you still get this error contact us',
          });
        } else {
          return Promise.reject({
            message: 'Problems to communicate with server.',
          });
        }
      }
      return Promise.reject(error);
    }
  );
};
