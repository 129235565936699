import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useConfirmationContext } from '../contexts/ConfirmationContext';

export const ConfirmationDialog = () => {
  const { active, setActive, message, buttons, onConfirm } =
    useConfirmationContext();

  if (!active) return null;
  return (
    <Dialog open={active} onClose={() => onConfirm(false)}>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setActive(false);
            onConfirm(true);
          }}
        >
          {buttons.yes}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setActive(false);
            onConfirm(false);
          }}
        >
          {buttons.no}
        </Button>
        <Button variant="text" onClick={() => setActive(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
