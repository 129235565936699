import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Treatment } from '../../types/Diagnose.type';
import { Button } from '../Button';
import { TreatmentCardTitle } from './TreatmentCardTitle';
import { TreatmentSources } from './TreatmentSources';
import { TreatmentType } from './types';

const getTreatmentName = (treatment: Treatment) => {
  if (treatment.Type === TreatmentType.Drug) {
    return treatment.Name;
  } else if (treatment.Type === TreatmentType.Procedure) {
    return treatment.Procedure ? treatment.Procedure.Name : '';
  } else if (treatment.Type === TreatmentType.OtherTreatment) {
    return treatment.OtherTreatment ? treatment.OtherTreatment.Name : '';
  }
  return treatment.Name;
};

export const TreatmentCard = ({
  treatment,
  checked,
  toggleSelect,
  showDosages,
}: {
  treatment: Treatment;
  checked?: boolean;
  toggleSelect?: (treatment: Treatment, checked: boolean) => void;
  showDosages?: (treatment: Treatment) => void;
}) => {
  const canToggle = Boolean(toggleSelect);

  const handleToggleCheckbox = (event: any, checked: boolean) => {
    event.stopPropagation();

    if (toggleSelect) {
      toggleSelect(treatment, checked);
    }
  };

  const handleShowDosagesClick = (treatment: Treatment) => {
    if (showDosages) {
      showDosages(treatment);
    }
  };

  return (
    <Card style={{ marginBottom: '1em' }}>
      <CardContent>
        <Grid container alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <TreatmentCardTitle treatment={treatment} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" align="left">
              {getTreatmentName(treatment)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {canToggle && (
              <ToggleButtonGroup
                size="small"
                value={checked}
                exclusive
                color="primary"
                onChange={handleToggleCheckbox}
              >
                <ToggleButton value={true}>Selected</ToggleButton>
                <ToggleButton value={false}>Not selected</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Grid>
          <TreatmentSources treatmentSources={treatment.Source_Treatments} />
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '14px', whiteSpace: 'pre-line' }}
              gutterBottom
              align="justify"
            >
              {treatment.Info}
            </Typography>

            {treatment.FollowUp && (
              <React.Fragment>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    whiteSpace: 'pre-line',
                  }}
                  align="left"
                >
                  FOLLOW UP
                </Typography>
                <Typography
                  style={{ fontSize: '14px', whiteSpace: 'pre-line' }}
                  align="justify"
                >
                  {treatment.FollowUp}
                </Typography>
              </React.Fragment>
            )}

            {treatment.Type === TreatmentType.Drug && showDosages && (
              <React.Fragment>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => handleShowDosagesClick(treatment)}
                >
                  Show dosages
                </Button>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
