import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { TemplatePage } from '../pages/TemplatePage';
import { ProfilePage } from '../pages/ProfilePage';
import {
  ROUTE_NOTIFICATIONS,
  ROUTE_SUBSCRIPTION,
} from '../config/routes.config';

export const ProfileRouter = () => {
  return (
    <Routes>
      <Route path="info" element={<ProfilePage />} />
      <Route
        path={ROUTE_NOTIFICATIONS}
        element={<TemplatePage title="Notifications" />}
      />
      <Route
        path={ROUTE_SUBSCRIPTION}
        element={<TemplatePage title="Your subscription status" />}
      />
    </Routes>
  );
};
