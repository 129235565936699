import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { useEffect, useState } from 'react';
import API from '../../api/Api';
import {
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../../config/routes.config';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useRWDContext } from '../../contexts/RWDContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import {
  getRWDdiagnosesMethod2,
  getRWDsymptoms,
  getRWDage,
} from '../../utils/rwdUtils';
import { Diagnose } from '../../types/Diagnose.type';
import { useTreatmentsPageDispatch } from '../../contexts/TreatmentsPageContext';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { PlaylistRemove } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { FloatingActionBar } from './FloatingActionBar';

export const DiagnosesFloatingActionBar = ({
  promptDiagnoses,
}: {
  promptDiagnoses: () => void;
}) => {
  const {
    selectedDiagnoses,
    dontSelect,
    externalSaveAndContinue,
    diagnoses,
    subsequentDiagnosesObj,
    selectedSymptoms,
    selectedSymptomsObj,
  } = useDiagnosesPage();

  const navigate = useNavigate();

  const diagnosesPageDispatch = useDiagnosesPageDispatch();
  const dispatchTreatments = useTreatmentsPageDispatch();
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDiagnosesVisible, setSelectedDiagnosesVisible] =
    useState(false);

  const { exportState } = useWorkHistory();

  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();

  useEffect(() => {
    if (externalSaveAndContinue) {
      diagnosesPageDispatch({
        type: 'setExternalSaveAndContinue',
        value: false,
      });
      handleSaveAndContinue();
    }
  }, [externalSaveAndContinue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveAndContinue = async () => {
    if (!selectedDiagnoses.length && !dontSelect) {
      promptDiagnoses();
      return;
    }
    await handleSave(false);

    if (!patientId || !visitId) return;

    const path = generatePath(
      dontSelect ? ROUTE_VISIT_SUMMARY : ROUTE_VISIT_TREATMENTS,
      {
        patientId,
        visitId,
      }
    );
    navigate(path);
  };

  // Stuff for RWD
  const { track: trackRWD } = useRWDContext();
  const { symptoms } = useSymptoms();
  const patientInfo = usePatientInfo();

  const handleSave = async (force: boolean) => {
    const selected = diagnoses
      ? diagnoses.filter(
          (diagnose: Diagnose) => selectedDiagnoses.indexOf(diagnose.Id) > -1
        )
      : [];

    window.dataLayer.push({
      event: 'selectedDiagnoses',
      diagnosisTotal: selected.length,
    });
    selected.map((selectedDiagnosis) => {
      window.dataLayer.push({
        event: 'selectedDiagnosis',
        diagnosisId: selectedDiagnosis.Id,
        diagnosisName: selectedDiagnosis.Disease.Name,
      });
      return true;
    });

    dispatchTreatments({ type: 'reset' });
    dispatchTreatments({
      type: 'setSubsequentDiseaseNames',
      subsequentDiagnosesObj,
    });
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (force || (visitId && visitId !== 'test')) {
      setShowLoader(true);
      await exportState();
      setShowLoader(false);
    }
    (async () => {
      // send selected diagnoses with 10s interval to activecampaing using track
      for (const selectedDiagnosis of selected) {
        API.trackEvent('selectediagnose', selectedDiagnosis.Disease.Name);
        // wait for 10s
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    })();
    // Start of RWD functionality
    if (selected.length > 0) {
      const diagnosesForRWD = getRWDdiagnosesMethod2(selected);
      const arrayOfSymptoms = getRWDsymptoms(
        selectedSymptoms,
        symptoms,
        selectedSymptomsObj
      );
      const age = getRWDage(patientInfo.dateOfBirth);
      trackRWD({
        actionName: 'selected_diagnoses',
        signalmentSpecies:
          patientInfo.animalTypeName[0].toUpperCase() +
          patientInfo.animalTypeName.slice(1),
        signalmentBreed:
          patientInfo.breedName === '' ? undefined : patientInfo.breedName,
        signalmentWeight: +patientInfo.weight,
        signalmentGender: patientInfo.genderName,
        signalmentAge: age,
        signalmentNeutered: patientInfo.neutered ? 'neutered' : 'intact',
        signalmentPatientType: patientId === 'test' ? 'test' : 'clinical',
        diseases: diagnosesForRWD,
        symptoms: arrayOfSymptoms,
      });
    }
    // End of RWD functionality
  };

  return (
    <FloatingActionBar
      showLoader={showLoader}
      handleSave={handleSave}
      onContinue={handleSaveAndContinue}
      saveDisabled={patientInfo.AnimalTypeId === -1}
    >
      <Button variant="text" onClick={() => setSelectedDiagnosesVisible(true)}>
        {selectedDiagnoses.length} diagnose(s) selected
      </Button>
      <Dialog
        open={selectedDiagnosesVisible}
        onClose={() => setSelectedDiagnosesVisible(false)}
      >
        <DialogTitle>Selected diagnoses</DialogTitle>
        <DialogContent>
          <ul>
            {selectedDiagnoses.map((diagnoseId) => {
              const diagnose = (diagnoses || []).find(
                (d) => d.Id === diagnoseId
              );
              return (
                <li key={diagnoseId}>
                  {diagnose?.Disease.Name}{' '}
                  <Button variant="text" onClick={() => {}}>
                    <PlaylistRemove />
                  </Button>
                </li>
              );
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDiagnosesVisible(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </FloatingActionBar>
  );
};
