import React, { useEffect, useState } from 'react';
import { ProfileContainer } from '../components/profile/ProfileContainer';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../contexts/SubscriptionContext';
import { useRequestData } from '../hooks/useRequestData';
import { Subscription } from '../types/Subscription.type';

export const ProfilePage = () => {
  const [deleteSubscription, setDeleteSubscription] = useState(false);

  const { subscription, isLoading: isLoadingSubscription } =
    useSubscriptionContext();

  const dispatchSubscription = useSubscriptionDispatch();

  useEffect(() => {
    if (isLoadingSubscription) {
      setDeleteSubscription(false);
    }
  }, [isLoadingSubscription]);

  useRequestData<Subscription>({
    needTransport: deleteSubscription,
    dispatch: dispatchSubscription,
    method: 'delete',
    url: '/stripe',
    data: subscription,
  });
  return <ProfileContainer />;
};
