import { useCallback, useMemo } from 'react';
import axios from 'axios';

import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';

import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../contexts/TreatmentsPageContext';

import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../contexts/DosagesPageContext';

import { useWorkContext } from '../contexts/WorkContext';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import {
  usePatientListDispatch,
  usePatientListState,
} from '../contexts/PatientListContext';
import { useVisitsListState } from '../contexts/VisitsListContext';
import {
  ROUTE_CURRENT_VISIT,
  ROUTE_VISIT_DIAGNOSIS,
} from '../config/routes.config';
import { Patient } from '../types/Patient.type';
import { PatientVisit } from '../types/PatientVisit.type';
import {
  useDrugProducts,
  useDrugProductsDispatch,
} from '../contexts/DrugProductsContext';
import { DiagnosesPageState } from '../reducers/diagnosesPage/diagnoses.page.state';
import { TreatmentsPageState } from '../reducers/treatmentsPage/treatments.page.state';
import { DrugProductsState } from '../reducers/drugProducts/drugProducts.state';
import { DosagesPageState } from '../reducers/dosagesPage/dosages.page.state';
import API from '../api/Api';
import moment from 'moment';
import { useLLMDiagnose } from '../contexts/LLMDiagnoseContext';
import {
  convertKgToLbs,
  formatPayload,
  patientInfoToPatient,
} from '../utils/insertPatientUtils';

const VISIT_HISTORY_API_URL = '/visit-history';

type HistoryObject = {
  path: string;
  diagnosesPage: DiagnosesPageState;
  treatmentsPage: TreatmentsPageState;
  drugProducts: DrugProductsState;
  dosagesPage: DosagesPageState;
  llm?: { freeText: string }; // old objects don't have this field
};

export const useWorkHistory = () => {
  const { freeText, setFreeText, setExtractedSymptoms, setExtractedAnimal } =
    useLLMDiagnose();
  const navigate = useNavigate();
  const location = useLocation();
  const { setPatient, setVisit } = useWorkContext();
  const { animalTypesObj } = useAnimalTypesContext();
  const { patientsObj } = usePatientListState();
  const { visitsObj } = useVisitsListState();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const patientInfo = usePatientInfo();

  const { visitId, patientId }: any = useParams();

  const diagnosesPage = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const treatmentsPage = useTreatmentsPage();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();
  const dosagesPage = useDosagesPage();
  const dispatchDosagesPage = useDosagesPageDispatch();
  const drugProducts = useDrugProducts();
  const dispatchDrugProducts = useDrugProductsDispatch();
  const dispatchPatientList = usePatientListDispatch();

  const dirty = useMemo(() => {
    return (
      diagnosesPage.dirty ||
      treatmentsPage.dirty ||
      dosagesPage.dirty ||
      drugProducts.dirty
    );
  }, [diagnosesPage, treatmentsPage, dosagesPage, drugProducts]);

  const resetDiagnosesPage = useCallback(() => {
    dispatchPageDiagnoses({
      type: 'resetAll',
    });
  }, [dispatchPageDiagnoses]);

  const resetTreatmentsPage = useCallback(() => {
    dispatchTreatmentsPage({
      type: 'reset',
    });
  }, [dispatchTreatmentsPage]);

  const resetDosagesPage = useCallback(() => {
    dispatchDosagesPage({ type: 'reset' });
  }, [dispatchDosagesPage]);

  const resetDrugProducts = useCallback(() => {
    dispatchDrugProducts({ type: 'resetAll' });
  }, [dispatchDrugProducts]);

  const resetAllPages = useCallback(() => {
    resetDiagnosesPage();
    resetTreatmentsPage();
    resetDosagesPage();
    resetDrugProducts();
  }, [
    resetDiagnosesPage,
    resetDosagesPage,
    resetTreatmentsPage,
    resetDrugProducts,
  ]);

  const getState = useCallback(
    async (visitId: string) => {
      try {
        const { data } = await axios.get<HistoryObject>(VISIT_HISTORY_API_URL, {
          params: { visitId },
        });
        return data;
      } catch (errorObject) {
        // TODO: Handle this with some clever way in the ui?
        navigate(-1);
      }
    },
    [navigate]
  );

  const postState = useCallback(
    async (visitId: string, data: HistoryObject) => {
      try {
        await axios.post(VISIT_HISTORY_API_URL, {
          visitId,
          data,
        });
      } catch (errorObject) {
        return; // TODO: Show error in the ui?
      }
    },
    []
  );

  const exportState = useCallback(async () => {
    let exportVisitId = visitId;
    let path = location.pathname;
    let visitPatientId = patientId;
    if (patientId === 'test') {
      // create patient from current patient info
      const patient = patientInfoToPatient(patientInfo);
      const resp = await API.createPatient(
        formatPayload({
          ...patient,
          BreedId: patient.BreedId ? patient.BreedId.toString() : '',
          DateOfBirth: patient.DateOfBirth ? moment(patient.DateOfBirth) : null,
          GenderId: String(patient.GenderId),
          WeightLbs: convertKgToLbs(patient.Weight),
        })
      );
      dispatchPatientList({
        type: 'add',
        patient: resp.body!,
      });
      visitPatientId = resp.body?.Id;
      path = path.replace('test', visitPatientId);
    }
    if (visitId === 'test') {
      const date = moment();
      // date in YYYY-MM-DD format
      const dateStr = date.format('YYYY-MM-DD');
      // time in HH:MM format
      const timeStr = date.format('HH:mm');
      const resp = await API.createVisit({
        PatientId: visitPatientId,
        Date: dateStr,
        Time: timeStr,
        AreaSpecified: 0,
      });
      exportVisitId = resp.body?.Id;
      path = path.replace('test', exportVisitId);
    }
    if (!exportVisitId) throw new Error('Failed to create visit');
    const promise = await postState(exportVisitId, {
      diagnosesPage,
      treatmentsPage,
      dosagesPage,
      drugProducts,
      path: path,
      llm: {
        freeText,
      },
    });
    if ((window as any).pmsIntegration) {
      (window as any).pmsIntegration.pending.push(promise);
    }
    if (path !== location.pathname) {
      navigate(path, { replace: true });
    }
    dispatchPageDiagnoses({ type: 'setDirty', dirty: false });
    dispatchTreatmentsPage({ type: 'setDirty', dirty: false });
    dispatchDosagesPage({ type: 'setDirty', dirty: false });
  }, [
    diagnosesPage,
    dosagesPage,
    drugProducts,
    location.pathname,
    postState,
    treatmentsPage,
    visitId,
    patientId,
    patientInfo,
    freeText,
    dispatchPageDiagnoses,
    dispatchTreatmentsPage,
    dispatchDosagesPage,
    navigate,
    dispatchPatientList,
  ]);

  const importState = useCallback(
    async (historyObject: HistoryObject) => {
      setExtractedSymptoms({});
      if (historyObject.llm?.freeText) {
        setFreeText(historyObject.llm.freeText);
      } else {
        setFreeText('');
      }
      if (historyObject.diagnosesPage) {
        dispatchPageDiagnoses({
          type: 'import',
          diagnosesPage: historyObject.diagnosesPage,
        });
      } else {
        resetAllPages();
        return;
      }
      if (historyObject.treatmentsPage) {
        dispatchTreatmentsPage({
          type: 'import',
          treatmentsPage: historyObject.treatmentsPage,
        });
      } else {
        resetTreatmentsPage();
        resetDosagesPage();
        return;
      }
      if (historyObject.dosagesPage) {
        dispatchDosagesPage({
          type: 'import',
          dosagesPage: historyObject.dosagesPage,
        });
        dispatchDrugProducts({
          type: 'import',
          drugProducts: historyObject.drugProducts,
        });
        return;
      } else {
        resetDosagesPage();
        resetDrugProducts();
      }
    },
    [
      dispatchDosagesPage,
      dispatchPageDiagnoses,
      dispatchTreatmentsPage,
      dispatchDrugProducts,
      resetAllPages,
      resetDosagesPage,
      resetTreatmentsPage,
      resetDrugProducts,
      setFreeText,
      setExtractedSymptoms,
    ]
  );

  const goToWorkPage = useCallback(
    async (
      patientId: string,
      visitId: string,
      homePage: boolean | undefined
    ) => {
      const patient = patientsObj![patientId];
      setPatient(patient);
      setVisit(visitsObj![visitId]);
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });

      const historyObject = await getState(visitId);

      await importState(historyObject!);

      if (historyObject && historyObject.path) {
        navigate(historyObject.path);
        return;
      }

      const path = generatePath(
        homePage ? ROUTE_VISIT_DIAGNOSIS : ROUTE_CURRENT_VISIT,
        { patientId, visitId }
      );

      navigate(path);
    },
    [
      animalTypesObj,
      dispatchPatientInfo,
      getState,
      navigate,
      patientsObj,
      setPatient,
      setVisit,
      visitsObj,
      importState,
    ]
  );

  const goToWorkPageFromAnywhere = useCallback(
    async (patient: Patient, visit: PatientVisit, ignorePath?: boolean) => {
      setPatient(patient);
      setVisit(visit);
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
      // reset LLM context
      setExtractedSymptoms({});
      setExtractedAnimal(null);
      setFreeText('');

      const historyObject = await getState(String(visit.Id));

      await importState(historyObject!);

      if (ignorePath) {
        return;
      }

      if (historyObject && historyObject.path) {
        navigate(historyObject.path);
        return;
      }
      const path = generatePath(ROUTE_VISIT_DIAGNOSIS, {
        patientId: String(patient.Id),
        visitId: String(visit.Id),
      });
      navigate(path);
    },
    [
      animalTypesObj,
      dispatchPatientInfo,
      getState,
      navigate,
      setPatient,
      setVisit,
      importState,
      setExtractedSymptoms,
      setExtractedAnimal,
      setFreeText,
    ]
  );

  return {
    resetAllPages,
    exportState,
    goToWorkPage,
    goToWorkPageFromAnywhere,
    dirty,
  };
};
