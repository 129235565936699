import { LOCALE_US, UNDEFINED_ID } from '../../config/constant.params';
import { Female, Male } from '../../types/Gender.type';
import { intervalToDate } from '../../utils/dateUtils';
import { convertKgToLbs } from '../../utils/insertPatientUtils';
import {
  CurrentPatientInfoState,
  CurrentPatientInfoAction,
  CurrentPatientInfoDefault,
} from './current.patient.info.state';

export const currentPatientInfoReducer = (
  state: CurrentPatientInfoState,
  action: CurrentPatientInfoAction
): CurrentPatientInfoState => {
  switch (action.type) {
    case 'setPatientInfo': {
      return {
        ...CurrentPatientInfoDefault,
        Id: action.patient.Id,
        AnimalTypeId: action.patient.AnimalTypeId,
        breedId: action.patient.BreedId ? action.patient.BreedId : UNDEFINED_ID,
        name: action.patient.NameOrId || '',
        animalTypeName: action.animalTypeName.toLowerCase(),
        neutered: action.patient.Neutered,
        GenderId: action.patient.GenderId,
        genderName:
          action.patient.GenderId === Male
            ? 'male'
            : action.patient.GenderId === Female
            ? 'female'
            : 'gender: unknown',
        patientAge: action.patient.DateOfBirth
          ? intervalToDate(new Date(), new Date(action.patient.DateOfBirth))
          : '',
        dateOfBirth: action.patient.DateOfBirth
          ? action.patient.DateOfBirth
          : '',
        weight: action.patient.Weight,
        weightStr: !action.patient.Weight
          ? ''
          : navigator.language === LOCALE_US
          ? `${convertKgToLbs(action.patient.Weight)} lbs`
          : `${action.patient.Weight} kg`,
      };
    }

    case 'setBreedName': {
      return {
        ...state,
        breedName: action.breedName,
      };
    }

    case 'resetPatientInfo': {
      return {
        ...CurrentPatientInfoDefault,
      };
    }

    case 'request': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'success': {
      return {
        ...state,
        breedName: action.responseData[0].Name,
        isLoading: false,
      };
    }

    case 'failure': {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case 'reset': {
      const newState = { ...state };
      if (newState.error) {
        delete newState.error;
        newState.breedName = '';
      }
      return newState;
    }

    default:
      return state;
  }
};
