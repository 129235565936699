import React, { useEffect, useState } from 'react';
import { CardTodayVisits } from '../components/home/CardTodayVisits';
import { CardOtherVisits } from '../components/visits/CardOtherVisits';
import Loader from '../components/Loader';
import { useVisitsListDispatch } from '../contexts/VisitsListContext';
import { useOtherVisitsDispatch } from '../contexts/OtherVisitsContext';

export const VisitsPage = () => {
  const [visitRowClicked, setVisitRowClicked] = useState(false);

  const dispatch = useVisitsListDispatch();
  const otherVisitDispatch = useOtherVisitsDispatch();
  useEffect(() => {
    dispatch({ type: 'reset' });
    otherVisitDispatch({ type: 'reset' });
  }, [dispatch, otherVisitDispatch]);

  return (
    <>
      <Loader showLoader={visitRowClicked} />
      <CardTodayVisits setVisitRowClicked={setVisitRowClicked} />
      <CardOtherVisits setVisitRowClicked={setVisitRowClicked} />
    </>
  );
};
