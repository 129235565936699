import React, { createContext, useContext, useMemo, useState } from 'react';
import { useVeterinarian } from './VeterinarianContext';
import APILayer from '../api/ApiLayer';

type RWDEvent = {
  actionName: string;
  signalmentSpecies?: string;
  signalmentBreed?: string;
  signalmentWeight?: number;
  signalmentGender?: string;
  signalmentAge?: number;
  signalmentNeutered?: string;
  signalmentPatientType?: string;
  symptoms?: { name: string; type: string; required: string }[];
  geographicalAreas?: { name: string | undefined }[];
  dosages?: {
    strength: number;
    strengthUnit: string;
    amount: number;
    amountUnit: string;
    doseType: string;
  }[];
  treatments?: {
    name: string;
    type: string;
    connectedDiagnosis?: string;
  }[];
  diseases?: {
    name?: string;
    searchCause?: string;
    searchMethod?: string;
  }[];
};

type RWDContextType = {
  track: (event: RWDEvent) => void;
};

const RWDContext = createContext<RWDContextType>({
  track: () => {},
});

export const RWDContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const veterinarianState = useVeterinarian();
  const [sessionId] = useState(Date.now());
  const track = useMemo(() => {
    if (!veterinarianState.veterinarian) return () => {};
    return (event: { actionName: string; [key: string]: any }) => {
      const account = {
        email: veterinarianState.veterinarian?.Email,
        personTypeId: veterinarianState.veterinarian?.PersonTypeId,
        countryId: veterinarianState.veterinarian?.CountryId,
        registrationDate: veterinarianState.veterinarian?.AddedDate,
      };
      APILayer.postRequest('/rwd', {
        ...event,
        account,
        sessionId,
      });
      console.log(account, sessionId, event);
    };
  }, [veterinarianState.veterinarian, sessionId]);
  return (
    <RWDContext.Provider value={{ track }}>{children}</RWDContext.Provider>
  );
};

export const useRWDContext = () => {
  return useContext(RWDContext);
};
