import { addOrRemoveNumberInArray } from '../../utils/treatmentsUtils';
import {
  TreatmentsPageState,
  TreatmentsPageAction,
  TreatmentsPageDefault,
} from './treatments.page.state';

export const treatmentsPageReducer = (
  state: TreatmentsPageState,
  action: TreatmentsPageAction
): TreatmentsPageState => {
  switch (action.type) {
    case 'setExternalSaveAndContinue': {
      return {
        ...state,
        externalSaveAndContinue: action.value,
      };
    }
    case 'reset': {
      return { ...TreatmentsPageDefault };
    }

    case 'resetSubSequentDiseases': {
      return { ...state, subsequentTreatmentInfos: [] };
    }

    case 'request': {
      return { ...state, isLoading: true };
    }

    case 'success': {
      if (action.options === 'subsequentDiagnoses') {
        const subsequentTreatmentsObj: { [x: number]: number } = {};
        action.responseData.forEach((diagnose, index) => {
          subsequentTreatmentsObj[diagnose.Id] = index;
        });

        return {
          ...state,
          isLoading: false,
          subsequentTreatmentInfos: action.responseData,
          subsequentTreatmentsObj,
        };
      } else {
        const treatmentsObj: { [x: number]: number } = {};
        action.responseData.forEach((diagnose, index) => {
          treatmentsObj[diagnose.Id] = index;
        });

        return {
          ...state,
          isLoading: false,
          treatmentInfos: action.responseData,
          treatmentsObj,
        };
      }
    }

    case 'failure':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case 'import':
      return {
        ...action.treatmentsPage,
        dirty: false,
      };

    case 'setTreatmentSelected': {
      const selectedTreatments = [...state.selectedTreatments];
      const selectedTreatmentsObj = {
        ...state.selectedTreatmentsObj,
      };
      const treatment = action.treatment;
      const index = selectedTreatments.indexOf(treatment.Id);

      let changes = false;
      if (action.checked && index === -1) {
        selectedTreatments.push(treatment.Id);
        if (!(treatment.Id in selectedTreatmentsObj)) {
          selectedTreatmentsObj[treatment.Id] = treatment;
        }
        changes = true;
      } else if (!action.checked && index > -1) {
        selectedTreatments.splice(index, 1);
        if (treatment.Id in selectedTreatmentsObj) {
          delete selectedTreatmentsObj[treatment.Id];
        }
        changes = true;
      }
      for (const stId in selectedTreatmentsObj) {
        const obj = selectedTreatmentsObj[stId];
        window.dataLayer.push({
          event: 'selectedTreatment',
          treatmentId: obj.Id,
          treatmentName:
            obj.Name !== null && obj.Name !== ''
              ? obj.Name
              : obj.OtherTreatment !== null && obj.OtherTreatment.Name !== ''
              ? obj.OtherTreatment.Name
              : obj.Procedure !== null && obj.Procedure.Name !== ''
              ? obj.Procedure.Name
              : 'Unspecified',
        });
      }

      if (changes) {
        return {
          ...state,
          selectedTreatments,
          selectedTreatmentsObj,
          dirty: true,
        };
      } else {
        return state;
      }
    }

    case 'deselectTreatments': {
      const sameIds = state.selectedTreatments.filter((selectedId) =>
        action.deselectTreatmentIds.includes(selectedId)
      );

      const newSelectedTreatments = state.selectedTreatments.filter(
        (id) => !sameIds.includes(id)
      );
      const newSelectedTreatmentsObj = { ...state.selectedTreatmentsObj };
      sameIds.forEach((id) => {
        delete newSelectedTreatmentsObj[id];
      });

      return {
        ...state,
        selectedTreatments: newSelectedTreatments,
        selectedTreatmentsObj: newSelectedTreatmentsObj,
        dirty: true,
      };
    }

    case 'setSubsequentDiseaseNames': {
      const subsequentDiseaseIds: number[] = [];
      const subsequentDiseaseNames: { [x: number]: string } = {};
      Object.entries(action.subsequentDiagnosesObj).forEach(([key, value]) => {
        value.forEach((d) => {
          if (subsequentDiseaseIds.indexOf(d.EquivalentToDiseaseId) === -1) {
            subsequentDiseaseIds.push(d.EquivalentToDiseaseId);
            subsequentDiseaseNames[d.EquivalentToDiseaseId] = d.Name;
          }
        });
      });

      return {
        ...state,
        subsequentDiseaseNames,
        subsequentDiseaseIds,
        dirty: true,
      };
    }

    case 'setSubsequentDiseaseSelected': {
      const [changes, selectedSubsequentDiseases] = addOrRemoveNumberInArray(
        state.selectedSubsequentDiseases,
        action.diseaseId,
        action.checked
      );
      let confirmedSubsequentDiseases = state.confirmedSubsequentDiseases;
      if (changes && !action.checked) {
        [, confirmedSubsequentDiseases] = addOrRemoveNumberInArray(
          state.confirmedSubsequentDiseases,
          action.diseaseId,
          action.checked
        );
      }

      if (changes) {
        return {
          ...state,
          selectedSubsequentDiseases,
          confirmedSubsequentDiseases,
          dirty: true,
        };
      } else {
        return state;
      }
    }

    case 'setSubsequentDiseaseConfirmed': {
      const [changes, confirmedSubsequentDiseases] = addOrRemoveNumberInArray(
        state.confirmedSubsequentDiseases,
        action.diseaseId,
        action.checked
      );

      if (changes) {
        return {
          ...state,
          confirmedSubsequentDiseases,
          dirty: true,
        };
      } else {
        return state;
      }
    }

    case 'setDirty':
      return {
        ...state,
        dirty: action.dirty,
      };

    default:
      return state;
  }
};
